import axios from "axios";
import React, { Component } from "react";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      status: null,
      show: { value: null, tf: false },
      loading: false,
      singleCustData: {},
    };
  }

  getSingleCustomer = (e) => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url:  process.env.REACT_APP_BASE_URL + `/customer/${e}`,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.customer) {
              this.setState({
                singleCustData: res.data.customer,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Customer Data!", tf: true },
                loading: false,
                showEditField: { tf: false },
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load Customers!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load Customers!", tf: true },
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (
        this.state.status === "load-failed" ||
        this.state.status === "type-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "failed" ||
        this.state.status === "del-failed" ||
        this.state.status === "app-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "success" ||
        this.state.status === "app-success" ||
        this.state.status === "del-success"
      ) {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount() {
    if (!this.props.history.location.state) {
      window.location.href = "/createinvoice";
    }
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getSingleCustomer(this.props.history.location.state?.customer);
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="invoice-container">
          <button onClick={() => window.print()} className="float">
            <i className="fa fa-print my-float"></i>
          </button>
          <div className="container">
            <div className="row invoice-header px-0 px-md-3 pb-2 pt-4">
              <div className="col-md-4 col-12">
                <span>
                  <img src="/assets/images/logo.png" alt="logo" width="100%" />
                </span>
                <h1>INVOICE</h1>
              </div>
              <div className="col-md-4 col-12 text-md-right">
                <p>0117 207 446</p>
                <p>info@savoga.lk</p>
                <p>www.savoga.lk</p>
              </div>
              <div className="col-md-4 col-12 text-md-right">
                <p>No. 133/9/c, Mahena Road,</p>
                <p>Siyambalape</p>
                <p>Sri Lanka</p>
              </div>
            </div>

            <div className="invoice-content row px-0 px-md-5 pt-5">
              <div className="col-md-3 col-12">
                <h5 className="almost-gray mb-3">Invoiced to:</h5>
                <p className="gray-ish">{this.state.singleCustData?.name}</p>
                <p className="gray-ish">{this.state.singleCustData?.address}</p>
                {/* <p className="gray-ish">VAT ID: 12091803</p> */}
              </div>
              <div className="col-md-3 col-12">
                <h5 className="almost-gray">Invoice number:</h5>
                <p className="gray-ish"># {this.props.history.location.state?.invoiceid}</p>

                <h5 className="almost-gray">Date of Issue:</h5>
                <p className="gray-ish">{this.props.history.location.state?.created_at} </p>
              </div>
              <div className="col-md-6 col-12 text-right total-field">
                <h4 className="almost-gray">Invoice Total</h4>
                <h1 className="gray-ish">
                  Rs.{" "}
                  {Number(this.props.history.location.state?.total).toFixed(2)}
                </h1>
                <h5 className="almost-gray due-date">
                  Due Date: 01 / 01 / 20 20
                </h5>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-md-10 offset-md-1 col-12 invoice-table pt-1">
                <Table className="table table-hover">
                  <Thead className="thead splitForPrint">
                    <Tr>
                      <Th scope="col gray-ish">#</Th>
                      <Th scope="col gray-ish">Item</Th>
                      <Th className="text-right" scope="col gray-ish">
                        Qty
                      </Th>
                      <Th className="text-right" scope="col gray-ish">
                        Unit Price (Rs.)
                      </Th>
                      <Th className="text-right" scope="col gray-ish">
                        Discount
                      </Th>
                      <Th className="text-right" scope="col gray-ish">
                        Amount (Rs.)
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {this.props.history.location.state?.items?.map(
                      (data, i) => {
                        if (i === 17 || i == 49) {
                          return (
                            <>
                              <div className="page-break"></div>
                              <Tr>
                                <Th scope="row">{i + 1}</Th>
                                <Td className="item">
                                  <div>{data.name || data.item}</div>
                                </Td>
                                <Td className="text-right">{data.qty}</Td>
                                <Td className="text-right">
                                  {Number(
                                    data.price || data.unit_price
                                  )?.toFixed(2)}
                                </Td>
                                <Td className="text-right">
                                  {data.discount}
                                  {data.discount ? " %" : null}
                                </Td>
                                <Td className="text-right">
                                  {Number(
                                    data.amount ||
                                      data.unit_price * data.qty -
                                        (data.unit_price *
                                          data.qty *
                                          data.discount) /
                                          100
                                  )?.toFixed(2)}
                                </Td>
                              </Tr>
                            </>
                          );
                        } else {
                          return (
                            <Tr>
                              <Th scope="row">{i + 1}</Th>
                              <Td className="item">
                                <div>{data.name || data.item}</div>
                              </Td>
                              <Td className="text-right">{data.qty}</Td>
                              <Td className="text-right">
                                {Number(data.price || data.unit_price)?.toFixed(
                                  2
                                )}
                              </Td>
                              <Td className="text-right">
                                {data.discount}
                                {data.discount ? " %" : null}
                              </Td>
                              <Td className="text-right">
                                {Number(
                                  data.amount ||
                                    data.unit_price * data.qty -
                                      (data.unit_price *
                                        data.qty *
                                        data.discount) /
                                        100
                                )?.toFixed(2)}
                              </Td>
                            </Tr>
                          );
                        }
                      }
                    )}
                  </Tbody>
                </Table>
              </div>
            </div>
            <div className="row invoice_details">
              <div className="col-md-4 offset-md-1 col-12 pt-3">
                <h4 className="gray-ish">Terms</h4>
                <p className="pt-3 almost-gray">
                  <ul>
                    <li>1st term</li>
                    <li>2nd term</li>
                  </ul>
                </p>
              </div>
              <div className="offset-md-1 col-md-5 col-12 mb-3 pr-md-4 sub-table">
                <Table className="table table-borderless">
                  <Tbody>
                    <Tr>
                      <Th scope="row gray-ish">Sub Total</Th>
                      <Td className="text-right">
                        Rs.{" "}
                        {Number(
                          Number(this.props.history.location.state?.discount) +
                            Number(this.props.history.location.state?.total)
                        ).toFixed(2)}
                      </Td>
                    </Tr>
                    <Tr>
                      <Th scope="row gray-ish">Discounts</Th>
                      <Td className="text-right">
                        Rs.{" "}
                        {Number(
                          this.props.history.location.state?.discount
                        ).toFixed(2)}
                      </Td>
                    </Tr>
                    <Tr className="last-row">
                      <Th scope="row">
                        <h4>Total</h4>
                      </Th>
                      <Td className="text-right">
                        <h4>
                          Rs.{" "}
                          {Number(
                            this.props.history.location.state?.total
                          ).toFixed(2)}
                        </h4>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default Invoice;
