import React, { Component } from "react";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";
import html2canvas from "html2canvas";

import loader from "../../Resources/Images/loader/spinner-1.gif";

import "react-datepicker/dist/react-datepicker.css";
import PrintingSlip from "./printingSlip";

export class PrintPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: { value: null, tf: false },
      loading: false,
      status: null,
      salarySlips: null,
      token: null,
      empData: [],
      empId: null,
      year: null,
      selectedYear: null,
      month: null,
    };
  }

  printSlip() {
    const htmlNode = document.getElementById(`slip`);
    html2canvas(htmlNode, {
      backgroundColor: "#FFFFFF",
    }).then((canvas) => {
      canvas.style.display = "none";
      var image = canvas.toDataURL("png");
      var a = document.createElement("a");
      a.setAttribute("download", "salarySlip.png");
      a.setAttribute("href", image);
      a.click();
    });
  }



  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (this.state.status === "load-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "success") {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    if (this.props?.match?.params) {
      this.setState({
        salarySlips: this.props?.match?.params.id,
      });
    } else {
      this.setState({
        salarySlips: this.props.userId,
      });
    }
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title d-flex">
                Salary Slips Preview
                <button
                  className="btn btn-warning ml-auto"
                  onClick={() => this.printSlip()}
                >
                  Print
                </button>
              </h4>
              <PrintingSlip userId={this.state.salarySlips} />
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default PrintPreview;
