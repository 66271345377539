/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class ViewInvoices extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      invoices: [],
      status: null,
      show: { value: null, tf: false },
      loading: false,
      customerData: [],
    };
  }

  getCustomer = (id) => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
      viewCustomer: false,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url:  process.env.REACT_APP_BASE_URL + `/customer/${id}`,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              customerData: response.data.customer,
              loading: false,
              viewCustomer: true,
            });
          } else {
            this.setState({
              loading: false,
              status: "load-failed",
              show: { value: "Failed to Load Customers!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "load-failed",
            show: { value: "Failed to Load Customers!", tf: true },
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  allInvoices = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url:  process.env.REACT_APP_BASE_URL + "/invoice/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              invoices: response.data.invoice,
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
              status: "failed",
              show: { value: "Loading Failed!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "failed",
            show: { value: "Loading Failed!", tf: true },
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  deleteInvoices = (e) => {
    swal({
      title: "Do you want to delete?",
      text: "Are you sure that you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        if (this.state.token) {
          if (e) {
            this.setState({
              show: { value: null, tf: false },
              loading: true,
            });

            var config = {
              method: "delete",
              url:  process.env.REACT_APP_BASE_URL + `/invoice/${e}`,
              headers: {
                Authorization: `Bearer ${this.state.token}`,
              },
            };

            axios(config)
              .then((response) => {
                if (response.data.success) {
                  this.setState({
                    status: "del-success",
                    loading: false,
                    show: { value: "Deleted Suucessfully!", tf: true },
                  });
                  this.allInvoices();
                } else {
                  this.setState({
                    status: "del-failed",
                    loading: false,
                    show: { value: "Deleting Failed!", tf: true },
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  status: "del-failed",
                  loading: false,
                  show: { value: "Deleting Failed!", tf: true },
                });
                console.log(error);
              });
          } else {
            this.setState({
              status: "del-failed",
              loading: false,
              show: { value: "Deleting Failed!", tf: true },
            });
          }
        } else {
          this.setState({
            loading: false,
            status: "auth-failed",
            show: { value: "Authentication Error!", tf: true },
          });
        }
      }
    });
  };

  renderInvoice = (id) => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
      viewCustomer: false,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url:  process.env.REACT_APP_BASE_URL + `/invoice/${id}`,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            var discount = 0;
            var total = 0;
            response.data.invoiceitems.map((data) => {
              discount =
                discount + (data.unit_price * data.qty * data.discount) / 100;
              total = total + data.unit_price * data.qty;
            });

            const sendData = {
              items: response.data.invoiceitems,
              discount: discount,
              total: total - discount,
              customer: response.data.invoice[0].customerid,
              invoiceid: response.data.invoice[0].invoiceid,
            };
            this.props.history.push({
              pathname: "/invoice",
              state: sendData,
            });
          } else {
            this.setState({
              loading: false,
              status: "load-failed",
              show: { value: "Failed to Load Invoice!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "load-failed",
            show: { value: "Failed to Load Invoice!", tf: true },
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (
        this.state.status === "load-failed" ||
        this.state.status === "type-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "failed" ||
        this.state.status === "del-failed" ||
        this.state.status === "app-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "success" ||
        this.state.status === "app-success" ||
        this.state.status === "del-success"
      ) {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
              viewCustomer: false,
            });
            this.allInvoices();
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.allInvoices();
  }

  render() {
    var tableData = this.state.invoices?.map((data, i) => {
      return (
        <Tr key={i}>
          <Th>{data.invoiceid}</Th>
          <Td>
            <button
              className="btn btn-link p-0 m-0"
              onClick={() => this.getCustomer(data.customerid)}
            >
              {data.name}
            </button>
          </Td>
          <Td className="text-right">{data.amount || Number(0.0)}</Td>
          <Td className="text-right">
            <button
              className="btn btn-inverse-success"
              onClick={() => this.renderInvoice(data.invoiceid)}
            >
              View
            </button>
          </Td>
          <Td className="text-right">
            <button className="btn btn-inverse-info">Edit</button>
          </Td>
          <Td className="text-right">
            <button
              onClick={() => {
                this.deleteInvoices(data.invoiceid);
              }}
              className="btn btn-inverse-danger"
            >
              Delete
            </button>
          </Td>
        </Tr>
      );
    });

    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <Modal
          show={this.state.viewCustomer}
          size="md"
          onHide={() => {
            this.setState({
              viewCustomer: false,
            });
          }}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="col">
                <div className="row">Customer Details</div>
              </div>
            </Modal.Title>
          </Modal.Header>
          {this.state.customerData ? (
            <>
              <Modal.Body>
                <div className="col">
                  <div className="row">
                    <h5 className="mr-auto">Customer</h5>
                    <h5 className="ml-auto">{this.state.customerData.name}</h5>
                  </div>
                  <div className="row">
                    <h5 className="mr-auto">Address</h5>
                    <h5 className="ml-auto">
                      {this.state.customerData.address}
                    </h5>
                  </div>
                  <div className="row">
                    <h5 className="mr-auto">Company</h5>
                    <h5 className="ml-auto">
                      {this.state.customerData.company}
                    </h5>
                  </div>
                  <div className="row">
                    <h5 className="mr-auto">Phone 1</h5>
                    <h5 className="ml-auto">
                      {this.state.customerData.phone1}
                    </h5>
                  </div>
                  {this.state.customerData.phone2 && (
                    <div className="row">
                      <h5 className="mr-auto">Phone 2</h5>
                      <h5 className="ml-auto">
                        {this.state.customerData.phone2}
                      </h5>
                    </div>
                  )}
                  <div className="row">
                    <h5 className="mr-auto">Sales Rep</h5>
                    <h5 className="ml-auto">
                      {this.state.customerData.salesrepname}
                    </h5>
                  </div>
                  <div className="row">
                    <h5 className="mr-auto">Sales Rep Contact</h5>
                    <h5 className="ml-auto">
                      {this.state.customerData.salesrepcontact}
                    </h5>
                  </div>
                </div>
              </Modal.Body>
            </>
          ) : (
            <Modal.Body className="text-center">No data!</Modal.Body>
          )}
        </Modal>
        {this.state.showingInvoices !== null && (
          <div className="grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">All Invoices</h4>
                <p className="card-description">Invoice Details</p>
                <Table className="table table-hover">
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Customer</Th>
                      <Th className="text-right">Amount</Th>
                    </Tr>
                  </Thead>
                  {tableData ? (
                    <Tbody>{tableData}</Tbody>
                  ) : (
                    <Tbody>
                      <Td>No Data</Td>
                      <Td>No Data</Td>
                      <Td>No Data</Td>
                      <Td>No Data</Td>
                    </Tbody>
                  )}
                </Table>
              </div>
            </div>
          </div>
        )}
      </LoadingScreen>
    );
  }
}

export default ViewInvoices;
