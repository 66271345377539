import React, { Component } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import LoadingScreen from "react-loading-screen";
import qs from "querystring";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class AllCustomers extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      loading: true,
      provinces: null,
      districts: null,
      cities: null,
      isOpen: false,
      isOpenCityAdd: false,
    };
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  openModalAddCuty = () => this.setState({ isOpenCityAdd: true });
  closeModalAddCuty = () => this.setState({ isOpenCityAdd: false });

  getCustomers = () => {

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/customer/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.customers[0]) {
              this.setState({
                customers: res.data.customers,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Customer Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load Customers!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load Customers!", tf: true },
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  }; 
  
  searchCustomers = (id) => {
    this.setState({customers:null})
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/customer/searchcustomerbycity/" + id,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.customers[0]) {
              this.setState({
                customers: res.data.customers,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Customer Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load Customers!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load Customers!", tf: true },
            loading: false,
          });
         
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getProvinces = () => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/customer/provinces",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.provinces[0]) {
              this.setState({
                provinces: res.data.provinces,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
      });
    }
  };

  getDistricts = (id) => {
    this.setState({cities:null,districts:null})
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/customer/districts/" + id,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.districts[0]) {
              this.setState({
                districts: res.data.districts,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
      });
    }
  };

  getCities = (id) => {
    this.setState({cities:null})
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/customer/cities/" + id,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.cities[0]) {
              this.setState({
                cities: res.data.cities,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
      });
    }
  };

  addCustomer = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      var data = new FormData();

      data.append("name", e.target.name.value);
      data.append("company", e.target.company.value);
      data.append("address", e.target.address.value);
      data.append("phone1", e.target.phone1.value);
      data.append("phone2", e.target.phone2.value);
      data.append("city_id", e.target.cityid.value);
      data.append("salesrepname", e.target.salesrepname.value);
      data.append("salesrepcontact", e.target.salesrepcontact.value);

      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/customer",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              loading: false,
              isOpen: false,
            });
            document.getElementById("customerform").reset();
            swal({
              position: "center",
              icon: "success",
              title: "Customer Added",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getCustomers();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal("Oops...", "Error occured", "error");
        });
    }
  };

  addCity = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      var data = new FormData();

      data.append("district_id", e.target.districtid.value);
      data.append("city", e.target.city.value);

      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/customer/city",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              loading: false,
            });
            swal({
              position: "center",
              icon: "success",
              title: "City Added",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getCustomers();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal("Oops...", "Error occured", "error");
        });
    }
  };

  getSingleCustomer = (e) => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + `/customer/${e}`,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.customer) {
              this.setState({
                singleCustData: res.data.customer,
                loading: false,
                showEditField: { tf: false },
                viewCustomer: true,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Customer Data!", tf: true },
                loading: false,
                showEditField: { tf: false },
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load Customers!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load Customers!", tf: true },
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  updateCustomer = (e) => {
    e.preventDefault();

    if (this.state.token) {
      if (
        e.target.name.value &&
        e.target.address.value &&
        e.target.company.value &&
        e.target.phone1.value &&
        e.target.sRepName.value &&
        e.target.sRepNumber.value
      ) {
        var data = {
          id: this.state.singleCustData.id,
          name: e.target.name.value,
          address: e.target.address.value,
          company: e.target.company.value,
          phone1: e.target.phone1.value,
          phone2: e.target.phone1.value ? e.target.phone1.value : null,
          salesrepname: e.target.sRepName.value,
          salesrepcontact: e.target.sRepNumber.value,
        };

        swal({
          title: "Do you want to update?",
          text: "Are you sure that you want to update?",
          icon: "info",
          buttons: true,
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
              loading: true,
            });

            var config = {
              method: "patch",
              url: process.env.REACT_APP_BASE_URL + `/customer`,
              headers: {
                Authorization: `Bearer ${this.state.token}`,
              },
              data: qs.stringify(data),
            };

            axios(config)
              .then((res) => {
                if (res.data.success) {
                  this.getCustomers();
                  this.setState({
                    loading: false,
                    status: "success",
                    viewCustomer: false,
                    show: { value: "Successfully Updated Customer!", tf: true },
                  });
                } else {
                  this.setState({
                    loading: false,
                    status: "failed",
                    show: { value: "Failed to Update Customer!", tf: true },
                  });
                }
              })
              .catch((err) => {
                this.setState({
                  loading: false,
                  status: "failed",
                  show: { value: "Failed to Update Customer!", tf: true },
                });
                console.log(err);
              });
          }
        });
      } else {
        this.setState({
          status: "failed",
          show: { value: "Fill Required Fields!", tf: true },
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  deleteCustomer = (e) => {
    this.setState({
      show: { value: null, tf: false },
    });

    swal({
      title: "Do you want to delete?",
      text: "Are you sure that you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        this.setState({
          loading: true,
        });

        if (this.state.token) {
          if (e) {
            var config = {
              method: "delete",
              url: process.env.REACT_APP_BASE_URL + `/customer/${e}`,
              headers: {
                Authorization: `Bearer ${this.state.token}`,
              },
            };

            axios(config)
              .then((res) => {
                if (res.data.success) {
                  this.setState({
                    status: "success",
                    show: { value: "Successfully Deleted Customer!", tf: true },
                    loading: false,
                  });
                  this.getCustomers();
                } else {
                  this.setState({
                    status: "failed",
                    loading: false,
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  status: "failed",
                  show: { value: "Failed to Delete Customer!", tf: true },
                  loading: false,
                });
                console.log(error);
              });
          } else {
            this.setState({
              status: "failed",
              show: { value: "Failed to Delete Customer!", tf: true },
              loading: false,
            });
          }
        } else {
          this.setState({
            loading: false,
            status: "auth-failed",
            show: { value: "Authentication Error!", tf: true },
          });
        }
      }
    });
  };

  componentDidUpdate() {}

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getCustomers();
    this.getProvinces();
  }

  render() {
    var customers = this.state.customers ? (
      this.state.customers.map((data, i) => {
        return (
          <tr>
            <td> {data.name}</td>
            <td> {data.address}</td>
            <td> {data.city}</td>
            <td> {data.phone1}</td>
            <td> {data.phone2}</td>
            <td> <button onClick={()=>this.deleteCustomer(data.id)} class="btn btn-danger">Delete</button></td>
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );
    let provinces = this.state.provinces ? (
      this.state.provinces.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.name_en}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );

    let districts = this.state.districts ? (
      this.state.districts.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.name_en}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );

    let cities = this.state.cities ? (
      this.state.cities.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.city}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="pd-20 card p-4 mb-4">
          <div class="clearfix">
            <div class="pull-left d-flex w-100">
              <h1 class="text-center text-blue h2">Customers</h1>
            </div>
            <div class="row">
              <div class="col">
                <select
                  onChange={(e) => this.getDistricts(e.target.value)}
                  name="provinceid"
                  class="form-control"
                  required
                >
                  <option value="">Select a Province</option>
                  {provinces ? provinces : <option value="">No Data</option>}
                </select>
              </div>
              <div class="col">
                <select
                  onChange={(e) => this.getCities(e.target.value)}
                  name="districtid"
                  class="form-control"
                  required
                >
                  <option value="">Select a District</option>
                  {districts ? districts : <option value="">No Data</option>}
                </select>
              </div>
              <div class="col">
                <select
                
                onChange={(e)=>{this.searchCustomers(e.target.value)}}
                
                name="cityid" class="form-control" required>
                  <option value="">Select a City</option>
                  {cities ? cities : <option value="">No Data</option>}
                </select>
              </div>
              <div class="col">
                <button
                  type="button"
                  class="ml-auto btn btn-lg btn-primary btn-block"
                  onClick={this.openModalAddCuty}
                >
                  Add a City
                </button>
              </div>
              <div class="col">
                <button
                  type="button"
                  class="ml-auto btn btn-lg btn-info btn-block"
                  onClick={this.openModal}
                >
                  Add a Customer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="text-center">Name</th>
                  <th class="text-center">Address</th>
                  <th class="text-center">City</th>
                  <th class="text-center">Contact Number 1</th>
                  <th class="text-center">Contact Number 2</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{customers ? customers : <tr value="">No Data</tr>}</tbody>
            </table>
          </div>
        </div>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add a Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => this.addCustomer(e)} id="customerform">
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Name:</label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Customer Name"
                    name="name"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Company:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Company"
                    name="company"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Province:
                </label>
                <div class="col-sm-12 col-md-10">
                  <select
                    onChange={(e) => this.getDistricts(e.target.value)}
                    name="provinceid"
                    class="form-control"
                    required
                  >
                    <option value="">Select</option>
                    {provinces ? provinces : <option value="">No Data</option>}
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  District:
                </label>
                <div class="col-sm-12 col-md-10">
                  <select
                    onChange={(e) => this.getCities(e.target.value)}
                    name="districtid"
                    class="form-control"
                    required
                  >
                    <option value="">Select</option>
                    {districts ? districts : <option value="">No Data</option>}
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">City:</label>
                <div class="col-sm-12 col-md-10">
                  <select name="cityid" class="form-control" required>
                    <option value="">Select</option>
                    {cities ? cities : <option value="">No Data</option>}
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Address:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Address"
                    name="address"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Phone 1:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Phone Number 1"
                    name="phone1"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Phone 2:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Phone Number"
                    name="phone2"
                  />
                </div>
              </div>{" "}
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Sales Rep Name:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Sales Rep Name"
                    name="salesrepname"
                  />
                </div>
              </div>{" "}
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Sales Rep Contact Number:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Sales Rep Contact Number"
                    name="salesrepcontact"
                  />
                </div>
              </div>
              <div class="text-center">
              <button class="btn btn-info">Save</button>{" "}
         
            </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={this.state.isOpenCityAdd}
          onHide={this.closeModalAddCuty}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add a City</Modal.Title>
          </Modal.Header>
          <form onSubmit={(e) => this.addCity(e)}>
            <div class="form-group row">
              <label class="col-sm-12 col-md-2 col-form-label">Province:</label>
              <div class="col-sm-12 col-md-10">
                <select
                  onChange={(e) => this.getDistricts(e.target.value)}
                  name="provinceid"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  {provinces ? provinces : <option value="">No Data</option>}
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-12 col-md-2 col-form-label">District:</label>
              <div class="col-sm-12 col-md-10">
                <select
                  onChange={(e) => this.getCities(e.target.value)}
                  name="districtid"
                  class="form-control"
                  required
                >
                  <option value="">Select</option>
                  {districts ? districts : <option value="">No Data</option>}
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-12 col-md-2 col-form-label">City:</label>
              <div class="col-sm-12 col-md-10">
                <input
                  class="form-control"
                  type="text"
                  placeholder="City"
                  name="city"
                />
              </div>
            </div>
            <div class="text-center">
              <button class="btn btn-info">Save</button>{" "}
              <button onClick={this.closeModalAddCuty} class="btn btn-danger">
                Close
              </button>
            </div>
          </form>
          <Modal.Body></Modal.Body>
        </Modal>
      </LoadingScreen>
    );
  }
}

export default AllCustomers;
