import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class AllAllowances extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      allowanceData: null,
      status: null,
      show: { value: null, tf: false },
      loading: false,
      empData: [],
    };
  }

  getAllowances = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/allowance",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.allowances[0]) {
              this.setState({
                allowanceData: res.data.allowances,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No allowance Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load allowance!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load allowance!", tf: true },
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getEmpIds = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url:  process.env.REACT_APP_BASE_URL + "/employee/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            this.getAllowances();
            if (response.data.employees[0]) {
              this.setState({
                empData: response.data.employees,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Employee Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
              status: "type-failed",
              show: { value: "Failed Loading Employees!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "type-failed",
            show: { value: "Failed Loading Employees!", tf: true },
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  deleteAllowance = (e) => {
    swal({
      title: "Do you want to delete?",
      text: "Are you sure that you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        this.setState({
          loading: true,
        });

        if (this.state.token) {
          if (e) {
            var config = {
              method: "delete",
              url:  process.env.REACT_APP_BASE_URL + `/allowance/${e}`,
              headers: {
                Authorization: `Bearer ${this.state.token}`,
              },
            };

            axios(config)
              .then((res) => {
               
                if (res.data.success) {
                  this.setState({
                    status: "success",
                    show: {
                      value: "Successfully Deleted Allowance!",
                      tf: true,
                    },
                    loading: false,
                  });
                  this.getAllowances();
                } else {
                  this.setState({
                    status: "failed",
                    show: { value: "Failed to Delete allowance!", tf: true },
                    loading: false,
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  status: "failed",
                  show: { value: "Failed to Delete allowance!", tf: true },
                  loading: false,
                });
                console.log(error);
              });
          } else {
            this.setState({
              status: "failed",
              show: { value: "Failed to Delete allowance!", tf: true },
              loading: false,
            });
          }
        } else {
          this.setState({
            loading: false,
            status: "auth-failed",
            show: { value: "Authentication Error!", tf: true },
          });
        }
      }
    });
  };

  getEmployee = (id) => {
    var employee = "";
    this.state.empData.map((data) => {
      if (id === data.id) {
        employee = data;
      }
    });
    return employee.name;
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (this.state.status === "load-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "success") {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getEmpIds();
  }

  render() {
    var allowanceRows =
      this.state.allowanceData !== null &&
      this.state.allowanceData.map((data, i) => {
        return (
          <div className="col-sm-12 col-md-3" key={i}>
            <div className="card mb-4">
              <div className="card-body">
                <h4 className="card-title">
                  <div className="col-12">
                    <div className="row">
                      <div className="incentive-card-title">
                        {data.empid ? this.getEmployee(data.empid) : null}
                      </div>
                      <div className="incentive-card-title">
                        {data.allowance ? data.allowance : null}
                      </div>
                      <div className="incentive-card-title">
                        {data.allowance ? data.qty : null}
                      </div>
                      <div className="incentive-card-title">
                        {data.amount ? data.amount : null}
                      </div>
                    </div>
                  </div>
                </h4>
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      onClick={() => {
                        this.deleteAllowance(data.id ? data.id : null);
                      }}
                      className="btn btn-outline-danger"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        {this.state.allowanceData !== null && (
          <div className="grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">All Allowances</h4>
                <p className="card-description">Allowance Details</p>
                <div className="row">
                  {allowanceRows ? allowanceRows : "No Data"}
                </div>
              </div>
            </div>
          </div>
        )}
      </LoadingScreen>
    );
  }
}

export default AllAllowances;
