import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";

import "react-datepicker/dist/react-datepicker.css";

export class PrintingSlip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: { value: null, tf: false },
      loading: false,
      status: null,
      salarySlips: null,
      salaryIncentives: null,
      token: null,
      empData: [],
      empId: null,
      year: null,
      selectedYear: null,
      month: null,
      leavecount:null,
      salaryadvanceamount:null,
    };
  }


  getSalarySlip = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      if (this.props.userId) {
        var config = {
          method: "get",
          url: process.env.REACT_APP_BASE_URL + `/salaryslip/viewslip/${this.props.userId}`,
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        };

        axios(config)
          .then((res) => {
            if (res.data.success) {
              this.setState({
                salarySlips: res.data.salaryslip[0],
                salaryIncentives: res.data.salaryincentives,
                leavecount:res.data.leavecout,
                salaryadvanceamount:res.data.salaryadvanceamount,
                loading: false,
              });
            } else {
              this.setState({
                salarySlips: null,
                status: "load-failed",
                show: {
                  value: "Failed to Load Salary Slip Details!",
                  tf: true,
                },
                loading: false,
              });
            }
          })
          .catch((error) => {
            this.setState({
              salarySlips: null,
              status: "load-failed",
              show: {
                value: "Failed to Load Salary Slip Details!",
                tf: true,
              },
              loading: false,
            });
          });
      } else {
        this.setState({
          salarySlips: null,
          status: "failed",
          show: {
            value: "Failed to Load Salary Slip Details!",
            tf: true,
          },
          loading: false,
        });
      }
    } else {
      this.setState({
        salarySlips: null,
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (this.state.status === "load-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "success") {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getSalarySlip();
  }

  render() {
    let totalIncentives = 0;
    let grossSal = 0;

    const incentiveRows = this.state.salaryIncentives?.map((data) => {
   
      totalIncentives = totalIncentives + (data.amount * data.qty);
      console.log(totalIncentives);
      return (
        <tr>
          <td className="m-0 p-0">
            <h5 className="my-auto text-left">{data.incentive}</h5>
          </td>
          <td className="text-truncate"></td>
          <td className="text-truncate"></td>
          <td className="text-truncate"></td>
          <td className="text-right">{data.qty}</td>
          <td className="m-0 p-0">
            <h5 className="my-auto text-right">
              {Number(parseFloat(data.amount*data.qty)).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </h5>
          </td>
        </tr>
      );
    });

    grossSal =
      this.state.salarySlips?.basic +
      totalIncentives +
      this.state.salarySlips?.otamount;

      // console.log("check "+totalIncentives);

    const month = {
      "01": "January",
      "02": "February",
      "03": "March",
      "04": "April",
      "05": "May",
      "06": "June",
      "07": "July",
      "08": "August",
      "09": "September",
      10: "October",
      11: "November",
      12: "December",
    };

    const totalDeductions =
      (this.state.salarySlips?.basic * 8) / 100 +
      this.state.salarySlips?.loanamount +
      this.state.salarySlips?.lunchcount;

    return (
      <>
        {this.state.salarySlips && (
          <div className="grid-margin w-50 mx-auto border p-3" id="slip">
            <div className="d-flex">
              <h5 className="font-weight-bold">SEIJITSU LANKA (PVT) LTD</h5>
              {/* <p className="ml-auto">jegqma m;%Sldj</p> */}
            </div>
            <div className="d-flex">
              <p>
              {this.state.salarySlips?.name} 's Pay Slip for The Month of {month[this.state.salarySlips.month]}{" "}
                - {this.state.salarySlips.year} 
                <br />
                
              </p>
              {/* <h5 className="ml-auto">E.P.F.NO. 002</h5> */}
            </div>
            <h5>
              {/* {this.state.salarySlips?.basic} */}
            </h5>
            <table className="table table-sm table-borderless">
              <thead className="d-none">
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </thead>
              <tbody>
                <tr>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-left">Basic</h5>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-right">
                    {Number(
                        parseFloat(this.state.salarySlips.basic-3000)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    
                    </h5>
                  </td>
                </tr>
                <tr>
                          <td className="m-0 p-0">
                            <h5 className="my-auto text-left">GRA 1</h5>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="m-0 p-0">
                            <h5 className="my-auto text-right">
                              {Number(parseFloat(1000)).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <td className="m-0 p-0">
                            <h5 className="my-auto text-left">GRA 2</h5>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="m-0 p-0 border-bottom">
                            <h5 className="my-auto text-right">
                              {Number(parseFloat(2000)).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                }
                              )}
                            </h5>
                          </td>
                        </tr>
                <tr>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-left font-weight-bold">
                      Total Basic
                    </h5>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-right font-weight-bold">
                      {Number(
                        parseFloat(this.state.salarySlips.basic)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-left">O/T</h5>
                  </td>
                  <td className="text-truncate"></td>
                  <td className="text-right"></td>
                  <td className="text-right"></td>
                  <td className="text-right">
                    {this.state.salarySlips.othours}
                  </td>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-right">
                      {Number(
                        parseFloat(this.state.salarySlips.otamount)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </h5>
                  </td>
                </tr>

                {this.state.salaryIncentives.length > 0 && <>{incentiveRows}</>}

                <tr>
                  <td className="m-0 p-0 border-bottom">
                    <h5 className="my-auto text-left font-weight-bold">
                      Gross Salary
                    </h5>
                  </td>
                  <td className="border-bottom"></td>
                  <td className="border-bottom"></td>
                  <td className="border-bottom"></td>
                  <td className="border-bottom"></td>
                  <td className="m-0 p-0 border-bottom">
                    <h5 className="my-auto text-right font-weight-bold">
                      {Number(parseFloat(grossSal)).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </h5>
                  </td>
                </tr>

                <tr>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-left">EPF (8%)</h5>
                  </td>
                  <td className="text-truncate"></td>
                  <td className="text-right"></td>
                  <td className="text-right"></td>
                  <td className="text-right"></td>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-right">
                      {Number(
                        parseFloat((this.state.salarySlips.basic * 8) / 100)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-left">Salary Advance</h5>
                  </td>
                  <td className="text-truncate"></td>
                  <td className="text-right"></td>
                  <td className="text-right"></td>
                  <td className="text-right"></td>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-right">
                    {Number(
                        parseFloat(this.state.salaryadvanceamount || 0)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-left">Loan Instalment</h5>
                  </td>
                  <td className="text-truncate"></td>
                  <td className="text-right"></td>
                  <td className="text-right"></td>
                  <td className="text-right"></td>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-right">
                    {Number(
                        parseFloat(this.state.salarySlips?.loanamount || 0)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    
                    </h5>
                  </td>
                </tr>
               
                <tr>
                  <td className="border-top border-bottom m-0 p-0">
                    <h5 className="my-auto text-left">Total Deduction</h5>
                  </td>
                  <td className="border-top border-bottom"></td>
                  <td className="border-top border-bottom"></td>
                  <td className="border-top border-bottom"></td>
                  <td className="border-top border-bottom"></td>
                  <td className="border-top border-bottom m-0 p-0">
                    <h5 className="my-auto text-right">
                      {Number(parseFloat(totalDeductions)+parseFloat(this.state.salaryadvanceamount || 0)).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                        }
                      )}
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-left">Number of Leaves Remaining</h5>
                  </td>
                  <td className="text-truncate"></td>
                  <td className="text-right"></td>
                  <td className="text-right"></td>
                  <td className="text-right"></td>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-right">
                    {this.state.leavecount}
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td className="border-top border-bottom m-0 p-0">
                    <h5 className="my-auto text-left font-weight-bold">
                      Net Salary
                    </h5>
                  </td>
                  <td className="border-top border-bottom"></td>
                  <td className="border-top border-bottom"></td>
                  <td className="border-top border-bottom"></td>
                  <td className="border-top border-bottom"></td>
                  <td className="border-top border-bottom m-0 p-0">
                    <h5 className="my-auto text-right font-weight-bold">
                      {Number(
                        parseFloat(grossSal) - (parseFloat(totalDeductions)+parseFloat(this.state.salaryadvanceamount || 0))
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </h5>
                  </td>
                </tr>

                <tr>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-left">Company Contribution</h5>
                  </td>
                </tr>

                <tr>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-left">EPF (12%)</h5>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-right">
                      {Number(
                        parseFloat((this.state.salarySlips.basic * 12) / 100)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-left">ETF (3%)</h5>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="m-0 p-0">
                    <h5 className="my-auto text-right">
                      {Number(
                        parseFloat((this.state.salarySlips.basic * 3) / 100)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </h5>
                  </td>
                </tr>
                {/* <tr>
                          <td className="m-0 p-0">
                            <h5 className="my-auto text-left">
                              Banking Details
                            </h5>
                          </td>
                          <td></td>
                          <td></td>
                          <td>Bank:</td>
                          <td>Sampath Bank PLC</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>A/c No.:</td>
                          <td>1162 5436 4081</td>
                          <td></td>
                        </tr> */}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }
}

export default PrintingSlip;
