import React, { Component } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import ImageUploader from "react-images-upload";

import loader from "../../Resources/Images/loader/spinner-1.gif";
class AllEmployees extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      empData: null,
      status: null,
      show: { value: null, tf: false },
      loading: false,
      imageUpload: false,
      tempEid: null,
      uploadedPhoto: null,
    };
  }

  uploadImage = (photoFile, id) => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      if (photoFile && id) {
        var userData = new FormData();
        userData.append("empid", id);
        userData.append("photo", photoFile);

        var config = {
          method: "post",
          url:  process.env.REACT_APP_BASE_URL + "/employee/updateempphoto",
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
          data: userData,
        };

        axios(config)
          .then((res) => {
            if (res.data.success) {
              this.getEmployees();
              this.setState({
                imageUpload: false,
                loading: false,
                status: "success",
                show: { value: "Photo Successfully Updated!", tf: true },
              });
            } else {
              this.setState({
                status: "upload-failed",
                loading: false,
                show: { value: "Failed to Upload Photo!", tf: true },
              });
            }
          })
          .catch((error) => {
            this.setState({
              status: "upload-failed",
              loading: false,
              show: { value: "Failed to Upload Photo!", tf: true },
            });
          });
      } else {
        this.setState({
          status: "failed",
          loading: false,
          show: { value: "Select a Photo!", tf: true },
        });
      }
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getEmployees = () => {
    this.setState({
      show: { value: null, tf: false },
      loader: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url:  process.env.REACT_APP_BASE_URL + "/employee/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.employees[0]) {
              this.setState({
                empData: res.data.employees,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Employee Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load Employees!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load Employees!", tf: true },
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  deactivateEmployee = (e) => {
    swal({
      title: "Do you want to deactivate?",
      text: "Are you sure that you want to deactivate?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        this.setState({
          show: { value: null, tf: false },
          loading: true,
        });

        if (this.state.token) {
          if (e) {
            var data = {
              empid: e,
            };

            var config = {
              method: "patch",
              url:  process.env.REACT_APP_BASE_URL + "/employee/delete",
              headers: {
                Authorization: `Bearer ${this.state.token}`,
              },
              data: data,
            };

            axios(config)
              .then((res) => {
                if (res.data.success) {
                  this.setState({
                    status: "success",
                    show: {
                      value: "Successfully Deactivated Employee!",
                      tf: true,
                    },
                    loading: false,
                  });
                  this.getEmployees();
                } else {
                  this.setState({
                    status: "failed",
                    loading: false,
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  status: "failed",
                  show: { value: "Failed to Deactivate Employee!", tf: true },
                  loading: false,
                });
              });
          } else {
            this.setState({
              status: "failed",
              show: { value: "Failed to Deactivate Employee!", tf: true },
              loading: false,
            });
          }
        } else {
          this.setState({
            loading: false,
            status: "auth-failed",
            show: { value: "Authentication Error!", tf: true },
          });
        }
      }
    });
  };

  selectImage = (picture) => {
    this.setState({
      show: { value: null, tf: false },
    });

    if (picture) {
      this.setState({
        uploadedPhoto: picture[0],
      });
      if (picture[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          document
            .getElementById("upload-preview")
            .setAttribute("src", e.target.result);
          document.getElementById("img-preview-container").style.display =
            "block";
        };
        reader.readAsDataURL(picture[0]);
        document.getElementsByClassName("fileUploader")[0].style.display =
          "none";
      }
    } else {
      this.setState({
        status: "failed",
        show: { value: "Select a Photo!", tf: true },
      });
    }
  };

  removeImage = () => {
    document.getElementsByClassName("fileUploader")[0].style.display = "block";
    document.getElementById("img-preview-container").style.display = "none";
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (this.state.status === "load-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "success") {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getEmployees();
    window.addEventListener("load", (event) => {
      var images = document.getElementsByClassName("img-container");
      // images.map((img) => {
      //   var isLoaded = img.complete && img.naturalHeight !== 0;
      //   alert(isLoaded && isLoaded);
      // });
    });
  }

  render() {
    var empRows =
      this.state.empData !== null &&
      this.state.empData.map((data, i) => {
        return (
          <div className="col-sm-12 col-md-6" key={i}>
            <div
              className={
                data.status
                  ? data.status === 0
                    ? "card mb-4 emp-row-card bg-light"
                    : data.status === 1
                    ? "card mb-4 emp-row-card active"
                    : "card mb-4 emp-row-card bg-light"
                  : "card mb-4 emp-row-card active"
              }
            >
              <div className="row no-gutters">
                <div className="col-md-12 col-lg-4">
                  <div className="card-img-container">
                    <div
                      className="img-container"
                      style={{
                        backgroundImage: `url("https://savoga.ideacurl.com/images/${
                          data.photo ? data.photo : null
                        }")`,
                      }}
                    ></div>
                    <div className="btn-overlay">
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({
                            imageUpload: true,
                            tempEid: data.id,
                            show: { value: null, tf: false },
                          });
                        }}
                        className="btn btn-outline-success"
                      >
                        Change Image
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-8">
                  <div className="card-body">
                    <h1
                      className="card-title"
                      onClick={() =>
                        (window.location.pathname = `/emp/singleemp#${
                          data.id ? data.id : null
                        }`)
                      }
                    >
                      {data.name ? data.name : null}
                      <small className="text-muted">
                        &nbsp;&nbsp;({data.position ? data.position : null})
                      </small>
                    </h1>
                    <p className="card-text job-desc clamp">
                      {data.jd ? data.jd : null}
                    </p>
                    <p className="card-text">
                      {data.address ? data.address : null}
                    </p>
                    <p className="card-text">
                      {data.phone1 ? data.phone1 : null}
                    </p>
                    <div className="emp-rows-buttons">
                      <div className="row">
                        <div className="col">
                          <button
                            type="button"
                            onClick={() =>
                              (window.location.pathname = `/emp/updtemp#${
                                data.id ? data.id : null
                              }`)
                            }
                            className="btn btn-outline-info"
                          >
                            Edit Details
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              this.deactivateEmployee(data.id ? data.id : null);
                            }}
                            className={
                              data.status
                                ? data.status === 0
                                  ? "btn btn-outline-secondary"
                                  : data.status === 1
                                  ? "btn btn-outline-danger"
                                  : "btn btn-outline-danger"
                                : "btn"
                            }
                            disabled={
                              data.status
                                ? data.status === 0
                                  ? true
                                  : data.status === 1
                                  ? false
                                  : false
                                : true
                            }
                          >
                            Deactivate
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <Modal
          show={this.state.imageUpload}
          size="md"
          onHide={() => {
            this.setState({
              imageUpload: false,
            });
          }}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ImageUploader
              className="image-uploader"
              withIcon={true}
              buttonText="Choose images"
              onChange={(e) => this.selectImage(e)}
              imgExtension={[".jpg", ".png"]}
              maxFileSize={5242880}
              singleImage={true}
            />
            <div
              className="text-center"
              id="img-preview-container"
              style={{ display: "none" }}
            >
              <img
                id="upload-preview"
                className="img-fluid upload-preview"
                src="#"
                alt="..."
              />
              <button
                className="remove-image"
                onClick={() => {
                  this.removeImage();
                }}
              >
                &#215;
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="btn-success"
              onClick={
                (() => {
                  this.setState({ imageUpload: false });
                },
                () => {
                  this.uploadImage(
                    this.state.uploadedPhoto,
                    this.state.tempEid
                  );
                })
              }
            >
              Finish
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.empData !== null && (
          <div className="grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">All Employees</h4>
                <p className="card-description">Employee Details</p>
                <div className="row">{empRows ? empRows : "No Data"}</div>
              </div>
            </div>
          </div>
        )}
      </LoadingScreen>
    );
  }
}

export default AllEmployees;
