import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";
import loader from "../../Resources/Images/loader/spinner-1.gif";
import jsPDF from "jspdf";
import "jspdf-autotable";

class PettycashReport extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      loading: false,
      startdate: null,
      enddate: null,
      cid: null,
      companies: null,
      types: null,
      reims: null,
      company: null,
      openbal: null,
      closebal: null,
    };
  }

  printTable = (startdate, enddate, company) => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;

    const doc = new jsPDF("landscape");

    doc.autoTable({
      showHead: "everyPage",
      didDrawPage: function (data) {
        // Header
        doc.setFontSize(14);
        doc.setTextColor(40);

        doc.text(
          "Pettycash summery:" + startdate + " to " + enddate + " " + company,
          data.settings.margin.left,
          8
        );

        // Footer
        var str = "Issue Date " + today;

        doc.setFontSize(10);

        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
      styles: {},
      html: "#printtable2",
    });

    doc.autoTable({ html: "#printtable1" });
    doc.autoTable({ html: "#printtable" });
    doc.autoTable({ html: "#printtable3" });

    doc.save("pettycashreport.pdf");
  };

  getCompanies = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/company/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.companies[0]) {
              this.setState({
                companies: response.data.companies,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  getPettycashReport = () => {
    this.setState({
      loading: true,
      types: null,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url:
          process.env.REACT_APP_BASE_URL +
          "/pettycash/report/" +
          this.state.startdate +
          "&" +
          this.state.enddate +
          "&" +
          this.state.cid,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.types[0]) {
              this.setState({
                types: res.data.types,
                reims: res.data.reims,
                openbal: res.data.openingbal,
                closebal: res.data.closingbal,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getCompanies();
  }

  render() {
    var companies = this.state.companies ? (
      this.state.companies.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.name}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );
    var types = this.state.types ? (
      this.state.types.map((data, i) => {
        return (
          <tr>
            <td class="text-center"> {data.type}</td>
            <td class="text-center"> {data.sum}</td>
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );

    var reims = this.state.reims ? (
      this.state.reims.map((data, i) => {
        return (
          <tr>
            <td class="text-center"> {data.date}</td>
            <td class="text-center"> {data.amount}</td>
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="pd-20 card p-4 mb-4">
          <div class="clearfix">
            <div class="pull-left d-flex w-100">
              <h1 class="text-center text-blue h2">Petty Cash</h1>
            </div>

            <div class="row">
              <div class="col">
                <input
                  type="date"
                  onChange={(e) => this.setState({ startdate: e.target.value })}
                  class="form-control"
                />
              </div>

              <div class="col">
                <input
                  type="date"
                  onChange={(e) => this.setState({ enddate: e.target.value })}
                  class="form-control"
                />
              </div>

              <div class="col">
                <select
                  name="companyid"
                  class="form-control"
                  onChange={(e) => {
                    var index = e.nativeEvent.target.selectedIndex;
                    var text = e.nativeEvent.target[index].text;
                    this.setState({ cid: e.target.value, company: text });
                  }}
                  required
                >
                  <option value="">Select a Company</option>
                  {companies ? companies : <option value="">No Data</option>}
                </select>
              </div>
              <div class="col">
                <button
                  onClick={(e) => this.getPettycashReport()}
                  class="btn btn-warning btn-lg btn-block"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <h3 class="text-center">Summery</h3>
            <table id="printtable1" class="table table-bordered">
              <thead>
                <tr>
                  <th class="text-center">
                    <b>Opening Balance</b>
                  </th>
                  <th class="text-center">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td class="text-center">{this.state.openbal}</td>
                </tr>
              </tbody>
            </table>
            <table id="printtable" class="table table-bordered">
              <thead>
                <tr>
                  <th class="text-center">Type</th>
                  <th class="text-center">Amount</th>
                </tr>
              </thead>
              <tbody>{types ? types : <tr value="">No Data</tr>}</tbody>

              <tbody></tbody>
            </table>
            <h3 class="text-center">Reimbursements</h3>
            <table id="printtable2" class="table table-bordered">
              <thead>
                <tr>
                  <th class="text-center">Date</th>
                  <th class="text-center">Amount</th>
                </tr>
              </thead>
              <tbody>{reims ? reims : <tr value="">No Data</tr>}</tbody>

              <tbody></tbody>
            </table>
            <table id="printtable3" class="table table-bordered">
              <thead>
                <tr>
                  <th class="text-center">
                    <b>Closing Balance</b>
                  </th>
                  <th class="text-center">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td class="text-center">{this.state.closebal}</td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col">
                <button
                  class="btn btn-info btn-block"
                  onClick={(e) =>
                    this.printTable(
                      this.state.startdate,
                      this.state.enddate,
                      this.state.company
                    )
                  }
                >
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default PettycashReport;
