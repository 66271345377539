import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class Pettycashtypes extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      loading: true,
      pettycashtypes: null,
      type:null
    };
  }


  getPettycashtypes = () => {

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/pettycashtype/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.pettycashtypes[0]) {
              this.setState({
                pettycashtypes: res.data.pettycashtypes,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }; 
  
 

  addPettycashtype = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      var data = new FormData();

      data.append("type", this.state.type);

      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/pettycashtype",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              loading: false
            });
            swal({
              position: "center",
              icon: "success",
              title: "Petty cash type added",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getPettycashtypes();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal("Oops...", "Error occured", "error");
        });
    }
  };

  

  deletePettycashtype = (e) => {

    swal({
      title: "Do you want to delete?",
      text: "Are you sure that you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        this.setState({
          loading: true,
        });

        if (this.state.token) {
          if (e) {
            var config = {
              method: "delete",
              url: process.env.REACT_APP_BASE_URL + `/pettycashtype/${e}`,
              headers: {
                Authorization: `Bearer ${this.state.token}`,
              },
            };

            axios(config)
              .then((res) => {
                if (res.data.success) {
                  this.setState({
                    loading: false,
                    pettycashtypes:null
                  });
                  swal({
                    position: "center",
                    icon: "error",
                    title: "Petty cash type deleted",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.getPettycashtypes();
                } else {
                  this.setState({
                    status: "failed",
                    loading: false,
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  loading: false,
                });
                console.log(error);
              });
          } else {
            this.setState({
              loading: false,
            });
          }
        } else {
          this.setState({
            loading: false,
          });
        }
      }
    });
  };

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getPettycashtypes();
  }

  render() {
    var pettycashtypes = this.state.pettycashtypes ? (
      this.state.pettycashtypes.map((data, i) => {
        return (
          <tr>
            <td> {data.type}</td>
            <td> {data.fullname}</td>
            <td> {data.created_at}</td>
            <td> {data.updated_at}</td>
            <td> <button onClick={()=>this.deletePettycashtype(data.id)} class="btn btn-danger">Delete</button></td>
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );
    
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="pd-20 card p-4 mb-4">
          <div class="clearfix">
            <div class="pull-left d-flex w-100">
              <h1 class="text-center text-blue h2">Petty Cash Types</h1>
            </div>
            <div class="row">
              <div class="col">
                <input type="text" 
                 onChange={(e) => this.setState({type:e.target.value})}
                required name="type" placeholder="Petty Cash Type" class="form-control" />
              </div>
       
              <div class="col">
                <button
                  type="button"
                  class="ml-auto btn btn-lg btn-primary btn-block"
                  onClick={this.addPettycashtype}
                >
                  Add
                </button>
              </div>
    
            </div>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="text-center">Type</th>
                  <th class="text-center">Entered User</th>
                  <th class="text-center">Added Date</th>
                  <th class="text-center">Updated Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{pettycashtypes ? pettycashtypes : <tr value="">No Data</tr>}</tbody>
            </table>
          </div>
        </div>
       

        
      </LoadingScreen>
    );
  }
}

export default Pettycashtypes;
