import React, { Component } from "react";

import Routes from "./Routes";

class App extends Component {
  constructor() {
    super();
    this.state = {
      username: null,
      password: null,
    };
  }

  render() {
    return (
      <div className="App">
        <Routes />
      </div>
    );
  }
}

export default App;
