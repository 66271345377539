import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

import "react-datepicker/dist/react-datepicker.css";

export class ViewSingleSalarySlip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: { value: null, tf: false },
      loading: false,
      status: null,
      salarySlips: null,
      salaryIncentives: null,
      token: null,
      year: null,
      selectedYear: null,
      month: null,
      slipid: this.props.match.params.id,
    };
  }

  getSalarySlip = async () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      if (this.state.slipid) {
        var config = {
          method: "get",
          url:
            process.env.REACT_APP_BASE_URL +
            `/salaryslip/viewslip/${this.state.slipid}`,
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        };

        await axios(config)
          .then((res) => {
            if (res.data.success) {
              this.setState({
                salarySlips: res.data.salaryslip[0],
                salaryIncentives: res.data.salaryincentives,
                loading: false,
              });
            } else {
              this.setState({
                salarySlips: null,
                status: "load-failed",
                show: {
                  value: "Failed to Load Salary Slip Details!",
                  tf: true,
                },
                loading: false,
              });
            }
          })
          .catch((error) => {
            this.setState({
              salarySlips: null,
              status: "load-failed",
              show: {
                value: "Failed to Load Salary Slip Details!",
                tf: true,
              },
              loading: false,
            });
          });
      } else {
        this.setState({
          salarySlips: null,
          status: "failed",
          show: {
            value: "Failed to Load Salary Slip Details!",
            tf: true,
          },
          loading: false,
        });
      }
    } else {
      this.setState({
        salarySlips: null,
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  printSlip = (id) => {};

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (this.state.status === "load-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "success") {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getSalarySlip();
  }

  render() {
    let totalIncentives = 0;

    const incentiveRows = this.state.salaryIncentives?.map((data) => {
      totalIncentives = totalIncentives + data.amount * data.qty;
      return (
        <tr key={data.id}>
          <td>
            <h5 className="my-auto text-left font-weight-bold">
              {data.incentive}
            </h5>
          </td>
          <td>
            <h5 className="my-auto text-right">{data.qty}</h5>
          </td>
          <td>
            <h5 className="my-auto text-right">
              {Number(parseFloat(data.amount)).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </h5>
          </td>
        </tr>
      );
    });

    const month = {
      "01": "January",
      "02": "February",
      "03": "March",
      "04": "April",
      "05": "May",
      "06": "June",
      "07": "July",
      "08": "August",
      "09": "September",
      10: "October",
      11: "November",
      12: "December",
    };

    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="d-flex">
                <h4 className="float-left card-title">Salary Slip</h4>
              </div>

              <div className="grid-margin stretch-card w-50 mx-auto">
                <div className="card">
                  <div className="card-body">
                    <table className="table table-sm table-borderless">
                      <tbody>
                        <tr>
                          <td>
                            <h4 className="my-auto text-left font-weight-bold">
                              Employee
                            </h4>
                          </td>
                          <td>
                            <h4 className="my-auto text-right">
                              {this.state.salarySlips?.name}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="my-auto text-left font-weight-bold">
                              Year
                            </h4>
                          </td>
                          <td>
                            <h4 className="my-auto text-right">
                              {this.state.salarySlips?.year}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="my-auto text-left font-weight-bold">
                              Month
                            </h4>
                          </td>
                          <td>
                            <h4 className="my-auto text-right">
                              {month[this.state.salarySlips?.month]}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="my-auto text-left font-weight-bold">
                              Basic Salary
                            </h4>
                          </td>
                          <td>
                            <h4 className="my-auto text-right">
                              {Number(
                                parseFloat(this.state.salarySlips?.basic)
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="my-auto text-left font-weight-bold">
                              OT Amount
                            </h4>
                          </td>
                          <td>
                            <h4 className="my-auto text-right">
                              {Number(
                                parseFloat(this.state.salarySlips?.otamount)
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4 className="my-auto text-left font-weight-bold">
                              OT Hours
                            </h4>
                          </td>
                          <td>
                            <h4 className="my-auto text-right">
                              {Number(
                                parseFloat(this.state.salarySlips?.othours)
                              )}
                            </h4>
                          </td>
                        </tr>
                      
                      
                      </tbody>
                    </table>

                    {this.state.salaryIncentives?.length > 0 && (
                      <>
                        <hr />

                        <table className="table table-sm table-borderless mt-2">
                          <thead>
                            <th className="text-left">
                              <h4>Incentive</h4>
                            </th>
                            <th className="text-right">
                              <h4>Qty.</h4>
                            </th>
                            <th className="text-right">
                              <h4>Amount</h4>
                            </th>
                          </thead>
                          <tbody>{incentiveRows}</tbody>
                        </table>
                      </>
                    )}
                    <hr />
                    <div className="d-flex bg-secondary">
                      <h3 className="my-auto font-weight-bold text-left col-6">
                        Net Pay
                      </h3>
                      <h3 className="my-auto font-weight-bold text-right text-danger col-6">
                        Rs.{" "}
                        {Number(
                          parseFloat(this.state.salarySlips?.basic) +
                            parseFloat(this.state.salarySlips?.otamount) +
                            totalIncentives
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default ViewSingleSalarySlip;
