import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import loader from "../../Resources/Images/loader/spinner-1.gif";

import "react-datepicker/dist/react-datepicker.css";

export class AttendanceSummery extends Component {
  constructor() {
    super();
    this.state = {
      show: { value: null, tf: false },
      loading: false,
      status: null,
      attendanceSummery: [],
      token: null,
      empData: [],
      empId: null,
      year: null,
      selectedYear: null,
      month: null,
    };
  }

  getEmpIds = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/employee/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.employees[0]) {
              this.setState({
                empData: response.data.employees,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Employee Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
              status: "load-failed",
              show: { value: "Failed to Load Employees!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "load-failed",
            show: { value: "Failed to Load Employees!", tf: true },
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getAttendanceSummary = (empid, year, month) => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (empid && year && month) {
      if (this.state.token) {
        var config = {
          method: "get",
          url: process.env.REACT_APP_BASE_URL + `/attendance/summery/${empid}&${year}&${month}`,
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        };

        axios(config)
          .then((res) => {
            if (res.data.success) {
              if (res.data.attendanceSummery[0]) {
                const resArray = res.data.attendanceSummery;
                resArray.map((data, i) => {
                  return this.state.empData.map((emp) => {
                    if (emp.id === data.empid) {
                      resArray[i].empid = emp.name;
                    }
                  });
                });
                this.setState({
                  attendanceSummery: resArray,
                  loading: false,
                });
              } else {
                this.setState({
                  attendanceSummery: [],
                  status: "empty",
                  show: { value: "No Attendance Summery Data!", tf: true },
                  loading: false,
                });
              }
            } else {
              this.setState({
                attendanceSummery: [],
                status: "load-failed",
                show: {
                  value: "Failed to Load Attendance Summery Details!",
                  tf: true,
                },
                loading: false,
              });
            }
          })
          .catch((error) => {
            this.setState({
              attendanceSummery: [],
              status: "load-failed",
              show: {
                value: "Failed to Load Attendance Summery Details!",
                tf: true,
              },
              loading: false,
            });
          });
      } else {
        this.setState({
          attendanceSummery: [],
          loading: false,
          status: "auth-failed",
          show: { value: "Authentication Error!", tf: true },
        });
      }
    } else {
      this.setState({
        attendanceSummery: [],
        loading: false,
        status: "fill-failed",
        show: { value: "Select Employee, Year and Month!", tf: true },
      });
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (this.state.status === "load-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "success") {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getEmpIds();
  }

  render() {
    const rows = this.state.attendanceSummery?.map((data, i) => {
      return (
        <Tr key={i}>
          <Td scope="row">{data.empid}</Td>
          <Td>{data.date}</Td>
          <Td>{data.DayName}</Td>
          <Td>{data.workedhours}</Td>
          <Td>{data.workedmins}</Td>
          <Td>{data.othours}</Td>
          <Td>{data.otmins}</Td>
        </Tr>
      );
    });

    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Attendance Summery</h4>
              <p className="card-description">Attendance Summery Details</p>
              <div className="row p-0 m-0">
                <div className="float-left mb-4 mr-2 my-auto">
                  <select
                    className="form-control"
                    onChange={(e) => this.setState({ empId: e.target.value })}
                  >
                    <option value="">Select Employee...</option>
                    {this.state.empData.map((data) => {
                      return <option value={data.id}>{data.name}</option>;
                    })}
                  </select>
                </div>
                <div className="float-left mb-4 mr-2 my-auto">
                  <DatePicker
                    className="form-control"
                    selected={this.state.selectedYear}
                    onChange={(date) =>
                      this.setState({
                        selectedYear: date,
                        year: new Date(date).getFullYear(),
                      })
                    }
                    showYearPicker
                    isClearable
                    dateFormat="yyyy"
                    placeholderText="Select Year..."
                  />
                </div>
                <div className="float-left mb-4 mr-2 my-auto">
                  <select
                    className="form-control"
                    onChange={(e) => this.setState({ month: e.target.value })}
                  >
                    <option value="">Select Month...</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div className="float-left my-auto">
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      this.getAttendanceSummary(
                        this.state.empId,
                        this.state.year,
                        this.state.month
                      )
                    }
                  >
                    Search
                  </button>
                </div>
              </div>
              {this.state.attendanceSummery.length > 0 && (
                <div className="row p-0 m-0">
                  <Table className="table table-striped">
                    <Thead>
                      <Tr>
                        <Th>Employee</Th>
                        <Th>Date</Th>
                        <Th>Day</Th>
                        <Th>Worked Hrs.</Th>
                        <Th>Worked Mins.</Th>
                        <Th>OT Hrs.</Th>
                        <Th>OT Mins.</Th>
                      </Tr>
                    </Thead>
                    <Tbody>{rows}</Tbody>
                  </Table>
                </div>
              )}
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default AttendanceSummery;
