import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import qs from "querystring";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class AllProducts extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      productData: null,
      status: null,
      show: { value: null, tf: false },
      loading: false,
      showNameEditField: { value: null, tf: false },
      showPriceEditField: { value: null, tf: false },
      newName: null,
      newPrice: null,
    };
  }

  getProducts = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/product",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.products[0]) {
              this.setState({
                productData: res.data.products,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Product Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load Products!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load Products!", tf: true },
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  updateProduct = (id, cid, scid, stat, type) => {
    if (this.state.token) {
      if (type === "name") {
        if (scid && cid && stat && this.state.newName) {
          var data = {
            id: id,
            catid: cid,
            subcatid: scid,
            price: stat,
            name: this.state.newName,
          };
        } else {
          this.setState({
            status: "failed",
            show: { value: "Fill Required Fields!", tf: true },
            loading: false,
          });
        }
      } else if (type === "price") {
        if (scid && cid && stat && this.state.newPrice) {
          var data = {
            id: id,
            catid: cid,
            subcatid: scid,
            name: stat,
            price: JSON.parse(this.state.newPrice),
          };
        } else {
          this.setState({
            status: "failed",
            show: { value: "Fill Required Fields!", tf: true },
            loading: false,
          });
        }
      } else {
        this.setState({
          status: "failed",
          show: { value: "Failed to Update Product!", tf: true },
          loading: false,
          showPriceEditField: {
            value: null,
            tf: !this.state.showPriceEditField.tf,
          },
        });
      }

      swal({
        title: "Do you want to update?",
        text: "Are you sure that you want to update?",
        icon: "info",
        buttons: true,
        dangerMode: false,
      }).then((yes) => {
        if (yes) {
          this.setState({
            show: { value: null, tf: false },
            loading: true,
          });

          var config = {
            method: "patch",
            url: process.env.REACT_APP_BASE_URL + "/product",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${this.state.token}`,
            },
            data: qs.stringify(data),
          };

          if (type === "name") {
            axios(config)
              .then((res) => {
                if (res.data.success) {
                  this.setState({
                    status: "success",
                    show: { value: "Successfully Updated Product!", tf: true },
                    loading: false,
                    showNameEditField: {
                      value: null,
                      tf: !this.state.showNameEditField.tf,
                    },
                  });
                  this.getProducts();
                } else {
                  this.setState({
                    status: "failed",
                    loading: false,
                    showNameEditField: {
                      value: null,
                      tf: !this.state.showNameEditField.tf,
                    },
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  status: "failed",
                  show: { value: "Failed to Update Product!", tf: true },
                  loading: false,
                  showNameEditField: {
                    value: null,
                    tf: !this.state.showNameEditField.tf,
                  },
                });
              });
          } else if (type === "price") {
            axios(config)
              .then((res) => {
                if (res.data.success) {
                  this.setState({
                    status: "success",
                    show: { value: "Successfully Updated Product!", tf: true },
                    loading: false,
                    showPriceEditField: {
                      value: null,
                      tf: !this.state.showPriceEditField.tf,
                    },
                  });
                  this.getProducts();
                } else {
                  this.setState({
                    status: "failed",
                    loading: false,
                    showPriceEditField: {
                      value: null,
                      tf: !this.state.showPriceEditField.tf,
                    },
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  status: "failed",
                  show: { value: "Failed to Update Product!", tf: true },
                  loading: false,
                  showPriceEditField: {
                    value: null,
                    tf: !this.state.showPriceEditField.tf,
                  },
                });
              });
          }
        }
      });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  deleteProduct = (e) => {
    swal({
      title: "Do you want to delete?",
      text: "Are you sure that you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        this.setState({
          show: { value: null, tf: false },
          loading: true,
        });

        if (this.state.token) {
          var config = {
            method: "delete",
            url: process.env.REACT_APP_BASE_URL + `/product/${e}`,
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          };

          axios(config)
            .then((res) => {
              if (res.data.success) {
                this.setState({
                  status: "success",
                  show: { value: "Successfully Deleted Product!", tf: true },
                  loading: false,
                  productData:null
                });
                this.getProducts();
              } else {
                this.setState({
                  status: "failed",
                  loading: false,
                });
              }
            })
            .catch((error) => {
              this.setState({
                status: "failed",
                show: { value: "Failed to Delete Product!", tf: true },
                loading: false,
              });
            });
        } else {
          this.setState({
            loading: false,
            status: "auth-failed",
            show: { value: "Authentication Error!", tf: true },
          });
        }
      }
    });
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (
        this.state.status === "load-failed" ||
        this.state.status === "type-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "failed" ||
        this.state.status === "del-failed" ||
        this.state.status === "app-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "success" ||
        this.state.status === "app-success" ||
        this.state.status === "del-success"
      ) {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getProducts();
  }

  render() {
    var ProductCatRows =
      this.state.productData &&
      this.state.productData.map((data, i) => {
        return (
          <div className="col-sm-12 col-md-3" key={i}>
            <div className="card mb-4">
              <div className="card-body">
                <h4 className="card-title">
                  {this.state.showNameEditField.tf &&
                  this.state.showNameEditField.value === data.id ? (
                    <div className="row">
                      <input
                        type="text"
                        className="form-control form-control-sm col-8"
                        onChange={(e) =>
                          this.setState({
                            newName: e.target.value,
                          })
                        }
                        defaultValue={data.name}
                        id={data.id}
                      />
                      <button
                        className="btn cat-card-btn"
                        onClick={
                          (() => {
                            this.setState({
                              showNameEditField: {
                                value: data.id,
                                tf: !this.state.showNameEditField.tf,
                              },
                            });
                          },
                          () => {
                            this.updateProduct(
                              data.id,
                              data.catid,
                              data.subcatid,
                              data.price,
                              "name"
                            );
                          })
                        }
                      >
                        <i className="fa fa-check"></i>
                      </button>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="cat-card-title">{data.name}</div>
                      <button
                        className="btn cat-card-btn"
                        onClick={() => {
                          this.setState({
                            showNameEditField: {
                              value: data.id,
                              tf: !this.state.showNameEditField.tf,
                            },
                            newName: data.name,
                          });
                        }}
                      >
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </div>
                  )}
                </h4>
                <h6>
                  <small className="card-text">
                    {this.state.showPriceEditField.tf &&
                    this.state.showPriceEditField.value === data.id ? (
                      <div className="row">
                        <input
                          type="number"
                          step="0.01"
                          className="form-control form-control-sm col-8"
                          onChange={(e) =>
                            this.setState({
                              newPrice: e.target.value,
                            })
                          }
                          defaultValue={data.price}
                          id={data.id}
                        />
                        <button
                          className="btn cat-card-btn"
                          onClick={
                            (() => {
                              this.setState({
                                showPriceEditField: {
                                  value: data.id,
                                  tf: !this.state.showPriceEditField.tf,
                                },
                              });
                            },
                            () => {
                              this.updateProduct(
                                data.id,
                                data.catid,
                                data.subcatid,
                                data.name,
                                "price"
                              );
                            })
                          }
                        >
                          <i className="fa fa-check"></i>
                        </button>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="cat-card-title">
                          Rs.&nbsp;&nbsp;{data.price}
                        </div>
                        <button
                          className="btn cat-card-btn"
                          onClick={() => {
                            this.setState({
                              showPriceEditField: {
                                value: data.id,
                                tf: !this.state.showPriceEditField.tf,
                              },
                              newPrice: data.price,
                            });
                          }}
                        >
                          <i className="fa fa-pencil-square-o"></i>
                        </button>
                      </div>
                    )}
                  </small>
                </h6>
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      onClick={() => {
                        this.deleteProduct(data.id);
                      }}
                      className="btn btn-outline-danger"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        {this.state.productData !== null && (
          <div className="grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">All Products</h4>
                <p className="card-description">Product Details</p>
                <div className="row">{ProductCatRows}</div>
              </div>
            </div>
          </div>
        )}
      </LoadingScreen>
    );
  }
}

export default AllProducts;
