import React, { Component } from "react";

class SideBar extends Component {
  collapse = (list) => {
    if (list === "employees") {
      document.getElementById("leaves").classList.remove("show");
      document.getElementById("leaves-anchor").classList.add("collapsed");
      document.getElementById("attendance").classList.remove("show");
      document.getElementById("attendance-anchor").classList.add("collapsed");
      document.getElementById("incentive").classList.remove("show");
      document.getElementById("incentive-anchor").classList.add("collapsed");
      document.getElementById("allowance").classList.remove("show");
      document.getElementById("allowance-anchor").classList.add("collapsed");
      document.getElementById("salary-slip").classList.remove("show");
      document.getElementById("salary-slip-anchor").classList.add("collapsed");
      document.getElementById("employees").classList.add("show");
      document.getElementById("employees-anchor").classList.remove("collapsed");
    } else if (list === "leaves") {
      document.getElementById("employees").classList.remove("show");
      document.getElementById("employees-anchor").classList.add("collapsed");
      document.getElementById("attendance").classList.remove("show");
      document.getElementById("attendance-anchor").classList.add("collapsed");
      document.getElementById("incentive").classList.remove("show");
      document.getElementById("incentive-anchor").classList.add("collapsed");
      document.getElementById("allowance").classList.remove("show");
      document.getElementById("allowance-anchor").classList.add("collapsed");
      document.getElementById("salary-slip").classList.remove("show");
      document.getElementById("salary-slip-anchor").classList.add("collapsed");
      document.getElementById("leaves").classList.add("show");
      document.getElementById("leaves-anchor").classList.remove("collapsed");
    } else if (list === "attendance") {
      document.getElementById("employees").classList.remove("show");
      document.getElementById("employees-anchor").classList.add("collapsed");
      document.getElementById("leaves").classList.remove("show");
      document.getElementById("leaves-anchor").classList.add("collapsed");
      document.getElementById("salary-slip").classList.remove("show");
      document.getElementById("salary-slip-anchor").classList.add("collapsed");
      document.getElementById("incentive").classList.remove("show");
      document.getElementById("incentive-anchor").classList.add("collapsed");
      document.getElementById("allowance").classList.remove("show");
      document.getElementById("allowance-anchor").classList.add("collapsed");
      document.getElementById("attendance").classList.add("show");
      document
        .getElementById("attendance-anchor")
        .classList.remove("collapsed");
    } else if (list === "incentive") {
      document.getElementById("employees").classList.remove("show");
      document.getElementById("employees-anchor").classList.add("collapsed");
      document.getElementById("leaves").classList.remove("show");
      document.getElementById("leaves-anchor").classList.add("collapsed");
      document.getElementById("attendance").classList.remove("show");
      document.getElementById("attendance-anchor").classList.add("collapsed");
      document.getElementById("salary-slip").classList.remove("show");
      document.getElementById("salary-slip-anchor").classList.add("collapsed");
      document.getElementById("incentive").classList.add("show");
      document.getElementById("incentive-anchor").classList.remove("collapsed");
      document.getElementById("allowance").classList.add("show");
      document.getElementById("allowance-anchor").classList.remove("collapsed");
    } else if (list === "allowance") {
      document.getElementById("employees").classList.remove("show");
      document.getElementById("employees-anchor").classList.add("collapsed");
      document.getElementById("leaves").classList.remove("show");
      document.getElementById("leaves-anchor").classList.add("collapsed");
      document.getElementById("attendance").classList.remove("show");
      document.getElementById("attendance-anchor").classList.add("collapsed");
      document.getElementById("salary-slip").classList.remove("show");
      document.getElementById("salary-slip-anchor").classList.add("collapsed");
      document.getElementById("incentive").classList.remove("show");
      document.getElementById("incentive-anchor").classList.add("collapsed");
      document.getElementById("allowance").classList.add("show");
      document.getElementById("allowance-anchor").classList.remove("collapsed");
    } else if (list === "salary") {
      document.getElementById("employees").classList.remove("show");
      document.getElementById("employees-anchor").classList.add("collapsed");
      document.getElementById("leaves").classList.remove("show");
      document.getElementById("leaves-anchor").classList.add("collapsed");
      document.getElementById("attendance").classList.remove("show");
      document.getElementById("attendance-anchor").classList.add("collapsed");
      document.getElementById("incentive").classList.remove("show");
      document.getElementById("incentive-anchor").classList.add("collapsed");
      document.getElementById("allowance").classList.remove("show");
      document.getElementById("allowance-anchor").classList.add("collapsed");
      document.getElementById("salary-slip").classList.add("show");
      document
        .getElementById("salary-slip-anchor")
        .classList.remove("collapsed");
    } else if (list === "category") {
      document.getElementById("subCategories").classList.remove("show");
      document
        .getElementById("subCategories-anchor")
        .classList.add("collapsed");
      document.getElementById("products").classList.remove("show");
      document.getElementById("products-anchor").classList.add("collapsed");
      document.getElementById("category").classList.add("show");
      document.getElementById("category-anchor").classList.remove("collapsed");
    } else if (list === "subCategories") {
      document.getElementById("category").classList.remove("show");
      document.getElementById("category-anchor").classList.add("collapsed");
      document.getElementById("products").classList.remove("show");
      document.getElementById("products-anchor").classList.add("collapsed");
      document.getElementById("subCategories").classList.add("show");
      document
        .getElementById("subCategories-anchor")
        .classList.remove("collapsed");
    } else if (list === "products") {
      document.getElementById("category").classList.remove("show");
      document.getElementById("category-anchor").classList.add("collapsed");
      document.getElementById("subCategories").classList.remove("show");
      document
        .getElementById("subCategories-anchor")
        .classList.add("collapsed");
      document.getElementById("products").classList.add("show");
      document.getElementById("products-anchor").classList.remove("collapsed");
    } else if (list === "customer") {
      document.getElementById("invoice").classList.remove("show");
      document.getElementById("invoice-anchor").classList.add("collapsed");
      // document.getElementById("customer").classList.remove("show");
      // document.getElementById("customer-anchor").classList.add("collapsed");
      document.getElementById("customer").classList.add("show");
      document.getElementById("customer-anchor").classList.remove("collapsed");
    } else if (list === "invoice") {
      document.getElementById("customer").classList.remove("show");
      document.getElementById("customer-anchor").classList.add("collapsed");
      // document.getElementById("customer").classList.remove("show");
      // document.getElementById("customer-anchor").classList.add("collapsed");
      document.getElementById("invoice").classList.add("show");
      document.getElementById("invoice-anchor").classList.remove("collapsed");
    } else if (list === "all-invoices") {
      document.getElementById("customer").classList.remove("show");
      document.getElementById("customer-anchor").classList.add("collapsed");
      // document.getElementById("customer").classList.remove("show");
      // document.getElementById("customer-anchor").classList.add("collapsed");
      document.getElementById("all-invoices").classList.add("show");
      document
        .getElementById("all-invoices-anchor")
        .classList.remove("collapsed");
    }
  };

  render() {
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/home"
    ) {
      return null;
    } else {
      return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
          {window.location.pathname.includes("emp") ? (
            <ul className="nav">
              <li className="nav-item nav-profile">
                <a href="/home" className="nav-link">
                  <div className="text-wrapper">
                    <p className="profile-name">Home</p>
                  </div>
                </a>
              </li>
              <li className="nav-item nav-category">Employee Menu</li>
              <li
                className="nav-item"
                onClick={() => {
                  this.collapse("employees");
                }}
              >
                <a className="nav-link" href="#employees" id="employees-anchor">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Employee</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="employees">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/addemp">
                        <span className="menu-title">Add Employee</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/allemp">
                        <span className="menu-title">View Employees</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.collapse("attendance");
                }}
              >
                <a
                  className="nav-link"
                  href="#attendance"
                  id="attendance-anchor"
                >
                  <i className="menu-icon typcn typcn-document-add"></i>
                  <span className="menu-title">Attendance</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="attendance">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/addattendance">
                        <span className="menu-title">Add Attendance</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/allattendance">
                        <span className="menu-title">View Attendance</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/attendancesummery">
                        <span className="menu-title">
                          View Attendance Summery
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.collapse("leaves");
                }}
              >
                <a className="nav-link" href="#leaves" id="leaves-anchor">
                  <i className="menu-icon typcn typcn-document-add"></i>
                  <span className="menu-title">Leaves</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="leaves">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/submitleave">
                        <span className="menu-title">Submit Leave</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/allleaves">
                        <span className="menu-title">View Leaves</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.collapse("incentive");
                }}
              >
                <a className="nav-link" href="#incentive" id="incentive-anchor">
                  <i className="menu-icon typcn typcn-document-add"></i>
                  <span className="menu-title">Incentives</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="incentive">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/addincentive">
                        <span className="menu-title">Add Incentive</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/allincentives">
                        <span className="menu-title">View Incentive</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.collapse("allowance");
                }}
              >
                <a className="nav-link" href="#allowance" id="allowance-anchor">
                  <i className="menu-icon typcn typcn-document-add"></i>
                  <span className="menu-title">Allowances</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="allowance">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/addallowance">
                        <span className="menu-title">Add Allowance</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/allallowances">
                        <span className="menu-title">View Allowance</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.collapse("salary");
                }}
              >
                <a className="nav-link" href="#salary" id="salary-slip-anchor">
                  <i className="menu-icon typcn typcn-document-add"></i>
                  <span className="menu-title">Salary Slip</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="salary-slip">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/createsalaryslip">
                        <span className="menu-title">Create Salary Slip</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/salaryadvance">
                        <span className="menu-title">Add Salary Advance</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/loans">
                        <span className="menu-title">Loans</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/emp/viewslaryslips">
                        <span className="menu-title">View Salary Slips</span>
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" href="/emp/salarytable">
                        <span className="menu-title">Salary Table</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          ) : window.location.pathname.includes("customer") ||
            window.location.pathname.includes("invoice") ? (
            <ul className="nav">
              <li className="nav-item nav-profile">
                <a href="/home" className="nav-link">
                  <div className="text-wrapper">
                    <p className="profile-name">Home</p>
                  </div>
                </a>
              </li>
              <li className="nav-item nav-category">Customer Menu</li>
              <li
                className="nav-item"
                onClick={() => {
                  this.collapse("customer");
                }}
              >
                <a className="nav-link" href="#customer" id="customer-anchor">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Customer</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="customer">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <a className="nav-link" href="/customer/allcustomers">
                        <span className="menu-title">Add/View Customer</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.collapse("invoice");
                }}
              >
                <a className="nav-link" href="#invoice" id="invoice-anchor">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Invoice</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="invoice">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <a className="nav-link" href="/createinvoice">
                        <span className="menu-title">Create Invoice</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/allinvoices">
                        <span className="menu-title">View Invoices</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          ) : window.location.pathname.includes("product") ? (
            <ul className="nav">
              <li className="nav-item nav-profile">
                <a href="/home" className="nav-link">
                  <div className="text-wrapper">
                    <p className="profile-name">Home</p>
                  </div>
                </a>
              </li>
              <li className="nav-item nav-category">Products Menu</li>
              <li
                className="nav-item"
                onClick={() => {
                  this.collapse("category");
                }}
              >
                <a className="nav-link" href="#category" id="category-anchor">
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Category</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="category">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <a className="nav-link" href="/product/addcategory">
                        <span className="menu-title">Add Category</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/product/allcategories">
                        <span className="menu-title">View Categories</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.collapse("subCategories");
                }}
              >
                <a
                  className="nav-link"
                  href="#subCategories"
                  id="subCategories-anchor"
                >
                  <i className="menu-icon typcn typcn-document-add"></i>
                  <span className="menu-title">Sub Categories</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="subCategories">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <a className="nav-link" href="/product/addsubcategory">
                        <span className="menu-title">Add Sub Category</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/product/allsubcategories">
                        <span className="menu-title">View Sub Categories</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.collapse("products");
                }}
              >
                <a className="nav-link" href="#products" id="products-anchor">
                  <i className="menu-icon typcn typcn-document-add"></i>
                  <span className="menu-title">Products</span>
                  <i className="menu-arrow"></i>
                </a>
                <div className="collapse" id="products">
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <a className="nav-link" href="/product/addproduct">
                        <span className="menu-title">Add Product</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/product/allproducts">
                        <span className="menu-title">View Products</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          ) : window.location.pathname.includes("pettycash") ? (
            <ul className="nav">
              <li className="nav-item nav-profile">
                <a href="/home" className="nav-link">
                  <div className="text-wrapper">
                    <p className="profile-name">Home</p>
                  </div>
                </a>
              </li>
              <li className="nav-item nav-category">Petty Cash Menu</li>
                   
               <li
                className="nav-item"
                onClick={() => {
                  this.collapse("category");
                }}
              >
                <a
                  className="nav-link"
                  href="/pettycash/pettycashtypes"
                  id="category-anchor"
                >
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Petty Cash Types</span>
                  <i className="menu-arrow"></i>
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.collapse("category");
                }}
              >
                <a
                  className="nav-link"
                  href="/pettycash/all"
                  id="category-anchor"
                >
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Petty Cash</span>
                  <i className="menu-arrow"></i>
                </a>
              </li>  
              
                       <li
                className="nav-item"
                onClick={() => {
                  this.collapse("category");
                }}
              >
                <a
                  className="nav-link"
                  href="/pettycash/report"
                  id="category-anchor"
                >
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Petty Cash Report</span>
                  <i className="menu-arrow"></i>
                </a>
              </li>
            </ul>
          )
          : window.location.pathname.includes("vehicles") ? (
<ul className="nav">
              <li className="nav-item nav-profile">
                <a href="/home" className="nav-link">
                  <div className="text-wrapper">
                    <p className="profile-name">Home</p>
                  </div>
                </a>
              </li>
              <li className="nav-item nav-category">Vehicles Menu</li>
                   
               <li
                className="nav-item"
                onClick={() => {
                  this.collapse("category");
                }}
              >
                <a
                  className="nav-link"
                  href="/vehicles/all"
                  id="category-anchor"
                >
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">All Vehicles</span>
                  <i className="menu-arrow"></i>
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  this.collapse("category");
                }}
              >
                <a
                  className="nav-link"
                  href="/vehicles/renewables"
                  id="category-anchor"
                >
                  <i className="menu-icon typcn typcn-coffee"></i>
                  <span className="menu-title">Renewables</span>
                  <i className="menu-arrow"></i>
                </a>
              </li>  
              
                      
            </ul>
          )
          : (
            <ul className="nav">
              <li className="nav-item nav-profile">
                <a href="/home" className="nav-link">
                  <div className="text-wrapper">
                    <p className="profile-name">Home</p>
                  </div>
                </a>
              </li>
            </ul>
          )}
        </nav>
      );
    }
  }
}

export default SideBar;
