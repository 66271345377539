import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";
import { Modal } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class Pettycash extends Component {
  
  constructor() {
    super();
    this.state = {
      token: null,
      loading: true,
      isOpen: false,
      pettycashes: null,
      companies: null,
      pettycashtypes: null,
      empData: null,
      isOpenReim: null,
      pctot: null,
      pcreimtot: null,
      companyid:null,
      startdate:null,
      enddate:null,
      payee:null
    };
  }


  openModal = () => this.setState({ isOpen: true });
  openModalOpenReim = () => this.setState({ isOpenReim: true });
  closeModal = () => this.setState({ isOpen: false });
  closeModalOpenReim = () => this.setState({ isOpenReim: false });

  printTable = (startdate, enddate) => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;

    const doc = new jsPDF();

    doc.autoTable({
      showHead: "everyPage",
      didDrawPage: function (data) {
        // Header
        doc.setFontSize(14);
        doc.setTextColor(40);

        doc.text(
          "Pettycash list:" + startdate + " to " + enddate,
          data.settings.margin.left,
          8
        );

        // Footer
        var str = "Issue Date " + today;

        doc.setFontSize(10);

        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
      styles: {},
      html: "#printtable",
    });

    doc.save("pettycashreport.pdf");
  };

  getEmpIds = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/employee/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.employees[0]) {
              this.setState({
                empData: response.data.employees,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  getCompanies = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/company/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.companies[0]) {
              this.setState({
                companies: response.data.companies,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  getPettycash = () => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/pettycash/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.pettycashes[0]) {
              this.setState({
                pettycashes: res.data.pettycashes,
                pctot: res.data.pctotal,
                pcreimtot: res.data.pctotalreim,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }; 
  
  search = () => {
    this.setState({
      pettycashes: null,
   
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/pettycash/search/" + this.state.cid + "&" + this.state.startdate + "&" + this.state.enddate + "&" + this.state.payee,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.pettycashes[0]) {
              this.setState({
                pettycashes: res.data.pettycashes,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  addpettycashtype = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      var data = new FormData();

      data.append("type", this.state.type);

      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/pettycashtype",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              loading: false,
            });
            swal({
              position: "center",
              icon: "success",
              title: "Petty cash type added",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getPettycashtypes();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal("Oops...", "Error occured", "error");
        });
    }
  };

  deletePettycash = (e) => {
    swal({
      title: "Do you want to delete?",
      text: "Are you sure that you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        this.setState({
          loading: true,
        });

        if (this.state.token) {
          if (e) {
            var config = {
              method: "delete",
              url: process.env.REACT_APP_BASE_URL + `/pettycash/${e}`,
              headers: {
                Authorization: `Bearer ${this.state.token}`,
              },
            };

            axios(config)
              .then((res) => {
                if (res.data.success) {
                  this.setState({
                    loading: false,
                    pettycashtypes: null,
                  });
                  swal({
                    position: "center",
                    icon: "error",
                    title: "Petty cash deleted",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.getPettycash();
                } else {
                  this.setState({
                    status: "failed",
                    loading: false,
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  loading: false,
                });
                console.log(error);
              });
          } else {
            this.setState({
              loading: false,
            });
          }
        } else {
          this.setState({
            loading: false,
          });
        }
      }
    });
  };

  getPettycashtypes = () => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/pettycashtype/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.pettycashtypes[0]) {
              this.setState({
                pettycashtypes: res.data.pettycashtypes,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  addpettycash = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      var data = new FormData();

      data.append("companyid", e.target.companyid.value);
      data.append("typeid", e.target.typeid.value);
      data.append("startdate", e.target.startdate.value);
      data.append("enddate", e.target.enddate.value);
      data.append("payee", e.target.payee.value);
      data.append("payeetel", e.target.payeetel.value);
      data.append("amount", e.target.amount.value);
      data.append("description", e.target.description.value);
      data.append("reference", e.target.reference.value);
      data.append("authorizedby", e.target.authorizedby.value);
      data.append("paidby", e.target.paidby.value);
      data.append("receivedby", e.target.receivedby.value);

      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/pettycash",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              loading: false,
              isOpen: false,
            });
            document.getElementById("pettycashform").reset();
            swal({
              position: "center",
              icon: "success",
              title: "Voucher Added",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getPettycash();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal("Oops...", "Error occured", "error");
        });
    }
  };

  addpettycashreim = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      var data = new FormData();

      data.append("companyid", e.target.companyid.value);
      data.append("date", e.target.date.value);
      data.append("amount", e.target.amount.value);
      data.append("reference", e.target.reference.value);
      data.append("authorizedby", e.target.authorizedby.value);

      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/pettycash/reim",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              loading: false,
              isOpen: false,
            });
            document.getElementById("reimform").reset();
            swal({
              position: "center",
              icon: "success",
              title: "Reim Added",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getPettycash();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal("Oops...", "Error occured", "error");
        });
    }
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getPettycash();
    this.getCompanies();
    this.getPettycashtypes();
    this.getEmpIds();
  }

  render() {
    var emps = this.state.empData ? (
      this.state.empData.map((data, i) => {
        return (
          <option value={data.id ? data.id : ""} key={i}>
            {data.name ? data.name : "No Data"}
          </option>
        );
      })
    ) : (
      <option value="">No Data</option>
    );

    var companies = this.state.companies ? (
      this.state.companies.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.name}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );
    var pettycashtypes = this.state.pettycashtypes ? (
      this.state.pettycashtypes.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.type}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );
    var pettycashes = this.state.pettycashes ? (
      this.state.pettycashes.map((data, i) => {
        return (
          <tr>
            <td> {data.company}</td>
            <td> {data.vid}</td>
            <td> {data.payee}</td>
            <td> {data.type}</td>
            <td> {data.startdate}</td>
            <td> {data.enddate}</td>
            <td> {data.amount}</td>
            <td> {data.description}</td>
            <td>
              <span class="badge badge-primary">{data.preparedby}</span>{" "}
            </td>
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );

    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="pd-20 card p-4 mb-4">
          <div class="clearfix">
            <div class="pull-left d-flex w-100">
              <h1 class="text-center text-blue h2">Petty Cash</h1>
            </div>
            <div class="row">
              <div class="col">
                <input
                  type="text"
                  onChange={(e) => this.setState({ type: e.target.value })}
                  required
                  name="type"
                  placeholder="Petty Cash Type"
                  class="form-control"
                />
              </div>

              <div class="col">
                <button
                  type="button"
                  class="ml-auto btn btn-lg btn-primary btn-block"
                  onClick={this.addPettycashtype}
                >
                  Add
                </button>
              </div>

              <div class="col">
                <button
                  type="button"
                  class="ml-auto btn btn-lg btn-primary btn-block"
                  onClick={this.openModal}
                >
                  Add a Voucher
                </button>
              </div>

              <div class="col">
                <button
                  type="button"
                  class="ml-auto btn btn-lg btn-info btn-block"
                  onClick={this.openModalOpenReim}
                >
                  Add Reimbursement
                </button>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col">
              <select name="companyid" class="form-control"
                onChange={(e)=>this.setState({cid:e.target.value})}
              required>
                    <option value="">Select a Company</option>
                    {companies ? companies : <option value="">No Data</option>}
                  </select>
              </div>
              <div class="col">
              <input 
                type="date"
                onChange={(e)=>this.setState({startdate: e.target.value})}
               
                class="form-control"

                />
              </div>   
              
                    <div class="col">
              <input 
                type="date"
                onChange={(e)=>this.setState({enddate: e.target.value})}
               
                class="form-control"

                />
              </div>
              <div class="col">
                <input type="text" 
                class="form-control"
                placeholder="Payee"
                onChange={(e)=>this.setState({payee: e.target.value})}
               
                />
              </div>
              <div class="col">
                <input type="button"
                class="btn btn-warning  btn-block"
                value="Search"
                onClick={(this.search)}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="row">
            <div class="col">
              <div class="alert alert-success" role="alert">
                <b class="text-uppercase">Savoga Enterprises -></b>
                <b> Petty cash total: Rs.</b> {this.state.pctot?.pctotalsavoga ? this.state.pctot?.pctotalsavoga : 0 } |{" "}
                <b> Reimbursement total: Rs.</b>{" "}
                {this.state.pcreimtot?.pctotalsavogareim ? this.state.pcreimtot?.pctotalsavogareim : 0} |
                <b> Remaining Balance: Rs.</b>{" "}
                {(parseInt(this.state.pcreimtot?.pctotalsavogareim) ? parseInt(this.state.pcreimtot?.pctotalsavogareim) : 0 )- (parseInt(this.state.pctot?.pctotalsavoga) ? parseInt(this.state.pctot?.pctotalsavoga) : 0 )}
              </div>
            </div>
          </div>      
          
           <div class="row">
            <div class="col">
              <div class="alert alert-primary" role="alert">
                <b class="text-uppercase">Savoga Enterprises (PVT) Ltd -></b>
                <b> Petty cash total: Rs.</b> {this.state.pctot?.pctotalsavogapvt ? this.state.pctot?.pctotalsavogapvt :0} |{" "}
                <b> Reimbursement total: Rs.</b>{" "}
                {this.state.pcreimtot?.pctotalsavogapvtreim ? this.state.pcreimtot?.pctotalsavogapvtreim : 0} |
                <b> Remaining Balance: Rs.</b>{" "} 
                {(parseInt(this.state.pcreimtot?.pctotalsavogapvtreim) ? parseInt(this.state.pcreimtot?.pctotalsavogapvtreim) : 0) - (parseInt(this.state.pctot?.pctotalsavogapvt) ? parseInt(this.state.pctot?.pctotalsavogapvt) : 0)}
              </div>
            </div>
          </div>     
          
              <div class="row">
            <div class="col">
              <div class="alert alert-warning" role="alert">
                <b class="text-uppercase">Seijitsu Lanka (Pvt) Ltd -></b>
                <b> Petty cash total: Rs.</b> {this.state.pctot?.pctotalsej ? this.state.pctot?.pctotalsej : 0} |{" "}
                <b> Reimbursement total: Rs.</b>{" "}
                {this.state.pcreimtot?.pctotalsejreim ? this.state.pcreimtot?.pctotalsejreim : 0} |
                <b> Remaining Balance: Rs.</b>{" "}
                {(parseInt(this.state.pcreimtot?.pctotalsejreim) ? parseInt(this.state.pcreimtot?.pctotalsejreim) : 0 ) - (parseInt(this.state.pctot?.pctotalsej) ? parseInt(this.state.pctot?.pctotalsej) : 0 )}
              </div>
            </div>
          </div>
          <div class="row">
              <div class="col">
                <button
                  class="btn btn-info btn-block"
                  onClick={(e)=>this.printTable(
                    this.state.startdate,
                    this.state.enddate
                  )}
                >
                  Print
                </button>
              </div>
            </div>
          <div class="table-responsive">
            <table id="printtable" class="table table-bordered">
              <thead>
                <tr>
                  <th class="text-center">Company</th>
                  <th class="text-center">V. No</th>
                  <th class="text-center">Payee</th>
                  <th class="text-center">Type</th>
                  <th class="text-center">Start Date</th>
                  <th class="text-center">End date</th>
                  <th class="text-center">Amount</th>
                  <th class="text-center">Description</th>
                  <th class="text-center">Prepared By</th>
                </tr>
              </thead>
              <tbody>
                {pettycashes ? pettycashes : <tr value="">No Data</tr>}
              </tbody>
            </table>
  
          </div>
        </div>

        <Modal show={this.state.isOpenReim} onHide={this.closeModalOpenReim}>
          <Modal.Header closeButton>
            <Modal.Title>Add a Petty Cash Reimbursement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => this.addpettycashreim(e)} id="reimform">
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Company:
                </label>
                <div class="col-sm-12 col-md-10">
                  <select name="companyid" class="form-control" 
                
                  required>
                    <option value="">Select</option>
                    {companies ? companies : <option value="">No Data</option>}
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Date:</label>
                <div class="col-sm-12 col-md-10">
                  <input
                  
                    class="form-control"
                    type="date"
                    name="date"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Amount:</label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="number"
                    placeholder="Amount"
                    name="amount"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Reference:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Reference"
                    name="reference"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Authorized By:
                </label>
                <div class="col-sm-12 col-md-10">
                  <select name="authorizedby" class="form-control" required>
                    <option value="">Select</option>
                    {emps ? emps : <option value="">No Data</option>}
                  </select>
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-info">Save</button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add a Petty Cash Voucher</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => this.addpettycash(e)} id="pettycashform">
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Company:
                </label>
                <div class="col-sm-12 col-md-10">
                  <select name="companyid" class="form-control" required>
                    <option value="">Select</option>
                    {companies ? companies : <option value="">No Data</option>}
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Petty Cash Type:
                </label>
                <div class="col-sm-12 col-md-10">
                  <select name="typeid" class="form-control" required>
                    <option value="">Select</option>
                    {pettycashtypes ? (
                      pettycashtypes
                    ) : (
                      <option value="">No Data</option>
                    )}
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Start Date:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="date"
                    name="startdate"
                    required
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  End Date:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="date"
                    name="enddate"
                    required
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Payee:</label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Payee"
                    name="payee"
                    required
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Payee Phone:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Payee"
                    name="payeetel"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Amount:</label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Amount"
                    name="amount"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Description:
                </label>
                <div class="col-sm-12 col-md-10">
                  <textarea
                    class="form-control"
                    type="text"
                    placeholder="Description"
                    name="description"
                    required
                  ></textarea>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Reference:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Reference"
                    name="reference"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Authorized By:
                </label>
                <div class="col-sm-12 col-md-10">
                  <select name="authorizedby" class="form-control" required>
                    <option value="">Select</option>
                    {emps ? emps : <option value="">No Data</option>}
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Paid By:
                </label>
                <div class="col-sm-12 col-md-10">
                  <select name="paidby" class="form-control" required>
                    <option value="">Select</option>
                    {emps ? emps : <option value="">No Data</option>}
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Received By:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Received By"
                    name="receivedby"
                    required
                  />
                </div>
              </div>

              <div class="text-center">
                <button class="btn btn-info">Save</button>{" "}
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </LoadingScreen>
    );
  }
}

export default Pettycash;
