import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";
class SingleEmployee extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      empData: null,
      empStatus: null,
      status: null,
      show: { value: null, tf: false },
      loading: false,
    };
  }

  getEmployee = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url:  process.env.REACT_APP_BASE_URL + `/employee/${this.props.match.params.emp.substring(
          3
        )}`,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.employee) {
              this.setState({
                empData: response.data.employee,
                empStatus:
                  response.data.employee.status === 1
                    ? "Active"
                    : response.data.employee.status === 0
                    ? "Not Active"
                    : "No Information",
                status: "success",
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Employee Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              empData: response.data.employee,
              show: { value: "Error Loading Employee Details!", tf: true },
              status: "failed",
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            empData: "not-found",
            show: { value: "Error Loading Employee Details!", tf: true },
            status: "failed",
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (this.state.status === "load-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "success") {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getEmployee();
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        {this.state.empData !== null && this.state.empData !== "not-found" && (
          <div className="card">
            <div className="card-body">
              <div className="profile-header">
                <div className="d-flex justify-content-center justify-content-md-between flex-wrap">
                  <div className="profile-info d-flex align-items-center justify-content-center flex-wrap mr-sm-3">
                    <img
                      className="rounded-circle img-lg img-employee mb-3 mb-sm-0"
                      src={
                        "https://savoga.ideacurl.com/images/" +
                        this.state.empData.photo
                      }
                      alt="profile image"
                    />
                    <div className="wrapper pl-sm-4">
                      <strong>
                        <h4 className="profile-user-name text-center text-sm-left">
                          {this.state.empData.name +
                            " (" +
                            this.state.empData.position +
                            ")"}
                        </h4>
                      </strong>
                      <p className="profile-user-designation text-center text-md-left my-2 my-md-0">
                        {this.state.empData.jd}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="profile-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row w-100 mt-4">
                      <div className="col-md-6 profile-data">
                        <h5>
                          <strong>Employee ID :</strong>{" "}
                          {" " + this.state.empData.customid}
                        </h5>
                        <h5>
                          <strong>Name :</strong>{" "}
                          {" " + this.state.empData.name}
                        </h5>
                        <h5>
                          <strong>Address :</strong>{" "}
                          {" " + this.state.empData.address}
                        </h5>
                        <h5>
                          <strong>
                            {this.state.empData.phone2 !== null &&
                            this.state.empData.phone2 !== "null"
                              ? "Phone Number 1 :"
                              : "Phone Number :"}
                          </strong>{" "}
                          {" " + this.state.empData.phone1}
                        </h5>
                        {this.state.empData.phone2 !== null &&
                        this.state.empData.phone2 !== "null" ? (
                          <h5>
                            <strong>Phone Number 2 :</strong>{" "}
                            {" " + this.state.empData.phone2}
                          </h5>
                        ) : null}
                        <h5>
                          <strong>NIC :</strong> {" " + this.state.empData.nic}
                        </h5>
                        <h5>
                          <strong>Company :</strong>{" "}
                          {" " + this.state.empData.companyname}
                        </h5>
                        <h5>
                          <strong>Position :</strong>{" "}
                          {" " + this.state.empData.position}
                        </h5>
                      </div>
                      <div className="col-md-6 profile-data">
                        <h5>
                          <strong>Job Description :</strong>{" "}
                          {" " + this.state.empData.jd}
                        </h5>
                        <h5>
                          <strong>Salary Type :</strong>{" "}
                          {" " + this.state.empData.salarytype}
                        </h5>
                        <h5>
                          <strong>Basic Salary :</strong>{" "}
                          {" Rs. " +
                            Number(this.state.empData.basicsalary).toFixed(2)}
                        </h5>
                        <h5>
                          <strong>OT Rate :</strong>{" "}
                          {" " + this.state.empData.otrate}
                        </h5>
                        <h5>
                          <strong>Bank :</strong>{" "}
                          {" " + this.state.empData.bank}
                        </h5>
                        <h5>
                          <strong>Account Number :</strong>{" "}
                          {" " + this.state.empData.bankacc}
                        </h5>
                        <h5>
                          <strong>Status :</strong> {" " + this.state.empStatus}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </LoadingScreen>
    );
  }
}

export default SingleEmployee;
