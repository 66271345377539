import React from "react";
import { Route } from "react-router-dom";

//auth
import auth from "./auth";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.isAuthenticated()) {
          return (
            <div>
              <Component {...props} />
            </div>
          );
        } else {
          return (window.location.href = "/");
        }
      }}
    />
  );
};
