import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert2";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class AllAttendance extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      attendances: null,
      loading: true,
      empData: null,
      selectedMonth: "",
      selectedYear: "",
    };
  }

  onDelete(e) {
    swal.fire({
      title: "Do you want to delete?",
      text: "Are you sure that you want to delete?",
      icon: "warning",
      showCancelButton: true,
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        this.setState({
          loading: true,
        });

        if (this.state.token) {
          if (e) {
            var config = {
              method: "delete",
              url: process.env.REACT_APP_BASE_URL + `/attendance/${e}`,
              headers: {
                Authorization: `Bearer ${this.state.token}`,
              },
            };

            axios(config)
              .then((res) => {
                if (res.data.success) {
                  this.setState({
                    status: "success",
                    show: {
                      value: "Successfully Deleted Attendance!",
                      tf: true,
                    },
                    loading: false,
                  });
                  this.getEmpIds();
                } else {
                  this.setState({
                    status: "failed",
                    show: { value: "Failed to Delete Attendance!", tf: true },
                    loading: false,
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  status: "failed",
                  show: { value: "Failed to Delete Attendance!", tf: true },
                  loading: false,
                });
              });
          } else {
            this.setState({
              status: "failed",
              show: { value: "Failed to Delete Attendance!", tf: true },
              loading: false,
            });
          }
        } else {
          this.setState({
            loading: false,
            status: "auth-failed",
            show: { value: "Authentication Error!", tf: true },
          });
        }
      }
    });
  }


  getEmpIds = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/employee/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.employees[0]) {
              this.setState({
                empData: response.data.employees,
                loading: false,
              });
              this.getAttendance();
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Employee Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
              status: "load-failed",
              show: { value: "Failed to Load Employees!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "load-failed",
            show: { value: "Failed to Load Employees!", tf: true },
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getAttendance = (empid) => {

    if(!empid || this.state.selectedMonth==null){
      swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Select an Employee & a month!',
      })
      
    }else{


    this.setState({
      attendances:null,
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/attendance/search/" + empid + "&" + this.state.selectedYear + "&" + this.state.selectedMonth,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.attendances[0]) {
              
              this.setState({
                attendances: res.data.attendances,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load Attendance Details!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load Attendance Details!", tf: true },
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  }
  };

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getEmpIds();
  }

  render() {
    var ids = this.state.empData ? (
      this.state.empData.map((data, i) => {
        return (
          <option value={data.id ? data.id : ""} key={i}>
            {data.name ? data.name : "No Data"}
          </option>
        );
      })
    ) : (
      <option value="">No Data</option>
    );
    var attendances = this.state.attendances ? (
      this.state.attendances.map((data, i) => {
        return (
          <tr>
            <td>{data.name}</td>
            <td>{data.date}</td>
            <td>{data.dayname}</td>
            <td>{data.time}</td>
            <td>{data.created_at}</td>
            <td><button class="btn btn-danger" onClick={e=>this.onDelete(data.id)}>Delete</button></td>
          </tr>
        );
      })
    ) : (
      <tr value="">No Data</tr>
    );

    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">All Attendance</h4>
              <p className="card-description">Attendance Details</p>
              <div className="float-right">
                <select
                  onChange={(e) =>{
                    this.getAttendance(e.target.value);
                  }
                  }
                  class="form-control"
                >
                  <option value="">Select an Employee</option>
                  {ids ? ids : <option value="">No Data</option>}
                </select>
              </div>
              <div className="float-right mb-4">
              <select onChange={e=>this.setState({selectedMonth:e.target.value})} class="form-control">
                <option value="">Select a Month</option>
                <option value="1">Janaury</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              </div>
              <div className="float-right mb-4">
              <select onChange={e=>this.setState({selectedYear:e.target.value})} class="form-control">
                <option value="">Select a Year</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                 <option value="2024">2024</option>
              </select>
              </div>
              
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Employee</th>
                    <th scope="col">Date</th>
                    <th scope="col">Day</th>
                    <th scope="col">Time</th>
                    <th scope="col">Marked At</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {attendances ? attendances : <tr value="">No Data</tr>}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default AllAttendance;
