import React, { Component } from "react";
import axios from "axios";
import FormData from "form-data";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class AddIncentive extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      status: null,
      show: { value: null, tf: false },
      loading: false,
      empData: [],
    };
  }

  getEmpIds = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url:  process.env.REACT_APP_BASE_URL + "/employee/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.employees[0]) {
              this.setState({
                empData: response.data.employees,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Employee Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
              status: "load-failed",
              show: { value: "Failed to Load Employees!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "load-failed",
            show: { value: "Failed to Load Employees!", tf: true },
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  addIncentive = (e) => {
    e.preventDefault();
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      if (e.target.incentive.value) {
        var data = new FormData();
        data.append("incentive", e.target.incentive.value);
        data.append("amount", e.target.amount.value);
        data.append("empid", e.target.empid.value);
        data.append("date", e.target.date.value);
        data.append("qty", e.target.qty.value);

        var config = {
          method: "post",
          url:  process.env.REACT_APP_BASE_URL + "/incentive",
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
          data: data,
        };

        axios(config)
          .then((response) => {
            if (response.data.success) {
              this.setState({
                status: "success",
                show: { value: "Incentive Successfully Added!", tf: true },
                loading: false,
              });
            } else {
              this.setState({
                status: "failed",
                show: { value: "Failed to Add Incentive!", tf: true },
                loading: false,
              });
            }
          })
          .catch((error) => {
            this.setState({
              status: "failed",
              show: { value: "Failed to Add Incentive!", tf: true },
              loading: false,
            });
          });
      } else {
        this.setState({
          status: "failed",
          show: { value: "Fill Required Fields!", tf: true },
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (this.state.status === "load-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "success") {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            document.getElementById("form").reset();
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount = () => {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"))
    this.getEmpIds();
  };

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Incentive</h4>
              <p className="card-description">Incentive Details</p>
              <form
                className="forms-sample"
                onSubmit={(e) => this.addIncentive(e)}
                id="form"
              >
                <div className="form-group">
                  <label htmlFor="incentive">Incentive</label>
                  <input
                    type="text"
                    className="form-control"
                    id="incentive"
                    required
                    placeholder="Incentive"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="amount">Amount</label>
                  <input
                    type="number"
                    step="0.01"
                    className="form-control"
                    id="amount"
                    required
                    placeholder="Amount"
                  />
                </div>         
                 <div className="form-group">
                  <label htmlFor="amount">Date</label>
                  <input
                    type="date"
                    className="form-control"
                    name="date"
                    required
                    placeholder="date"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="empid">Employee</label>
                  <select className="form-control" id="empid">
                    <option value="">Select Employee...</option>
                    {this.state.empData.map((data) => {
                      return <option value={data.id}>{data.name}</option>;
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="qty">Quantity</label>
                  <input
                    type="number"
                    className="form-control"
                    id="qty"
                    required
                    placeholder="Quantity"
                  />
                </div>
                <button type="submit" className="btn btn-success mr-2">
                  Submit
                </button>
                <button type="reset" className="btn btn-light">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default AddIncentive;
