import React, { Component, Fragment } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
// import { Modal, Dropdown, Table } from "react-bootstrap";
import loader from "../../Resources/Images/loader/spinner-1.gif";
import jsPDF from "jspdf";
import "jspdf-autotable";
class SalaryTable extends Component {
  state = {
    token: null,
    loading: false,
    emps: null,
    leavecount: null,
    ot: null,
    monthleavecount: null,
    incentivesProduction: null,
    incentivesBatta: null,
    incentivesTarget: null,
    incentivesAtendance: null,
    allowancesMedical: null,
    allowancesWelding: null,
    allowancesSpecial: null,
    allowancesFuel: null,
    allowancesMeal: null,
    salaryadvances: null,
    loans: null,
    tempcompanyname:null,
    selectedYear:null,
    selectedMonth:null,
  };

  printSalaryTable = (company) => {
    const doc = new jsPDF("l");

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    doc.autoTable(
      
      { 
        showHead: "everyPage",
        styles: { lineWidth: 1,lineColor:1 },
        didDrawPage: function (data) {

          // Header
          doc.setFontSize(20);
          doc.setTextColor(40);
  
          doc.text("Salary Table for :" + company, data.settings.margin.left, 8);
  
          // Footer
          var str = "Page " + doc.internal.getNumberOfPages();
  
          doc.setFontSize(10);
  
  
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.text(str, data.settings.margin.left, pageHeight - 10);
        },
        html: "#printtable"
    }
      
      );
    doc.save("table.pdf");
  };

  getSalaryTable =  (cid,company) => {
    this.setState({
      loading: false,
      tempcompanyname:company
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/salaryslip/salarytable/" + cid + "&" + this.state.selectedYear + "&" + this.state.selectedMonth,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

       axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.emps[0]) {
              this.setState({
                loading: false,
                emps: res.data.emps,
                leavecount: res.data.leavecount,
                ot: res.data.ot,
                monthleavecount: res.data.monthleavecount,
                incentivesProduction: res.data.incentivesProduction,
                incentivesBatta: res.data.incentivesBatta,
                incentivesTarget: res.data.incentivesTarget,
                incentivesAtendance: res.data.incentivesAtendance,
                allowancesMedical: res.data.allowancesMedical,
                allowancesWelding: res.data.allowancesWelding,
                allowancesSpecial: res.data.allowancesSpecial,
                allowancesFuel: res.data.allowancesFuel,
                allowancesMeal: res.data.allowancesMeal,
                salaryadvances: res.data.salaryadvances,
                loans: res.data.loans,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Not found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
  }

  render() {
    var ids = this.state.emps ? (
      this.state.emps.map((data, i) => {
        return (
          <tr>
            <td> {data.name}</td>
            <td> {parseInt(data.basicsalary) + 3000}</td>

            <td> {this.state.ot[i].othours}</td>
            <td> {this.state.incentivesProduction[i].incentiveamount}</td>
            <td> {this.state.incentivesTarget[i].incentiveamount}</td>
            <td> {this.state.monthleavecount[i].attendanceincentive}</td>
            <td> {this.state.incentivesBatta[i].incentivesBatta}</td>
            <td> {this.state.allowancesWelding[i].amount}</td>
            <td> {this.state.allowancesMedical[i].amount}</td>

            <td> {parseInt(this.state.allowancesMeal[i].meal) * 150}</td>
            <td> {parseInt(this.state.allowancesFuel[i].fuel) * 100}</td>
            <td> {this.state.allowancesSpecial[i].amount}</td>
            <td>
            {this.state.loans[i].amount ? parseInt(this.state.loans[i].amount)-parseInt(this.state.loans[i].paidtotal) : 0}
            </td>
            <td> {this.state.monthleavecount[i].leavecount}</td>
            <td> {this.state.leavecount[i].leavecount}</td>
            {/* Worked Hours is below */}
            <td> {this.state.allowancesFuel[i].fuel}</td> 
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );
    var empids = this.state.empData ? (
      this.state.empData.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.name}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );

    var payments = this.state.payments ? (
      this.state.payments.map((data, i) => {
        return (
          <tr>
            <td> {data.created_at}</td>

            <td> {data.amount}</td>
            <td> {data.remarks}</td>
            <td> {data.fullname}</td>
            <td>
              {" "}
              <span class="badge badge-info">{data.name}</span>
            </td>
            <td>
              {" "}
              <button
                onClick={() => this.deletePayment(data.id)}
                class="btn btn-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="pd-20 card p-4 mb-4">
          <div class="clearfix">
            <div class="pull-left d-flex w-100">
              <h1 class="text-center text-blue h2">Salary Table</h1>
              <br />
              <div class="col">
              <select value={this.state.selectedYear} onChange={e=>this.setState({selectedYear:e.target.value})} class="form-control">
                <option value="">Select a Year</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
      
              </select>
              </div>
              <div class="col">
              <select value={this.state.selectedMonth} onChange={e=>this.setState({selectedMonth:e.target.value})} class="form-control">
                <option value="">Select a Month</option>
                <option value="1">Janaury</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
              </div>
            
            </div>
            <div class="row">
              <div class="col">
                <button
                onClick={()=>this.getSalaryTable(3,"Savoga Enterprises")}
                class="btn btn-lg btn-primary">Savoga Enterprises</button>
              </div>
              <div class="col">
                <button
                     onClick={()=>this.getSalaryTable(2,"Seijitsu Lanka (Pvt) Ltd")}
                class="btn btn-lg btn-primary">Seijitsu Lanka (Pvt) Ltd</button>
              </div>
              <div class="col">
                <button
                 onClick={()=>this.getSalaryTable(1,"Savoga Enterprises (Pvt) Ltd")}
                class="btn btn-lg btn-primary">
                  Savoga Enterprises (Pvt) Ltd
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table id="printtable" class="table table-bordered">
              <thead>
                <tr>
                  <th class="text-center">NAME</th>
                  <th class="text-center">BASIC SALARY</th>
                  <th class="text-center">O/T</th>
                  <th colSpan="4" class="text-center">
                    INCENTIVE
                  </th>
                  <th colSpan="4" class="text-center">
                    ALLOWANCE
                  </th>

                  <th></th>

                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th>Basic + GRA 1 + GRA 2</th>
                  <th></th>
                  <th>Production</th>
                  <th>Target</th>
                  <th>Atendance</th>
                  <th>Batta</th>

                  <th>Welding</th>
                  <th>Medical</th>
                  <th>Meal</th>
                  <th>Fuel</th>

                  <th>SP Bonus</th>
                  <th>Loan</th>
                  <th>Leaves</th>
                  <th>Leaves Left</th>
                  <th>Worked Days</th>
                </tr>
              </thead>
              <tbody>{ids ? ids : <tr value="">No Data</tr>}</tbody>
            </table>
            <button onClick={()=>this.printSalaryTable(this.state.tempcompanyname)}>Print Table</button>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default SalaryTable;
