import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import { Table, Tbody, Tr, Th, Td, Thead } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import loader from "../../Resources/Images/loader/spinner-1.gif";

import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

export class ViewSalarySlip extends Component {
  constructor() {
    super();
    this.state = {
      show: { value: null, tf: false },
      loading: false,
      status: null,
      salarySlips: [],
      token: null,
      empData: [],
      empId: null,
      year: null,
      selectedYear: null,
      month: null,
    };
  }



  deleteSlip = (e) => {
    swal({
      title: "Do you want to delete?",
      text: "Are you sure that you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        this.setState({
          show: { value: null, tf: false },
          loading: true,
        });

        if (this.state.token) {
          var config = {
            method: "delete",
            url: process.env.REACT_APP_BASE_URL + `/salaryslip/${e}`,
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          };

          axios(config)
            .then((res) => {
              if (res.data.success) {
                this.setState({
                  status: "success",
                  show: { value: "Successfully Deleted the Slip!", tf: true },
                  loading: false,
                });
              } else {
                this.setState({
                  status: "failed",
                  loading: false,
                });
              }
            })
            .catch((error) => {
              this.setState({
                status: "failed",
                show: { value: "Failed to Delete Product!", tf: true },
                loading: false,
              });
            });
        } else {
          this.setState({
            loading: false,
            status: "auth-failed",
            show: { value: "Authentication Error!", tf: true },
          });
        }
      }
    });
  };

  getSalarySlips = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + `/salaryslip/allslips/${this.state.year}&${this.state.month}`,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.slips[0]) {
              const resArray = res.data.slips;
              resArray.map((data, i) => {
                return this.state.empData.map((emp) => {
                  if (emp.id === data.empid) {
                    resArray[i].empid = emp.name;
                  }
                });
              });
              this.setState({
                salarySlips: resArray,
                loading: false,
              });
            } else {
              this.setState({
                salarySlips: [],
                status: "empty",
                show: { value: "No Salary Slip Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              salarySlips: [],
              status: "load-failed",
              show: {
                value: "Failed to Load Salary Slip Details!",
                tf: true,
              },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            salarySlips: [],
            status: "load-failed",
            show: {
              value: "Failed to Load Salary Slip Details!",
              tf: true,
            },
            loading: false,
          });
        });
    } else {
      this.setState({
        salarySlips: [],
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (this.state.status === "load-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "success") {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
  }

  render() {
    const rows = this.state.salarySlips?.map((data, i) => {
      return (
        <Tr key={i} className="cursor-pointer">
          <Td
            scope="row"
            onClick={() =>
              (window.location.href = `/emp/viewsingleslaryslip/${data.id}`)
            }
          >
            {data.empid}
          </Td>
          <Td
            onClick={() =>
              (window.location.href = `/emp/viewsingleslaryslip/${data.id}`)
            }
          >
            {data.year}
          </Td>
          <Td
            onClick={() =>
              (window.location.href = `/emp/viewsingleslaryslip/${data.id}`)
            }
          >
            {data.month}
          </Td>
          <Td
            onClick={() =>
              (window.location.href = `/emp/viewsingleslaryslip/${data.id}`)
            }
          >
            {data.basic}
          </Td>
          <Td
            onClick={() =>
              (window.location.href = `/emp/viewsingleslaryslip/${data.id}`)
            }
          >
            {data.otamount}
          </Td>
          <Td
            onClick={() =>
              (window.location.href = `/emp/viewsingleslaryslip/${data.id}`)
            }
          >
            {data.othours}
          </Td>
          <Td
            onClick={() =>
              (window.location.href = `/emp/viewsingleslaryslip/${data.id}`)
            }
          >
            {data.leavehours}
          </Td>
          <Td
            onClick={() =>
              (window.location.href = `/emp/viewsingleslaryslip/${data.id}`)
            }
          >
            {data.lunchcount}
          </Td>
          <Td>
            <button
              className="btn btn-inverse-info"
              onClick={() =>
                (window.location.href = `/emp/updatesalaryslip/${data.id}`)
              }
            >
              Update
            </button>
          </Td>
          <Td>
            <Link
              className="btn btn-warning ml-auto my-auto"
              to={{
                pathname: `/emp/slips/preview/${data.id}`,
              }}
            >
              Print
            </Link>
          </Td>
          <Td>
            <Link
              className="btn btn-danger ml-auto my-auto"
              onClick={() => {
                this.deleteSlip(data.id);
              }}
            >
              Delete
            </Link>
          </Td>
        </Tr>
      );
    });

    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Salary Slips</h4>
              <p className="card-description">Salary Slips Details</p>
              <div className="row p-0 m-0">
                {/* <div className="float-left mb-4 mr-2 my-auto">
                  <select
                    className="form-control"
                    onChange={(e) => this.setState({ empId: e.target.value })}
                  >
                    <option value="">Select Employee...</option>
                    {this.state.empData.map((data) => {
                      return <option value={data.id}>{data.name}</option>;
                    })}
                  </select>
                </div> */}
                <div className="float-left mb-4 mr-2 my-auto">
                  <DatePicker
                    className="form-control"
                    selected={this.state.selectedYear}
                    onChange={(date) =>
                      this.setState({
                        selectedYear: date,
                        year: new Date(date).getFullYear(),
                      })
                    }
                    showYearPicker
                    isClearable
                    dateFormat="yyyy"
                    placeholderText="Select Year..."
                  />
                </div>
                <div className="float-left mb-4 mr-2 my-auto">
                  <select
                    className="form-control"
                    onChange={(e) => this.setState({ month: e.target.value })}
                  >
                    <option value="">Select Month...</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div className="float-left my-auto">
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      this.getSalarySlips(this.state.year, this.state.month)
                    }
                  >
                    Search
                  </button>
                </div>
            
              </div>
            
              {this.state.salarySlips.length > 0 && (
                <div className="row p-0 m-0 overflow-auto">
                  <Table className="table">
                    <Thead>
                      <Tr>
                        <Th>Employee</Th>
                        <Th>Year</Th>
                        <Th>Month</Th>
                        <Th>Basic Salary</Th>
                        <Th>OT Amount</Th>
                        <Th>OT Hours</Th>
                        <Th>Leave Hours</Th>
                        <Th>Lunch Count</Th>
                      </Tr>
                    </Thead>
                    <Tbody>{rows}</Tbody>
                  </Table>
                </div>
              )}
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default ViewSalarySlip;
