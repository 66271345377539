import React, { Component } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class AllVehicles extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      loading: true,
      companies:null,
      isOpen: false,
      vehicles:null
    };
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  getCompanies = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/company/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.companies[0]) {
              this.setState({
                companies: response.data.companies,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Company Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
              status: "type-failed",
              show: { value: "Failed to load companies!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "type-failed",
            show: { value: "Failed Loading Employees!", tf: true },
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getVehicles = () => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/vehicle/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.vehicles[0]) {
              this.setState({
                vehicles: res.data.vehicles,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No vehicles Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load vehicles!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load vehicles!", tf: true },
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  addVehicle = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      var data = new FormData();

      data.append("vname", e.target.vname.value);
      data.append("make", e.target.make.value);
      data.append("model", e.target.model.value);
      data.append("color", e.target.color.value);
      data.append("myear", e.target.myear.value);
      data.append("ryear", e.target.ryear.value);
      data.append("plate", e.target.plate.value);
      data.append("fuel", e.target.fuel.value);
      data.append("companyid", e.target.companyid.value);
      data.append("price", e.target.price.value);

      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/vehicle",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              loading: false,
              isOpen: false,
            });
            document.getElementById("vehicleform").reset();
            swal({
              position: "center",
              icon: "success",
              title: "Vehicle Added",
              showConfirmButton: false,
              timer: 1500,
            });
        
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal("Oops...", "Error occured", "error");
        });
    }
  };


  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getCompanies();
    this.getVehicles();
  }

  render() {
    var vehicles = this.state.vehicles ? (
      this.state.vehicles.map((data, i) => {
        return (
          <tr>
            <td> {data.vname}</td>
            <td> {data.make}</td>
            <td> {data.model}</td>
            <td> {data.color}</td>
            <td> {data.myear}</td>
            <td> {data.ryear}</td>
            <td> {data.plate}</td>
            <td> {data.fuel}</td>
            <td> {data.name}</td>
            <td> {data.price}</td>
        
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );
    var companies = this.state.companies ? (
      this.state.companies.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.name}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="pd-20 card p-4 mb-4">
          <div class="clearfix">
            <div class="pull-left d-flex w-100">
              <h1 class="text-center text-blue h2">Vehicles</h1>
            </div>
            <div class="row">
              <div class="col">
                <button
                  type="button"
                  class="ml-auto btn btn-lg btn-primary btn-block"
                  onClick={this.openModal}
                >
                  Add a Vehicle
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="text-center">Vehicle name</th>
                  <th class="text-center">Make</th>
                  <th class="text-center">Model</th>
                  <th class="text-center">Color</th>
                  <th class="text-center">Manufacture Year</th>
                  <th class="text-center">Registration Year</th>
                  <th class="text-center">Plate</th>
                  <th class="text-center">Fuel Type</th>
                  <th class="text-center">Company</th>
                  <th class="text-center">Purchase Price</th>
              
                </tr>
              </thead>
              <tbody>{vehicles ? vehicles : <tr value="">No Data</tr>}</tbody>
            </table>
          </div>
        </div>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add a Vehicle</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => this.addVehicle(e)} id="vehicleform">
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Vehicle Name:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Vehicle Name"
                    name="vname"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Make:</label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Make"
                    name="make"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Model:</label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Model"
                    name="model"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Color:</label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Color"
                    name="color"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Manufacture Year:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Manufacture Year"
                    name="myear"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Registered Year:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="year"
                    placeholder="Registered Year"
                    name="ryear"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Licence Plate:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Licence Plate"
                    name="plate"
                  />
                </div>
              </div>              
              
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Fuel Type:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Fuel Type"
                    name="fuel"
                  />
                </div>
              </div>       
              
               <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Price:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Price"
                    name="price"
                  />
                </div>
              </div>    
            
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Company:</label>
                <div class="col-sm-12 col-md-10">
                  <select name="companyid" class="form-control" required>
                    <option value="">Select</option>
                    {companies ? companies : <option value="">No Data</option>}
                  </select>
                </div>
              </div>

              <div class="text-center">
                <button class="btn btn-info">Save</button>{" "}
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </LoadingScreen>
    );
  }
}

export default AllVehicles;
