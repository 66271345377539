import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class UpdateEmployee extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      empData: null,
      status: null,
      show: { value: null, tf: false },
      loading: true,
    };
  }

  getEmployee = () => {
    this.setState({
      show: { value: null, tf: false },
    });

    if (this.state.token) {
      if (this.props.match.params.emp.substring(3)) {
        var config = {
          method: "get",
          url:  process.env.REACT_APP_BASE_URL + `/employee/${this.props.match.params.emp.substring(
            3
          )}`,
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        };

        axios(config)
          .then((response) => {
            if (response.data.success) {
              if (response.data.employee) {
                this.setState({
                  empData: response.data.employee,
                  loading: false,
                });
              } else {
                this.setState({
                  status: "empty",
                  show: { value: "No Employee Data!", tf: true },
                  loading: false,
                });
              }
            } else {
              this.setState({
                empData: "not-found",
                loading: false,
                status: "load-failed",
                show: { value: "Faild to Load Employee Details!", tf: true },
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              empData: "not-found",
              loading: false,
              status: "load-failed",
              show: { value: "Faild to Load Employee Details!", tf: true },
            });
          });
      } else {
        this.setState({
          empData: "not-found",
          loading: false,
          status: "load-failed",
          show: { value: "Faild to Load Employee Details!", tf: true },
        });
      }
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  updateEmployee = (e) => {
    e.preventDefault();
    if (this.state.token) {
      if (
        e.target.bank.value &&
        e.target.bankacc.value &&
        e.target.status.value &&
        e.target.nic.value 
      ) {
        var data = {
          bank: e.target.bank.value,
          bankacc: e.target.bankacc.value,
          status: e.target.status.value,
          nic: e.target.nic.value,
          phone1: e.target.phoneNumber1.value,
          phone2: e.target.phoneNumber2.value,
          name: e.target.name.value,
          address: e.target.address.value,
          position: e.target.position.value,
          salarytype: e.target.salType.value,
          jd: e.target.jobDesc.value,
          basicsalary: e.target.basicSal.value,
          otrate: e.target.oTRate.value,
          companyname: e.target.companyName.value,
          customid: e.target.employeeId.value,
          empid: e.target.empId.value,
        };
        swal({
          title: "Do you want to update?",
          text: "Are you sure that you want to update?",
          icon: "info",
          buttons: true,
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              loading: true,
              show: { value: null, tf: false },
            });

            var config = {
              method: "patch",
              url: "https://savoga.ideacurl.com/api/v1/employee",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.token}`,
              },
              data: JSON.stringify(data),
            };

            axios(config)
              .then((response) => {
                if (response.data.success) {
                  this.setState({
                    loading: false,
                    status: "success",
                    show: { value: "Successfully Updated Employee!", tf: true },
                  });
                } else {
                  this.setState({
                    loading: false,
                    status: "failed",
                    show: { value: "Failed to Update Employee!", tf: true },
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  loading: false,
                  status: "failed",
                  show: { value: "Failed to Update Employee!", tf: true },
                });
              });
          }
        });
      } else {
        this.setState({
          loading: false,
          status: "fill-failed",
          show: { value: "Fill Required Fields!", tf: true },
        });
      }
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (this.state.status === "load-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "success") {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount = () => {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getEmployee();
  };

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        {this.state.empData !== null ? (
          <div className="grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">Update an Employee</h4>
                <p className="card-description">Employee Details</p>
                <form
                  className="forms-sample"
                  onSubmit={(e) => this.updateEmployee(e)}
                >
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="empId">Employee ID</label>
                        <input
                          type="number"
                          className="form-control"
                          id="empId"
                          defaultValue={this.state.empData.id}
                          disabled
                          placeholder="Employee ID"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Custom Employee ID</label>
                        <input
                          type="text"
                          className="form-control"
                          id="employeeId"
                          required
                          defaultValue={this.state.empData.customid}
                          placeholder="Custom Employee ID"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          defaultValue={this.state.empData.name}
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          defaultValue={this.state.empData.address}
                          placeholder="Address"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="phoneNumber1">Phone Number 1</label>
                        <input
                          type="number"
                          className="form-control"
                          id="phoneNumber1"
                          defaultValue={this.state.empData.phone1}
                          placeholder="Phone Number 1"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="phoneNumber2">Phone Number 2</label>
                        <input
                          type="number"
                          className="form-control"
                          id="phoneNumber2"
                          defaultValue={null}
                          defaultValue={
                            this.state.empData.phone2
                              ? this.state.empData.phone2
                              : null
                          }
                          placeholder="Phone Number 2"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="nic">NIC</label>
                        <input
                          type="text"
                          className="form-control"
                          id="nic"
                          defaultValue={this.state.empData.nic}
                          placeholder="NIC"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="companyName">Company Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="companyName"
                          defaultValue={this.state.empData.companyname}
                          placeholder="Company Name"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="position">Position</label>
                        <input
                          type="text"
                          className="form-control"
                          id="position"
                          defaultValue={this.state.empData.position}
                          placeholder="Position"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="jobDesc">Job Description</label>
                        <input
                          type="text"
                          className="form-control"
                          id="jobDesc"
                          defaultValue={this.state.empData.jd}
                          placeholder="Job Description"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="salType">Salary Type</label>
                        <select
                          className="form-control"
                          id="salType"
                          defaultValue={this.state.empData.salarytype}
                        >
                          <option value="">Select</option>
                          <option value="Daily">Daily</option>
                          <option value="Monthly">Monthly</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="basicSal">Basic Salary</label>
                        <input
                          type="number"
                          className="form-control"
                          id="basicSal"
                          defaultValue={this.state.empData.basicsalary}
                          placeholder="Basic Salary"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="oTRate">OT Rate</label>
                        <input
                          type="number"
                          className="form-control"
                          step="0.01"
                          id="oTRate"
                          defaultValue={this.state.empData.otrate}
                          placeholder="OT Rate"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="bank">Bank</label>
                        <select
                          className="form-control"
                          id="bank"
                          defaultValue={this.state.empData.bank}
                        >
                          <option value="">Select</option>
                          <option value="Amana Bank">Amana Bank</option>
                          <option value="Bank of Ceylon">Bank of Ceylon</option>
                          <option value="Bank of China Limited">
                            Bank of China Limited
                          </option>
                          <option value="Cargills Bank Ltd">
                            Cargills Bank Ltd
                          </option>
                          <option value="Citibank N.A.">Citibank N.A.</option>
                          <option value="Commercial Bank of Ceylon PLC">
                            Commercial Bank of Ceylon PLC
                          </option>
                          <option value="Deutsche Bank AG">
                            Deutsche Bank AG
                          </option>
                          <option value="DFCC Bank PLC">DFCC Bank PLC</option>
                          <option value="Habib Bank Ltd">Habib Bank Ltd</option>
                          <option value="Hatton National Bank PLC">
                            Hatton National Bank PLC
                          </option>
                          <option value="ICICI Bank Ltd">ICICI Bank Ltd</option>
                          <option value="Indian Bank">Indian Bank</option>
                          <option value="Indian Overseas Bank">
                            Indian Overseas Bank
                          </option>
                          <option value="MCB Bank Ltd">MCB Bank Ltd</option>
                          <option value="National Development Bank PLC">
                            National Development Bank PLC
                          </option>
                          <option value="Nations Trust Bank PLC">
                            Nations Trust Bank PLC
                          </option>
                          <option value="Pan Asia Banking Corporation PLC">
                            Pan Asia Banking Corporation PLC
                          </option>
                          <option value="People's Bank">People's Bank</option>
                          <option value="Public Bank Berhad">
                            Public Bank Berhad
                          </option>
                          <option value="Sampath Bank PLC">
                            Sampath Bank PLC
                          </option>
                          <option value="Seylan Bank PLC">
                            Seylan Bank PLC
                          </option>
                          <option value="Standard Chartered Bank">
                            Standard Chartered Bank
                          </option>
                          <option value="State Bank of India">
                            State Bank of India
                          </option>
                          <option value="The Hong Kong and Shanghai Banking Corporation Ltd (HSBC)">
                            The Hong Kong and Shanghai Banking Corporation Ltd
                            (HSBC)
                          </option>
                          <option value="Union Bank of Colombo PLC">
                            Union Bank of Colombo PLC
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="bankacc">Account Number</label>
                        <input
                          type="number"
                          className="form-control"
                          id="bankacc"
                          defaultValue={this.state.empData.bankacc}
                          placeholder="Account Number"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="status">Status</label>
                    <select
                      className="form-control"
                      id="status"
                      defaultValue={this.state.empData.status}
                    >
                      <option value="">Select</option>
                      <option value="1">Active</option>
                      <option value="0">Not Active</option>
                    </select>
                  </div>
                  <button
                    id="submit-form"
                    type="submit"
                    className="btn btn-success mr-2"
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-light"
                    onClick={() => this.getEmployee()}
                  >
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        ) : null}
      </LoadingScreen>
    );
  }
}

export default UpdateEmployee;
