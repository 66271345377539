import React, { Component } from "react";
import axios from "axios";
import qs from "querystring";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      phone: null,
      password: null,
      status: null,
      show: {
        value: null,
        tf: false,
      },
      loading: false,
    };
  }

  login = (e, phone, pw) => {
    if (!phone || !pw) {
      this.setState({
        status: "empty-failed failed",
        show: {
          value: "Username and Password must be filled!",
          tf: true,
        },
      });
    } else {
      this.setState({ show: { value: null, tf: false }, loading: true });
      e.preventDefault();
      var data = {
        phone: phone,
        password: pw,
      };
      axios
        .post(process.env.REACT_APP_BASE_URL + "/login", qs.stringify(data))
        .then((response) => {
          if (response.data.success) {
            this.setState({
              status: "success",
              show: {
                value: null,
                tf: false,
              },
            });
            sessionStorage.setItem("loginStatus", JSON.stringify(true));
            sessionStorage.setItem(
              "userToken",
              JSON.stringify(response.data.token.token)
            );
            window.location.href = "/home";
          } else {
            this.setState({
              status: "failed",
              show: {
                value: "Username or Password is Incorrect!",
                tf: true,
              },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "failed",
            show: {
              value: "Username or Password is Incorrect!",
              tf: true,
            },
            loading: false,
          });
        });
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (
        this.state.status === "load-failed" ||
        this.state.status === "type-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "failed" ||
        this.state.status === "del-failed" ||
        this.state.status === "app-failed" ||
        this.state.status === "empty-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
            this.setState({ show: { value: null, tf: false } });
          }
        });
      } else if (
        this.state.status === "success" ||
        this.state.status === "app-success" ||
        this.state.status === "del-success"
      ) {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            document.getElementById("form").reset();
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
              <div className="row w-100">
                <div className="row text-center">
                  <div className="col-lg-4 offset-lg-4">
                    <img
                      className="img-fluid"
                      src="/assets/images/logo.png"
                      alt="logo"
                    />
                  </div>
                </div>
                <div className="col-lg-4 mx-auto mt-5">
                  <div className="auto-form-wrapper">
                    <form id="form">
                      <div className="form-group">
                        <label className="label">Username</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone"
                            onChange={(e) => {
                              this.setState({
                                phone: e.target.value,
                              });
                            }}
                            required
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Password</label>
                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="*********"
                            onChange={(e) => {
                              this.setState({
                                password: e.target.value,
                              });
                            }}
                            required
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          id="submit-login-form"
                          className="btn btn-primary submit-btn btn-block"
                          onClick={(e) =>
                            this.login(e, this.state.phone, this.state.password)
                          }
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default Login;
