import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ProtectedRoute } from "./protectedRoute";

import Login from "./Components/user/login";
import EmployeeRegistration from "./Components/employee/employeeRegistration";
import UpdateEmployee from "./Components/employee/updateEmployee";
import AllEmployees from "./Components/employee/allEmployees";
import SingleEmployee from "./Components/employee/singleEmployee";
import SubmitLeave from "./Components/leaves/submitLeave";
import AllLEaves from "./Components/leaves/allLeaves";
import AddCategory from "./Components/categories/addCategory";
import AllCategories from "./Components/categories/allCategories";
import AddSubCategory from "./Components/subCategories/addSubCategory";
import AllSubCategories from "./Components/subCategories/allSubCategories";
import AddAttendance from "./Components/attendance/addAttendance";
import AllAttendance from "./Components/attendance/allAttendance";
import AddProduct from "./Components/products/addProduct";
import AllProducts from "./Components/products/allProducts";
import AllCustomers from "./Components/customers/allCustomers";
import Invoice from "./Components/invoice/invoice";
import CreateInvoice from "./Components/invoice/createInvoice";

//incentive
import AddIncentive from "./Components/incentives/addincentive";
import AllIncentives from "./Components/incentives/allincentives";

//allowance
import AddAllowance from "./Components/allowance/addallowance";
import AllAllowances from "./Components/allowance/allallowances";

import ChangePassword from "./Components/user/changePassword";
import AttendanceSummery from "./Components/attendance/attendanceSummery";
import CreateSalarySlip from "./Components/salary/createSalarySlip";
import ViewSalarySlip from "./Components/salary/viewSalarySlip";
import SendSMS from "./Components/sms/sendSms";
import ViewSingleSalarySlip from "./Components/salary/salarySlip";
import UpdateSalarySlip from "./Components/salary/updateSalarySlip";
import Home from "./Components/home";
import NavBar from "./Components/partials/navBar";
import SideBar from "./Components/partials/sideBar";
import PrintPreview from "./Components/salary/printPrview";
import ViewInvoices from "./Components/invoice/viewInvoices";

//Salary
import SalaryAdvance from "./Components/salary/salaryadvance";
import SalaryTable from "./Components/salary/salarytable";

//SalaryAdvance
import Loans from "./Components/salary/loans";

//pettycash
import Pettycashtypes from "./Components/pettycash/pettycashtypes";
import Pettycash from "./Components/pettycash/pettycash";
import PettycashReport from "./Components/pettycash/report";

//vehicles
import Vehicles from "./Components/vehicles/allVehicles";
import Renewables from "./Components/vehicles/renewables";

// import TestPage from "./Components/allowance/test";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
    };
  }

  // componentWillUnmount() {
  //   window.onbeforeunload = function() {
  //     cookies.remove("userToken");
  //     cookies.remove("userID");
  //   };
  // }

  render() {
    return (
      <Router>
        <div>
          <NavBar />
          <Switch>
            <Route path="/" exact strict component={Login} />
            <ProtectedRoute
              path="/user/changepassword"
              exact
              strict
              component={ChangePassword}
            />
            <div className="row">
              <div className="col-md-2">
                <SideBar />
              </div>
              <div
                className={
                  window.location.pathname === "/home"
                    ? "full-width-main-content-wrapper"
                    : "main-content-wrapper"
                }
              >
                <ProtectedRoute path="/home" exact strict component={Home} />
                <ProtectedRoute
                  path="/emp/addemp"
                  exact
                  strict
                  component={EmployeeRegistration}
                />
                <ProtectedRoute
                  path="/emp/updtemp:emp"
                  exact
                  strict
                  component={UpdateEmployee}
                />     
                
                <ProtectedRoute
                  path="/emp/addallowance"
                  exact
                  strict
                  component={AddAllowance}
                />


                <ProtectedRoute
                  path="/emp/allemp"
                  exact
                  strict
                  component={AllEmployees}
                />
                <ProtectedRoute
                  path="/emp/singleemp:emp"
                  exact
                  strict
                  component={SingleEmployee}
                />
                <ProtectedRoute
                  path="/emp/submitleave"
                  exact
                  strict
                  component={SubmitLeave}
                />
                <ProtectedRoute
                  path="/emp/allleaves"
                  exact
                  strict
                  component={AllLEaves}
                />
                <ProtectedRoute
                  path="/product/addcategory"
                  exact
                  strict
                  component={AddCategory}
                />
                <ProtectedRoute
                  path="/product/allcategories"
                  exact
                  strict
                  component={AllCategories}
                />
                <ProtectedRoute
                  path="/product/addsubcategory"
                  exact
                  strict
                  component={AddSubCategory}
                />
                <ProtectedRoute
                  path="/product/allsubcategories"
                  exact
                  strict
                  component={AllSubCategories}
                />
                <ProtectedRoute
                  path="/emp/addattendance"
                  exact
                  strict
                  component={AddAttendance}
                />
                <ProtectedRoute
                  path="/emp/allattendance"
                  exact
                  strict
                  component={AllAttendance}
                />
                <ProtectedRoute
                  path="/product/addproduct"
                  exact
                  strict
                  component={AddProduct}
                />
                <ProtectedRoute
                  path="/product/allproducts"
                  exact
                  strict
                  component={AllProducts}
                />
      
                <ProtectedRoute
                  path="/customer/allcustomers"
                  exact
                  strict
                  component={AllCustomers}
                />
                <ProtectedRoute
                  path="/invoice"
                  exact
                  strict
                  component={Invoice}
                />
                <ProtectedRoute
                  path="/createinvoice"
                  exact
                  strict
                  component={CreateInvoice}
                />
                <ProtectedRoute
                  path="/emp/addincentive"
                  exact
                  strict
                  component={AddIncentive}
                />
                <ProtectedRoute
                  path="/emp/allincentives"
                  exact
                  strict
                  component={AllIncentives}
                />         
                <ProtectedRoute
                  path="/emp/allallowances"
                  exact
                  strict
                  component={AllAllowances}
                />
      
                <ProtectedRoute
                  path="/emp/attendancesummery"
                  exact
                  strict
                  component={AttendanceSummery}
                />

                <ProtectedRoute
                  path="/emp/salaryadvance"
                  exact
                  strict
                  component={SalaryAdvance}
                />               
                 <ProtectedRoute
                  path="/emp/salarytable"
                  exact
                  strict
                  component={SalaryTable}
                />
                <ProtectedRoute
                  path="/emp/loans"
                  exact
                  strict
                  component={Loans}
                />

                <ProtectedRoute
                  path="/emp/createsalaryslip"
                  exact
                  strict
                  component={CreateSalarySlip}
                />
                <ProtectedRoute
                  path="/emp/viewslaryslips"
                  exact
                  strict
                  component={ViewSalarySlip}
                />

                <ProtectedRoute path="/sms" exact strict component={SendSMS} />
                <ProtectedRoute
                  path="/emp/viewsingleslaryslip/:id"
                  exact
                  strict
                  component={ViewSingleSalarySlip}
                />
                <ProtectedRoute
                  path="/emp/updatesalaryslip/:id"
                  exact
                  strict
                  component={UpdateSalarySlip}
                />
                <ProtectedRoute
                  path="/emp/slips/preview/:id"
                  exact
                  strict
                  component={PrintPreview}
                />
                <ProtectedRoute
                  path="/allinvoices"
                  exact
                  strict
                  component={ViewInvoices}
                />

                {/* pettycash */}
                <ProtectedRoute
                  path="/pettycash/pettycashtypes"
                  exact
                  strict
                  component={Pettycashtypes}
                />

                <ProtectedRoute
                  path="/pettycash/all"
                  exact
                  strict
                  component={Pettycash}

                />   
                
                <ProtectedRoute
                  path="/pettycash/report"
                  exact
                  strict
                  component={PettycashReport}
                />
                
                <ProtectedRoute
                  path="/vehicles/all"
                  exact
                  strict
                  component={Vehicles}
                />   
                 <ProtectedRoute
                  path="/vehicles/renewables"
                  exact
                  strict
                  component={Renewables}
                />
              </div>
            </div>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Routes;
