import React, { Component } from "react";
import axios from "axios";
import FormData from "form-data";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";
import DatePicker from "react-datepicker";

import loader from "../../Resources/Images/loader/spinner-1.gif";

import "react-datepicker/dist/react-datepicker.css";

class SendSMS extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      status: null,
      show: { value: null, tf: false },
      loading: false,
    };
  }

  sendSMS = (e) => {
    e.preventDefault();
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      if (e.target.phone.value && e.target.message.value) {
        var phoneNUmber = Number(e.target.phone.value);

        var data = new FormData();
        data.append(
          "phone",
          parseInt(phoneNUmber.toString().slice(0, 2)) !== 94
            ? parseInt(`94${phoneNUmber}`)
            : parseInt(phoneNUmber.toString().slice(2, 3)) === 0
            ? parseInt(`94${phoneNUmber.toString().slice(3)}`)
            : phoneNUmber
        );
        data.append("msg", e.target.message.value);

        var config = {
          method: "post",
          url:  process.env.REACT_APP_BASE_URL + "/sms/send",
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
          data: data,
        };

        axios(config)
          .then((response) => {
            if (response.data.success) {
              this.setState({
                status: "success",
                show: { value: "Message Successfully Sent!", tf: true },
                loading: false,
              });
            } else {
              this.setState({
                status: "failed",
                show: { value: "Failed to Send Message!", tf: true },
                loading: false,
              });
            }
          })
          .catch((error) => {
            this.setState({
              status: "failed",
              show: { value: "Failed to Send Message!", tf: true },
              loading: false,
            });
          });
      } else {
        this.setState({
          status: "failed",
          show: { value: "Fill Required Fields!", tf: true },
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (
        this.state.status === "load-failed" ||
        this.state.status === "type-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "failed" ||
        this.state.status === "del-failed" ||
        this.state.status === "app-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "success" ||
        this.state.status === "app-success" ||
        this.state.status === "del-success"
      ) {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            document.getElementById("form").reset();
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount = () => {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
  };

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Send SMS</h4>
              <p className="card-description">SMS Details</p>
              <form
                className="forms-sample"
                onSubmit={(e) => this.sendSMS(e)}
                id="form"
              >
                <div className="form-group">
                  <label htmlFor="phone">Phone number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="phone"
                    placeholder="Ex: 94771235678 | 0771234567"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <input
                    type="text"
                    className="form-control"
                    id="message"
                    placeholder="Message"
                    required
                  />
                </div>
                <button type="submit" className="btn btn-success mr-2">
                  Send
                </button>
                <button type="reset" className="btn btn-light">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default SendSMS;
