import React, { Component, Fragment } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import loader from "../../Resources/Images/loader/spinner-1.gif";

class SalaryAdvance extends Component {
  state = {
    token: null,
    loading: false,
    salaryadvances: null,
    isOpen: false,
  };

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  getSalaryAdvances = async () => {
    this.setState({
      loading: false,
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/salaryadvance/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.salaryadvances[0]) {
              this.setState({
                loading: false,
                salaryadvances: res.data.salaryadvances,
              });
            } else {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "No salary advances found!",
              });
            }
          } else {
            this.setState({
              loading: false,
            });

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Failed to Load salary advances!",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  addSalaryAdvance = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      var data = new FormData();

      data.append("empid", e.target.empid.value);
      data.append("amount", e.target.amount.value);
      data.append("date", e.target.date.value);


      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/salaryadvance",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              loading: false,
              isOpenSupplier: false,
            });
            document.getElementById("salaryadvanceform").reset();
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Salary Advance Added",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getSalaryAdvances();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          Swal.fire("Oops...", "Error occured", "error");
        });
    }
  };

  deleteSalaryAdvance = (id) => {
    Swal.fire({
      title: "Do you want to delete this salary advance?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          loading: true,
        });
        if (this.state.token) {
          var config = {
            method: "delete",
            url: process.env.REACT_APP_BASE_URL + "/salaryadvance/delete/" + id,
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          };

          axios(config)
            .then((response) => {
              if (response.data.success) {
                this.setState({
                  loading: false,
                });
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Salary Advance Deleted",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.getSalaryAdvances();
              }
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Error!",
              });
            });
        }
      }
    });
  };

  getEmpIds = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/employee/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.employees[0]) {
              this.setState({
                empData: response.data.employees,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Employee Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
              status: "type-failed",
              show: { value: "Failed Loading Employees!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "type-failed",
            show: { value: "Failed Loading Employees!", tf: true },
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  componentDidMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getSalaryAdvances();
    this.getEmpIds();
  }

  render() {
    var ids = this.state.salaryadvances ? (
      this.state.salaryadvances.map((data, i) => {
        return (
          <tr>
            <td> {data.name}</td>
            <td> {data.date}</td>

            <td> {data.amount}</td>

            <td>
              <button
                onClick={() => this.deleteSalaryAdvance(data.id)}
                class="btn btn-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );
    var empids = this.state.empData ? (
      this.state.empData.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.name}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="pd-20 card p-4 mb-4">
          <div class="clearfix">
            <div class="pull-left d-flex w-100">
              <h1 class="text-center text-blue h2">Salary Advances</h1>
              <button
                type="button"
                class="ml-auto btn btn-warning"
                onClick={this.openModal}
              >
                Add a Salary Advance
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <table class="table">
            <thead>
              <tr>
                <th>Employee</th>
                <th>Date</th>
                <th>Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{ids ? ids : <tr value="">No Data</tr>}</tbody>
          </table>
        </div>

        <Modal size="lg" show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Salary Advance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form
              onSubmit={(e) => this.addSalaryAdvance(e)}
              id="salaryadvanceform"
            >
              <div className="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Employee
                </label>
                <div class="col-sm-12 col-md-10">
                  <select className="form-control" id="empid" name="empid" required>
                    <option value="">Select</option>
                    {empids ? empids : <option value="">No Data</option>}
                  </select>
                </div>
              </div>
    
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Date:</label>
                <div class="col-sm-12 col-md-10">
                <input
                    class="form-control"
                    type="date" placeholder="Month" 
                    name="date"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Amount:</label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="number"
                    placeholder="Amount"
                    name="amount"
                    required
                  />
                </div>
              </div>

              <div class="text-center">
                <button class="btn btn-info">Save</button>{" "}
                <button onClick={this.closeModal} class="btn btn-danger">
                  Close
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </LoadingScreen>
    );
  }
}

export default SalaryAdvance;
