import React, { Component } from "react";
import axios from "axios";
import qs from "querystring";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      password: null,
      newPassword: null,
      confPassword: null,
      status: null,
      show: {
        value: null,
        tf: false,
      },
      loading: false,
    };
  }

  changePassword = (e, oldPw, newPw, confPw) => {
    e.preventDefault();
    if (!oldPw || !newPw || !confPw) {
      this.setState({
        status: "empty-failed failed",
        show: {
          value: "Fill all the fields!",
          tf: true,
        },
      });
    } else if (newPw !== confPw) {
      this.setState({
        status: "failed",
        show: {
          value: "Passwords doesn't match!",
          tf: true,
        },
      });
    } else {
      this.setState({ show: { value: null, tf: false }, loading: true });
      var data = {
        old_password: oldPw,
        new_password: newPw,
        confirm_password: confPw,
      };

      var config = {
        method: "post",
        url:  process.env.REACT_APP_BASE_URL + "/changepassword",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          if (response.data.status===200) {
            this.setState({
              status: "success",
              show: {
                value: null,
                tf: false,
              },
            });
            window.location.href = "/home";
          } else {
            this.setState({
              status: "failed",
              show: {
                value: "Something went wrong!",
                tf: true,
              },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "failed",
            show: {
              value: "Something went wrong!",
              tf: true,
            },
            loading: false,
          });
        });
    }
  };

  componentWillMount = () => {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (
        this.state.status === "load-failed" ||
        this.state.status === "type-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "failed" ||
        this.state.status === "del-failed" ||
        this.state.status === "app-failed" ||
        this.state.status === "empty-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
            this.setState({ show: { value: null, tf: false } });
          }
        });
      } else if (
        this.state.status === "success" ||
        this.state.status === "app-success" ||
        this.state.status === "del-success"
      ) {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            document.getElementById("form").reset();
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
              <div className="row w-100">
                <div className="col-lg-4 mx-auto mt-5">
                  <div className="auto-form-wrapper">
                    <form
                      id="form"
                      onClick={(e) =>
                        this.changePassword(
                          e,
                          this.state.password,
                          this.state.newPassword,
                          this.state.confPassword
                        )
                      }
                    >
                      <div className="form-group">
                        <label className="label">Old Password</label>
                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="*********"
                            onChange={(e) => {
                              this.setState({
                                password: e.target.value,
                              });
                            }}
                            required
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">New Password</label>
                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="*********"
                            onChange={(e) => {
                              this.setState({
                                newPassword: e.target.value,
                              });
                            }}
                            required
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="label">Confirm Password</label>
                        <div className="input-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="*********"
                            onChange={(e) => {
                              this.setState({
                                confPassword: e.target.value,
                              });
                            }}
                            required
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="mdi mdi-check-circle-outline"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          id="submit-login-form"
                          className="btn btn-primary submit-btn btn-block"
                        >
                          Change Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default ChangePassword;
