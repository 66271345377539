import React, { Component } from "react";
import axios from "axios";
import qs from "querystring";
import { Modal, Button } from "react-bootstrap";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";
import loader from "../../Resources/Images/loader/spinner-1.gif";

class AllLeaves extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      leaves: null,
      loading: false,
      empData: null,
      viewLeave: false,
      singleLeaveData: {},
      type: [],
      selectyear: null,
      selectmonth: null,
      selectempid: null,
      full:null,
      half:null,
      short:null,
      nopay:null
    };
  }

  getTypes = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      const config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/leave/types",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.leavetypes[0]) {
              this.setState({
                type: response.data.leavetypes,
                status: "type-success",
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Leave Type Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "type-failed",
              show: { value: "Failed Loading Leave Types!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "type-failed",
            show: { value: "Failed Loading Leave Types!", tf: true },
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getEmpIds = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/employee/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.employees[0]) {
              this.setState({
                empData: response.data.employees,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Employee Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  searchleaves = () => {
    this.setState({
      leaves: null,
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url:
          process.env.REACT_APP_BASE_URL +
          "/leave/search/" +
          this.state.selectyear +
          "&" +
          this.state.selectmonth +
          "&" +
          this.state.selectempid,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.leaves[0]) {
            this.setState({
              leaves: response.data.leaves,
              full: response.data.full,
              half: response.data.half,
              short: response.data.short,
              nopay: response.data.nopay,
              loading: false,
            });
          } else {
            this.setState({
              status: "empty",
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
      });
    }
  };

  approveUnapproveLeaves = (id, status) => {
    swal({
      title: `Do you want to ${status === 0 ? "approve" : "unapprove"}?`,
      text: `Are you sure that you want to ${
        status === 0 ? "approve" : "unapprove"
      }?`,
      icon: "info",
      buttons: true,
      dangerMode: false,
    }).then((yes) => {
      if (yes) {
        this.setState({
          show: { value: null, tf: false },
          loading: true,
        });

        if (this.state.token) {
          if (id && (status === 0 || status === 1)) {
            var data = {
              id: id,
              type: status === 0 ? 1 : 0,
            };

            var config = {
              method: "patch",
              url: process.env.REACT_APP_BASE_URL + "/leave/approve",
              headers: { Authorization: `Bearer ${this.state.token}` },
              data: qs.stringify(data),
            };

            axios(config)
              .then((response) => {
                if (response.data.success) {
                  this.setState({
                    status: "app-success",
                    loading: false,
                    show: {
                      value: `${
                        status === 0 ? "Approved" : "Unapproved"
                      } Successfully!`,
                      tf: true,
                    },
                  });
                  this.allLeaves();
                } else {
                  this.setState({
                    status: "app-failed",
                    loading: false,
                    show: {
                      value: `${
                        status === 0 ? "Approving" : "Unapproving"
                      } Failed!`,
                      tf: true,
                    },
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  status: "app-failed",
                  loading: false,
                  show: {
                    value: `${
                      status === 0 ? "Approving" : "Unapproving"
                    } Failed!`,
                    tf: true,
                  },
                });
                console.log(error);
              });
          } else {
            this.setState({
              status: "app-failed",
              loading: false,
              show: {
                value: `${status === 0 ? "Approving" : "Unapproving"} Failed!`,
                tf: true,
              },
            });
          }
        } else {
          this.setState({
            loading: false,
            status: "auth-failed",
            show: { value: "Authentication Error!", tf: true },
          });
        }
      }
    });
  };

  updateLeave = (e, empid, leaveId) => {
    e.preventDefault();

    if (this.state.token) {
      if (
        e.target.status.value &&
        e.target.leaveDate.value &&
        e.target.reason.value &&
        e.target.leaveType.value &&
        empid &&
        leaveId
      ) {
        var data = {
          empid: empid,
          status: e.target.status.value,
          leavedate: e.target.leaveDate.value,
          reason: e.target.reason.value,
          leavetype: e.target.leaveType.value,
          id: leaveId,
        };
        swal({
          title: "Do you want to update?",
          text: "Are you sure that you want to update?",
          icon: "info",
          buttons: true,
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              loading: true,
              show: { value: null, tf: false },
            });

            var config = {
              method: "patch",
              url: process.env.REACT_APP_BASE_URL + "/leave",
              headers: { Authorization: `Bearer ${this.state.token}` },
              data: qs.stringify(data),
            };

            axios(config)
              .then((response) => {
                if (response.data.success) {
                  this.setState({
                    status: "success",
                    show: { value: "Successfully Updated Leave!", tf: true },
                    loading: false,
                  });
                } else {
                  this.setState({
                    status: "failed",
                    show: { value: "Failed to Update Leave!", tf: true },
                    loading: false,
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  status: "failed",
                });
              });
          }
        });
      } else {
        this.setState({
          status: "failed",
          show: { value: "Fill Required Fields!", tf: true },
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  deleteLeaves = (e) => {
    swal({
      title: "Do you want to delete?",
      text: "Are you sure that you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        if (this.state.token) {
          if (e) {
            this.setState({
              show: { value: null, tf: false },
              loading: true,
            });

            var config = {
              method: "delete",
              url: process.env.REACT_APP_BASE_URL + `/leave/${e}`,
              headers: {
                Authorization: `Bearer ${this.state.token}`,
              },
            };

            axios(config)
              .then((response) => {
                if (response.data.success) {
                  this.setState({
                    status: "del-success",
                    loading: false,
                    show: { value: "Deleted Suucessfully!", tf: true },
                  });
                  this.allLeaves();
                } else {
                  this.setState({
                    status: "del-failed",
                    loading: false,
                    show: { value: "Deleting Failed!", tf: true },
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  status: "del-failed",
                  loading: false,
                  show: { value: "Deleting Failed!", tf: true },
                });
                console.log(error);
              });
          } else {
            this.setState({
              status: "del-failed",
              loading: false,
              show: { value: "Deleting Failed!", tf: true },
            });
          }
        } else {
          this.setState({
            loading: false,
            status: "auth-failed",
            show: { value: "Authentication Error!", tf: true },
          });
        }
      }
    });
  };

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getEmpIds();
    this.getTypes();
  }

  render() {
    var emps = this.state.empData ? (
      this.state.empData.map((data, i) => {
        return (
          <option value={data.id ? data.id : ""} key={i}>
            {data.name ? data.name : "No Data"}
          </option>
        );
      })
    ) : (
      <option value="">No Data</option>
    );

    var leaves = this.state.leaves ? (
      this.state.leaves.map((data, i) => {
        return (
          <tr>
            <td> {data.leavename}</td>
            <td> {data.leavedate}</td>
            <td> {data.reason}</td>
            <td> "Pending"</td>
            <td> {data.created_at}</td>
            <td> {data.updated_at}</td>
            {/* <td> <button onClick={()=>this.deleteCustomer(data.id)} class="btn btn-danger">Delete</button></td> */}
          </tr>
        );
      })
    ) : (
      <tr>No Data</tr>
    );

    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">All Leaves</h4>
              <p className="card-description">Leave Details</p>
              <div class="row">
                
                <div class="col">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ selectyear: e.target.value });
                  }}
                >
                  <option value="">Select Year</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
   <option value="2024">2024</option>
                </select>
                </div>        
                
                <div class="col">
                <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ selectmonth: e.target.value });
                  }}
                >
                  <option value="">All Months</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
                </div>

                  <div class="col">
                  <select
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ selectempid: e.target.value });
                  }}
                >
                  <option>Select Employee</option>
                  {emps ? emps : <option value="">No Data</option>}
                </select>
                  </div>
                <div class="col">
                <input
                  type="submit"
                  onClick={this.searchleaves}
                  class="btn btn-warning"
                  value="Search"
                />
                </div>
              </div>
             
              <div className="overflow-auto w-100">
                <hr />
                <div class="row">
                  <div class="col">
                    <div class="alert alert-success" role="alert">
                    <b class="text-danger">Pay :</b> <b>Full Day leaves: {this.state.full} </b> | <b>Half Day leaves: {this.state.half} </b> | <b>Short Day leaves: {this.state.short} </b> 
                    </div>
                  </div>
                </div>       
                
                <div class="row">
                  <div class="col">
                    <div class="alert alert-primary" role="alert">
                    <b class="text-danger">No Pay :</b> <b>Full Day leaves: {this.state.nopay?.full} </b> | <b>Half Day leaves: {this.state.nopay?.half} </b> | <b>Short Day leaves: {this.state.nopay?.short} </b> 
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th class="text-center">Leave Type</th>
                          <th class="text-center">Leave Date</th>
                          <th class="text-center">Reason</th>
                          <th class="text-center">Status</th>
                          <th class="text-center">System Date: Added</th>
                          <th class="text-center">System Date: Updated</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leaves ? leaves : <tr value="">No Data</tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default AllLeaves;
