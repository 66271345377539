import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import qs from "querystring";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class AllSubCategories extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      subCatData: null,
      status: null,
      show: { value: null, tf: false },
      loading: false,
      showEditField: { value: null, tf: false },
      newName: null,
    };
  }

  getSubCategories = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: "https://savoga.ideacurl.com/api/v1/subcategory/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.SubCategories[0]) {
              this.setState({
                subCatData: res.data.SubCategories,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Sub Category Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load Sub Categories!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load Sub Categories!", tf: true },
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  updateSubCategory = (scid, cid) => {
    swal({
      title: "Do you want to update?",
      text: "Are you sure you want to update?",
      icon: "info",
      buttons: true,
      dangerMode: false,
    }).then((yes) => {
      if (yes) {
        this.setState({
          show: { value: null, tf: false },
          loading: true,
        });

        if (this.state.token) {
          if (scid && cid && this.state.newName) {
            var data = {
              id: scid,
              catid: cid,
              newcatname: this.state.newName,
            };

            var config = {
              method: "patch",
              url: "https://savoga.ideacurl.com/api/v1/subcategory",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${this.state.token}`,
              },
              data: qs.stringify(data),
            };

            axios(config)
              .then((res) => {
                if (res.data.success) {
                  this.setState({
                    status: "success",
                    show: { value: "Successfully Updated Category!", tf: true },
                    loading: false,
                    showEditField: {
                      value: null,
                      tf: !this.state.showEditField.tf,
                    },
                  });
                  this.getSubCategories();
                } else {
                  this.setState({
                    status: "failed",
                    loading: false,
                    showEditField: {
                      value: null,
                      tf: !this.state.showEditField.tf,
                    },
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  status: "failed",
                  show: { value: "Failed to Update Category!", tf: true },
                  loading: false,
                  showEditField: {
                    value: null,
                    tf: !this.state.showEditField.tf,
                  },
                });
              });
          } else {
            this.setState({
              status: "failed",
              show: { value: "Fill Required Fields!", tf: true },
              loading: false,
            });
          }
        } else {
          this.setState({
            loading: false,
            status: "auth-failed",
            show: { value: "Authentication Error!", tf: true },
          });
        }
      }
    });
  };

  deleteSubCategory = (e) => {
    swal({
      title: "Do you want to delete?",
      text: "Are you sure you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        this.setState({
          show: { value: null, tf: false },
          loading: true,
        });

        if (this.state.token) {
          var config = {
            method: "delete",
            url: `https://savoga.ideacurl.com/api/v1/subcategory/${e}`,
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          };

          axios(config)
            .then((res) => {
              if (res.data.success) {
                this.setState({
                  status: "success",
                  show: { value: "Successfully Deleted Category!", tf: true },
                  loading: false,
                });
                this.getSubCategories();
              } else {
                this.setState({
                  status: "failed",
                  loading: false,
                });
              }
            })
            .catch((error) => {
              this.setState({
                status: "failed",
                show: { value: "Failed to Delete Category!", tf: true },
                loading: false,
              });
              console.log(error);
            });
        } else {
          this.setState({
            loading: false,
            status: "auth-failed",
            show: { value: "Authentication Error!", tf: true },
          });
        }
      }
    });
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (
        this.state.status === "load-failed" ||
        this.state.status === "type-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "failed" ||
        this.state.status === "del-failed" ||
        this.state.status === "app-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "success" ||
        this.state.status === "app-success" ||
        this.state.status === "del-success"
      ) {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getSubCategories();
  }

  render() {
    var subCatRows =
      this.state.subCatData !== null &&
      this.state.subCatData.map((data, i) => {
        return (
          <div className="col-sm-12 col-md-3" key={i}>
            <div className="card mb-4">
              <div className="card-body">
                <h4 className="card-title">
                  {this.state.showEditField.tf &&
                  this.state.showEditField.value === data.id ? (
                    <div className="row">
                      <input
                        type="text"
                        className="form-control form-control-sm col-8"
                        onChange={(e) =>
                          this.setState({
                            newName: e.target.value,
                          })
                        }
                        defaultValue={data.subcatname}
                        id={data.id}
                      />
                      <button
                        className="btn cat-card-btn"
                        onClick={
                          (() => {
                            this.setState({
                              showEditField: {
                                value: data.id,
                                tf: !this.state.showEditField.tf,
                              },
                            });
                          },
                          () => {
                            this.updateSubCategory(data.id, data.catid);
                          })
                        }
                      >
                        <i className="fa fa-check"></i>
                      </button>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="cat-card-title">{data.subcatname}</div>
                      <button
                        className="btn cat-card-btn"
                        onClick={() => {
                          this.setState({
                            showEditField: {
                              value: data.id,
                              tf: !this.state.showEditField.tf,
                            },
                            newName: data.subcatname,
                          });
                        }}
                      >
                        <i className="fa fa-pencil-square-o"></i>
                      </button>
                    </div>
                  )}
                </h4>
                <p>
                  <small className="card-text">
                    Created:&nbsp;&nbsp;&nbsp;{data.created_at}
                  </small>
                </p>
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      onClick={() => {
                        this.deleteSubCategory(data.id);
                      }}
                      className="btn btn-outline-danger"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        {this.state.subCatData !== null && (
          <div className="grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">All Sub Categories</h4>
                <p className="card-description">Sub Category Details</p>
                <div className="row">{subCatRows}</div>
              </div>
            </div>
          </div>
        )}
      </LoadingScreen>
    );
  }
}

export default AllSubCategories;
