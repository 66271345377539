import React, { Component } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class Renewables extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      loading: true,
      companies: null,
      isOpen: false,
      renewables: null,
      vehicles: null,
    };
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  getCompanies = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/company/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.companies[0]) {
              this.setState({
                companies: response.data.companies,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Company Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
              status: "type-failed",
              show: { value: "Failed to load companies!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "type-failed",
            show: { value: "Failed Loading Employees!", tf: true },
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getRenewables = () => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/renewable/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.renewables[0]) {
              this.setState({
                renewables: res.data.renewables,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No renewables Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load renewables!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load renewables!", tf: true },
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };
  
  searchRenewables = (id) => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/renewable/search/" + id,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.renewables[0]) {
              this.setState({
                renewables: res.data.renewables,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No renewables Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load renewables!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load renewables!", tf: true },
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getVehicles = () => {
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/vehicle/select",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.vehicles[0]) {
              this.setState({
                vehicles: res.data.vehicles,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No vehicles Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load vehicles!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load renewables!", tf: true },
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };



  updateStatus = async (id) => {
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "patch",
        url: process.env.REACT_APP_BASE_URL + "/renewable/" + id,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              loading: false,
            });

            swal({
              position: "center",
              icon: "success",
              title: "Status Changed!",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getRenewables();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal("Oops...", "Error occured", "error");
        });
    }
  };

  addRenewable = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    if (this.state.token) {
      var data = new FormData();

      data.append("type", e.target.type.value);
      data.append("vid", e.target.vid.value);
      data.append("renewperiod", e.target.renewperiod.value);
      data.append("reference", e.target.reference.value);
      data.append("phone1", e.target.phone1.value);
      data.append("phone2", e.target.phone2.value);
      data.append("expiredate", e.target.expiredate.value);
      data.append("cid", e.target.cid.value);

      var config = {
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "/renewable",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        data: data,
      };

      await axios(config)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({
              loading: false,
              isOpen: false,
            });
            document.getElementById("renewableform").reset();
            swal({
              position: "center",
              icon: "success",
              title: "Renewable Added",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal("Oops...", "Error occured", "error");
        });
    }
  };

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getCompanies();
    this.getRenewables();
    this.getVehicles();
  }

  render() {
    var renewables = this.state.renewables ? (
      this.state.renewables.map((data, i) => {
        if (data.status == 0) {
          return (
            <tr>
              <td>
                <button
                  onClick={() => this.updateStatus(data.id)}
                  class="btn btn-block btn-info"
                >
                  Mark as Done
                </button>
              </td>
              <td> {data.vname}</td>
              <td> {data.plate}</td>
              <td> {data.name}</td>
              <td> {data.type}</td>
              <td> {data.renewperiod}</td>
              <td> {data.reference}</td>
              <td> {data.phone1}</td>
              <td> {data.phone2}</td>
              <td> {data.expiredate}</td>
            </tr>
          );
        } else {
          return (
            <tr>
              <td>
                {" "}
                <button href="#" class="btn btn-block btn-success" disabled>
                  Done
                </button>
              </td>

              <td> {data.vname}</td>
              <td> {data.plate}</td>
              <td> {data.name}</td>
              <td> {data.type}</td>
              <td> {data.renewperiod}</td>
              <td> {data.reference}</td>
              <td> {data.phone1}</td>
              <td> {data.phone2}</td>
              <td> {data.expiredate}</td>
            </tr>
          );
        }
      })
    ) : (
      <tr>No Data</tr>
    );
    var companies = this.state.companies ? (
      this.state.companies.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.name}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );
    var vehicles = this.state.vehicles ? (
      this.state.vehicles.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.vname}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div class="pd-20 card p-4 mb-4">
          <div class="clearfix">
            <div class="pull-left d-flex w-100">
              <h1 class="text-center text-blue h2">Renewables</h1>
            </div>
            <div class="row">
              <div class="col">
                <button
                  type="button"
                  class="ml-auto btn btn-lg btn-primary btn-block"
                  onClick={this.openModal}
                >
                  Add a Item
                </button>
              </div>
              <div class="col">
                <select
                  class="form-control"
                  onChange={(e) => this.searchRenewables(e.target.value)}
                  required
                >
                  <option value="">Select a Vehicle</option>
                  {vehicles ? vehicles : <option value="">No Data</option>}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="text-center">Status</th>
                  <th class="text-center">Vehicle name</th>
                  <th class="text-center">Plate</th>
                  <th class="text-center">Company</th>
                  <th class="text-center">Type</th>
                  <th class="text-center">Renewable</th>
                  <th class="text-center">Reference</th>
                  <th class="text-center">Phone 1</th>
                  <th class="text-center">Phone 2</th>
                  <th class="text-center">Expire Date</th>
                </tr>
              </thead>
              <tbody>
                {renewables ? renewables : <tr value="">No Data</tr>}
              </tbody>
            </table>
          </div>
        </div>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add a Renewable</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => this.addRenewable(e)} id="renewableform">
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">Type:</label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Vehicle Name"
                    name="type"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Vehicle:
                </label>
                <div class="col-sm-12 col-md-10">
                  <select name="vid" class="form-control" required>
                    <option value="">Select</option>
                    {vehicles ? vehicles : <option value="">No Data</option>}
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Renewal Period:
                </label>
                <div class="col-sm-12 col-md-10">
                  <select name="renewperiod" class="form-control" required>
                    <option value="">Select</option>
                    <option value="1">1 Year</option>
                    <option value="6">6 Months</option>
                    <option value="3">3 Months</option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Reference:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Reference"
                    name="reference"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Phone 1:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Phone 1"
                    name="phone1"
                    maxlength="10"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Phone 2:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="text"
                    placeholder=" Phone 2"
                    name="phone2"
                    maxlength="10"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Expire Date:
                </label>
                <div class="col-sm-12 col-md-10">
                  <input
                    class="form-control"
                    type="date"
                    placeholder="Expire Date"
                    name="expiredate"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12 col-md-2 col-form-label">
                  Company:
                </label>
                <div class="col-sm-12 col-md-10">
                  <select name="cid" class="form-control" required>
                    <option value="">Select</option>
                    {companies ? companies : <option value="">No Data</option>}
                  </select>
                </div>
              </div>

              <div class="text-center">
                <button class="btn btn-info">Add</button>{" "}
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </LoadingScreen>
    );
  }
}

export default Renewables;
