import React, { Component } from "react";
import axios from "axios";
import FormData from "form-data";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class AddCategory extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      status: null,
      show: { value: null, tf: false },
      loading: false,
    };
  }

  addCategory = (e) => {
    e.preventDefault();
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      if (e.target.catName.value) {
        var data = new FormData();
        data.append("catname", e.target.catName.value);

        var config = {
          method: "post",
          url: "https://savoga.ideacurl.com/api/v1/category/new",
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
          data: data,
        };

        axios(config)
          .then((response) => {
            if (response.data.success) {
              this.setState({
                status: "success",
                show: { value: "Category Successfully Added!", tf: true },
                loading: false,
              });
            } else {
              this.setState({
                status: "failed",
                show: { value: "Failed to Add Category!", tf: true },
                loading: false,
              });
            }
          })
          .catch((error) => {
            this.setState({
              status: "failed",
              show: { value: "Failed to Add Category!", tf: true },
              loading: false,
            });
          });
      } else {
        this.setState({
          status: "failed",
          show: { value: "Fill Required Fields!", tf: true },
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (this.state.status === "load-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "success") {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            document.getElementById("form").reset();
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount = () => {
    this.setState({
      token: JSON.parse(sessionStorage.getItem("userToken")),
    });
  };

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Category</h4>
              <p className="card-description">Category Details</p>
              <form
                className="forms-sample"
                onSubmit={(e) => this.addCategory(e)}
                id="form"
              >
                <div className="form-group">
                  <label htmlFor="catName">Category Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="catName"
                    required
                    placeholder="Category Name"
                  />
                </div>
                <button type="submit" className="btn btn-success mr-2">
                  Submit
                </button>
                <button type="reset" className="btn btn-light">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default AddCategory;
