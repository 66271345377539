import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      show: { value: null, tf: false },
      status: null,
      loading: false,
    };
  }

  logOut = () => {
    swal({
      title: "Do you want to logout?",
      text: "Are you sure that you want to logout?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((yes) => {
      if (yes) {
        sessionStorage.removeItem("userToken");
        sessionStorage.removeItem("loginStatus");

        this.setState({
          loading: true,
        });

        if (this.state.token) {
          var config = {
            method: "get",
            url: "https://savoga.ideacurl.com/api/v1/logout",
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
          };

          axios(config)
            .then((res) => {
              if (res.data.success) {
                window.location.href = "/";
                this.setState({
                  show: { value: null, tf: false },
                  status: null,
                });
              } else {
                this.setState({
                  show: { value: "Logging out failed!", tf: true },
                  status: "failed",
                  loading: false,
                });
              }
            })
            .catch((err) => {
              this.setState({
                show: { value: "Logging out failed!", tf: true },
                status: "failed",
                loading: false,
              });
            });
        } else {
          this.setState({
            loading: false,
            status: "auth-failed",
            show: { value: "Authentication Error!", tf: true },
          });
        }
      }
    });
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      }
    }
  }

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
  }

  render() {
    if (window.location.pathname === "/") {
      return null;
    } else {
      return (
        <LoadingScreen
          loading={this.state.loading}
          bgColor="#ffffffcc"
          logoSrc={loader}
        >
          <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <div className="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
              <a className="navbar-brand brand-logo-mini" href="/home">
                <img src="/assets/images/logo-mini.png" alt="logo" />
              </a>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-center">
              <ul className="navbar-nav">
                {window.location.pathname !== "/home" &&
                window.location.pathname !== "/user/changepassword" ? (
                  <button
                    className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                    type="button"
                    data-toggle="offcanvas"
                  >
                    <span className="mdi mdi-menu"></span>
                  </button>
                ) : null}

                <a className="navbar-brand brand-logo" href="/home">
                  <img src="/assets/images/logo.png" alt="logo" />
                </a>
              </ul>
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a
                    className="nav-link dropdown-toggle px-2 d-flex align-items-center"
                    id="user-dropdown"
                    href="#"
                  >
                    <span className="profile-text font-weight-medium">
                      User
                    </span>
                    <div className="dropdown-menu">
                      <a href="/user/changepassword" className="dropdown-item">
                        Change Pasword
                      </a>
                      <a
                        href="#"
                        className="dropdown-item"
                        onClick={() => {
                          this.logOut();
                        }}
                      >
                        Logout
                      </a>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </LoadingScreen>
      );
    }
  }
}

export default NavBar;
