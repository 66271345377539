import React, { Component } from "react";
import LoadingScreen from "react-loading-screen";

import loader from "../Resources/Images/loader/spinner-1.gif";
import empImg from "../Resources/Images/home/employee.png";
import custImg from "../Resources/Images/home/team.png";
import productImg from "../Resources/Images/home/shipping.png";
import smsImg from "../Resources/Images/home/sms.png";
import pettycashImg from "../Resources/Images/home/pettycash.png";
import vehicle from "../Resources/Images/home/car.png";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper">
            <div className="col-12">
              <div className="row">
                <div className="col-sm-12 col-md-4 mb-4">
                  <div
                    className="card home-card"
                    onClick={() => {
                      window.location.pathname = "/emp/allemp";
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col-md-12 col-lg-4">
                        <div className="card-img-container text-center">
                          <img src={empImg} alt="..." />
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-8">
                        <div className="card-body">
                          <h3 className="card-title">Employee Management</h3>
                          <h6 className="card-subtitle mb-2 text-muted">
                            Add | Edit | Deactivate | View Employees
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 mb-4">
                  <div
                    className="card home-card"
                    onClick={() => {
                      window.location.pathname = "customer/allcustomers";
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col-md-12 col-lg-4">
                        <div className="card-img-container text-center">
                          <img src={custImg} alt="..." />
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-8">
                        <div className="card-body">
                          <h3 className="card-title">Customer Management</h3>
                          <h6 className="card-subtitle mb-2 text-muted">
                            Add | Edit | Deactivate | View Customers
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 mb-4">
                  <div
                    className="card home-card"
                    onClick={() => {
                      window.location.pathname = "/product/allcategories";
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col-md-12 col-lg-4">
                        <div className="card-img-container text-center">
                          <img src={productImg} alt="..." />
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-8">
                        <div className="card-body">
                          <h3 className="card-title">Product Management</h3>
                          <h6 className="card-subtitle mb-2 text-muted">
                            Add | Edit | Deactivate | View Products
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 mb-4">
                  <div
                    className="card home-card"
                    onClick={() => {
                      window.location.pathname = "/sms";
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col-md-12 col-lg-4">
                        <div className="card-img-container text-center">
                          <img src={smsImg} alt="..." />
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-8">
                        <div className="card-body">
                          <h3 className="card-title">Send SMS</h3>
                          <h6 className="card-subtitle mb-2 text-muted">
                            Send Messages to Employees
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>   
                
                <div className="col-sm-12 col-md-4 mb-4">
                  <div
                    className="card home-card"
                    onClick={() => {
                      window.location.pathname = "/pettycash/all";
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col-md-12 col-lg-4">
                        <div className="card-img-container text-center">
                          <img src={pettycashImg}  />
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-8">
                        <div className="card-body">
                          <h3 className="card-title">Petty Cash</h3>
                          <h6 className="card-subtitle mb-2 text-muted">
                            Petty Cash Management
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>     
                
                <div className="col-sm-12 col-md-4 mb-4">
                  <div
                    className="card home-card"
                    onClick={() => {
                      window.location.pathname = "/vehicles/all";
                    }}
                  >
                    <div className="row no-gutters">
                      <div className="col-md-12 col-lg-4">
                        <div className="card-img-container text-center">
                          <img src={vehicle}  />
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-8">
                        <div className="card-body">
                          <h3 className="card-title">Vehicles</h3>
                          <h6 className="card-subtitle mb-2 text-muted">
                          Vehicles Management
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default Home;
