import React, { Component } from "react";
import axios from "axios";
import FormData from "form-data";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import loader from "../../Resources/Images/loader/spinner-1.gif";

import "react-datepicker/dist/react-datepicker.css";
import html2canvas from "html2canvas";
import { Modal } from "react-bootstrap";
import PrintPreview from "./printPrview";

class CreateSalarySlip extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      status: null,
      show: { value: null, tf: false },
      loading: false,
      empData: [],
      singleEmpData: {},
      selectedYear: null,
      empid: null,
      year: null,
      otAmount: null,
      otHrs: null,
      doubleotHrs: null,
      incentiveData: null,
      viewSalarySlip: false,
      leavecount: null,
      lunchamount: null,
      lunchcount: null,
      dinnercount: null,
      dinneramount: null,
      loanamount: null,
      slipid: null,
    };
  }

  getOTHrs = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.empid && this.state.year && this.state.month) {
      if (this.state.token) {
        var config = {
          method: "get",
          url:
            process.env.REACT_APP_BASE_URL +
            `/salaryslip/othours/${this.state.empid}&${this.state.year}&${this.state.month}`,
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        };

        axios(config)
          .then((response) => {
            if (response.data.success) {
              this.setState({
                otAmount: response.data.otamout || 0,
                otHrs: response.data.othours || 0,
                doubleotHrs: response.data.doubleothours || 0,
                leavecount: response.data.leavecount || 0,
                lunchamount: response.data.lunchamount || 0,
                lunchcount: response.data.lunchcount || 0,
                dinnercount: response.data.dinnercount || 0,
                dinneramount: response.data.dinneramount || 0,
                loanamount: response.data.loanamount[0] || 0,
                loading: false,
              });
            } else {
              this.setState({
                loading: false,
                status: "failed",
                show: { value: "Failed Loading OT Data!", tf: true },
              });
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
              status: "failed",
              show: { value: "Failed Loading OT Data!", tf: true },
            });
          });
      } else {
        this.setState({
          loading: false,
          status: "auth-failed",
          show: { value: "Authentication Error!", tf: true },
        });
      }
    } else {
      this.setState({
        loading: false,
        status: "failed",
        show: { value: "Select Employee, Month and Year!", tf: true },
      });
    }
  };

  getSingleEmployee = (e) => {
    this.getIncentives();
    e.preventDefault();
    this.setState({
      show: { value: null, tf: false },
      loading: true,
      empid: e.target.value,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + `/employee/${e.target.value}`,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.employee) {
              this.setState({
                singleEmpData: response.data.employee,
                status: "success",
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Employee Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              empData: response.data.employee,
              show: { value: "Error Loading Employee Details!", tf: true },
              status: "failed",
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            empData: "not-found",
            show: { value: "Error Loading Employee Details!", tf: true },
            status: "failed",
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getIncentives = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/incentive",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.incentives[0]) {
              const result = [];
              res.data.incentives.map((data) => {
                if (data.empid === parseInt(this.state.empid)) {
                  result.push(data);
                }
              });

              this.setState({
                incentiveData: result,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Incentive Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load Incentives!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load Incentives!", tf: true },
            loading: false,
          });
          console.log(error);
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getEmpIds = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/employee/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.employees[0]) {
              this.setState({
                empData: response.data.employees,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Employee Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
              status: "type-failed",
              show: { value: "Failed Loading Employees!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "type-failed",
            show: { value: "Failed Loading Employees!", tf: true },
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  printSlip() {
    const htmlNode = document.getElementById(`slip`);
    html2canvas(htmlNode, {
      backgroundColor: "#FFFFFF",
    }).then((canvas) => {
      canvas.style.display = "none";
      var image = canvas.toDataURL("png");
      var a = document.createElement("a");
      a.setAttribute("download", "salarySlip.png");
      a.setAttribute("href", image);
      a.click();
    });
  }

  addSalarySlip = async (e) => {
    const event = e.target;
    e.preventDefault();
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      if (
        event.empid.value &&
        event.otHrs.value &&
        event.basicSal.value &&
        event.otAmount.value &&
        event.month.value 
        // this.state.year
        // &&
        // event.leaveHrs.value
      ) {
        var data = new FormData();
        data.append("empid", event.empid.value);
        data.append("othours", (event.otHrs.value+event.doubleotHrs.value));
        data.append("basic", event.basicSal.value);
        data.append("otamount", event.otAmount.value);
        data.append("lunchcount", 0);
        data.append("month", event.month.value);
        data.append("year", this.state.year);
        data.append("loanamount", event.loanamount.value);

        var config = {
          method: "post",
          url: process.env.REACT_APP_BASE_URL + "/salaryslip",
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
          data: data,
        };

        await axios(config)
          .then(async (response) => {
            if (response.data.success) {
              this.setState({ slipid: response.data.salaryslipid });
              if (this.state.incentiveData.length > 0) {
                this.state.incentiveData.map(async (data, idx) => {
                  var incentiveData = new FormData();
                  incentiveData.append("slipid", response.data.salaryslipid);
                  incentiveData.append("incentive", data.incentive);
                  incentiveData.append("qty", data.qty);
                  incentiveData.append("amount", data.amount);

                  var config = {
                    method: "post",
                    url:
                      process.env.REACT_APP_BASE_URL + "/salaryslip/incentive",
                    headers: {
                      Authorization: `Bearer ${this.state.token}`,
                    },
                    data: incentiveData,
                  };

                  await axios(config)
                    .then((response) => {
                      if (response.data.success) {
                        if (this.state.incentiveData.length - 1 === idx) {
                          this.setState({
                            status: "success",
                            show: {
                              value: "Successfully Added Salary Slip!",
                              tf: true,
                            },
                            loading: false,
                          });
                        }
                      } else {
                        this.setState({
                          status: "failed",
                          show: {
                            value: "Failed to Add Salary Slip!",
                            tf: true,
                          },
                          loading: false,
                        });
                      }
                    })
                    .catch((error) => {
                      this.setState({
                        status: "failed",
                        show: { value: "Failed to Add Salary Slip!", tf: true },
                        loading: false,
                      });
                    });
                });
              } else {
                this.setState({
                  status: "success",
                  show: {
                    value: "Successfully Added Salary Slip!",
                    tf: true,
                  },
                  loading: false,
                });
              }
            } else {
              this.setState({
                status: "failed",
                show: { value: "Failed to Add Salary Slip!", tf: true },
                loading: false,
              });
            }
          })
          .catch((error) => {
            this.setState({
              status: "failed",
              show: { value: "Failed to Add Salary Slip!", tf: true },
              loading: false,
            });
          });
      } else {
        this.setState({
          status: "failed",
          show: { value: "Fill Required Fields!", tf: true },
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  updateIncentive = (event, id, field) => {
    if (field === "incentive") {
      this.state.incentiveData[id].incentive = event;
    } else if (field === "qty") {
      this.state.incentiveData[id].qty = event;
    } else if (field === "amount") {
      this.state.incentiveData[id].amount = event;
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (
        this.state.status === "load-failed" ||
        this.state.status === "type-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "failed" ||
        this.state.status === "del-failed" ||
        this.state.status === "app-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "success" ||
        this.state.status === "app-success" ||
        this.state.status === "del-success"
      ) {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            document.getElementById("form").reset();
            this.setState({
              show: { value: null, tf: false },
              empData: [],
              singleEmpData: {},
              selectedYear: null,
              year: null,
              otAmount: null,
              otHrs: null,
              doubleotHrs: null,
              lunchCount: null,
              incentiveData: null,
              viewSalarySlip: true,
            });
            this.getEmpIds();
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount = () => {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getEmpIds();
  };

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <Modal
          show={this.state.viewSalarySlip}
          size="xl"
          onHide={() => {
            this.setState({
              viewSalarySlip: false,
            });
          }}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="col">
                <div className="row">Print Preview</div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PrintPreview userId={this.state.slipid} />
          </Modal.Body>
        </Modal>
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Create Salary Slip</h4>
              <p className="card-description">Salary Slip Details</p>
              <form
                className="forms-sample"
                onSubmit={(e) => this.addSalarySlip(e)}
                id="form"
              >
                <div className="form-group">
                  <label htmlFor="empid">Employee</label>
                  <select
                    className="form-control"
                    id="empid"
                    onChange={(e) => this.getSingleEmployee(e)}
                  >
                    <option value="">Select Employee...</option>
                    {this.state.empData !== "not-found" &&
                      this.state.empData?.map((data) => {
                        return <option value={data.id}>{data.name}</option>;
                      })}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="month">Month</label>
                  <div className="d-flex">
                    <select
                      id="month"
                      className="form-control"
                      onChange={(e) => this.setState({ month: e.target.value })}
                    >
                      <option value="">Select Month...</option>
                      <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="year">Year</label>
                  <DatePicker
                    className="form-control"
                    selected={this.state.selectedYear}
                    onChange={(date) =>
                      this.setState({
                        selectedYear: date,
                        year: new Date(date).getFullYear(),
                      })
                    }
                    showYearPicker
                    isClearable
                    dateFormat="yyyy"
                    placeholderText="Select Year..."
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="basicSal">Basic Salary</label>
                  <input
                    type="number"
                    className="form-control"
                    id="basicSal"
                    required
                    placeholder="Basic Salary"
                    disabled
                    defaultValue={this.state.singleEmpData?.basicsalary}
                  />
                </div>
                <div class="row">
                  <div className="form-group col-lg-4">
                    <label htmlFor="otHrs">OT Hours</label>
                    <input
                      type="number"
                      className="form-control"
                      id="otHrs"
                      required
                      placeholder="OT Hours"
                      disabled
                      defaultValue={this.state.otHrs}
                    />
                  </div>    
                    <div className="form-group col-lg-4">
                    <label htmlFor="otHrs">Double OT Hours</label>
                    <input
                      type="number"
                      className="form-control"
                      id="doubleotHrs"
                      required
                      placeholder="Double OT Hours"
                      disabled
                      defaultValue={this.state.doubleotHrs}
                    />
                  </div>
                  <div className="form-group col-lg-4">
                    <label htmlFor="otAmount">OT Amount</label>
                    <div className="d-flex">
                      <input
                        type="number"
                        className="form-control"
                        id="otAmount"
                        step="0.01"
                        required
                        placeholder="OT Amount"
                        disabled
                        defaultValue={this.state.otAmount}
                      />
                      <button
                        className="btn btn-success ml-1"
                        type="button"
                        onClick={() => this.getOTHrs()}
                      >
                        Get
                      </button>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="leaveHrs">Available Number of Leaves</label>
                  <div className="d-flex">
                    <input
                      type="number"
                      className="form-control"
                      id="leaveHrs"
                      required
                      value={this.state.leavecount}
                      disabled
                    />
                  </div>
                </div>
                <div class="row">
                  <div className="form-group col-lg-6">
                    <label htmlFor="lunchCount">Lunch Count</label>
                    <div className="d-flex">
                      <input
                        type="text"
                        className="form-control"
                        id="lunchCount"
                        required
                        placeholder="Lunch Amount for the month"
                        value={this.state.lunchcount}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-6">
                    <label htmlFor="lunchCount">Lunch Amount</label>
                    <div className="d-flex">
                      <input
                        type="text"
                        className="form-control"
                        id="lunchCount"
                        required
                        placeholder="Lunch Amount for the month"
                        value={this.state.lunchamount}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div className="form-group col-lg-6">
                    <label htmlFor="lunchCount">Dinner Count</label>
                    <div className="d-flex">
                      <input
                        type="text"
                        className="form-control"
                        id="lunchCount"
                        required
                        placeholder="Dinner Amount for the month"
                        value={this.state.dinnercount}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-6">
                    <label htmlFor="lunchCount">Dinner Amount</label>
                    <div className="d-flex">
                      <input
                        type="text"
                        className="form-control"
                        id="lunchCount"
                        required
                        placeholder="Lunch Amount for the month"
                        value={this.state.dinneramount}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                <div className="form-group col">
                    <label htmlFor="loanamount">Loan Amount</label>
                    <div className="d-flex">
                      <input
                        type="text"
                        className="form-control"
                        id="loanamount"
                        required
                        placeholder="Loan amount"
                        value={this.state.loanamount?.installmentamount || 0}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {this.state.incentiveData?.length > 0 && (
                  <Table className="table table-hover mb-3">
                    <Thead className="thead">
                      <Tr>
                        <Th scope="text-center gray-ish">Incentive</Th>
                        <Th scope="text-center gray-ish">Quantity</Th>
                        <Th scope="text-center gray-ish">Amount</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {this.state.incentiveData?.map((data, i) => {
                        return (
                          <Tr key={i}>
                            <Td>
                              <input
                                type="text"
                                id="incentive"
                                className="form-control"
                                required
                                defaultValue={data.incentive}
                                onChange={(e) =>
                                  this.updateIncentive(
                                    e.target.value,
                                    i,
                                    "incentive"
                                  )
                                }
                              />
                            </Td>
                            <Td>
                              <input
                                type="text"
                                id="qty"
                                className="form-control"
                                required
                                defaultValue={data.qty}
                                onChange={(e) =>
                                  this.updateIncentive(e.target.value, i, "qty")
                                }
                              />
                            </Td>
                            <Td>
                              <input
                                type="text"
                                id="amount"
                                className="form-control"
                                required
                                defaultValue={data.amount}
                                onChange={(e) =>
                                  this.updateIncentive(
                                    e.target.value,
                                    i,
                                    "amount"
                                  )
                                }
                              />
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                )}
                <button type="submit" className="btn btn-success mr-2">
                  Submit
                </button>
                <button type="reset" className="btn btn-light">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default CreateSalarySlip;
