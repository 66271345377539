import React, { Component } from "react";
import axios from "axios";
import FormData from "form-data";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class AddEmployee extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      status: null,
      show: { value: null, tf: false },
      loading: false,
      companies:null
    };
  }

  addEmployee = (e) => {
    e.preventDefault();
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      if (
        document.querySelector("#photo").files[0] &&
        e.target.employeeId.value &&
        e.target.name.value &&
        e.target.address.value &&
        e.target.phoneNumber1.value &&
        e.target.position.value &&
        e.target.salType.value &&
        e.target.jobDesc.value &&
        e.target.basicSal.value &&
        e.target.oTRate.value &&
        e.target.companyid.value &&
        e.target.bank.value &&
        e.target.bankacc.value &&
        e.target.status.value &&
        e.target.nic.value
      ) {
        var imgFile = document.querySelector("#photo");
        var data = new FormData();
        data.append("customid", e.target.employeeId.value);
        data.append("name", e.target.name.value);
        data.append("address", e.target.address.value);
        data.append("phone1", e.target.phoneNumber1.value);
        data.append("photo", imgFile.files[0]);
        data.append("position", e.target.position.value);
        data.append("salarytype", e.target.salType.value);
        data.append("jd", e.target.jobDesc.value);
        data.append("basicsalary", e.target.basicSal.value);
        data.append("otrate", e.target.oTRate.value);
        data.append("companyid", e.target.companyid.value);
        data.append("bank", e.target.bank.value);
        data.append("bankacc", e.target.bankacc.value);
        data.append("status", e.target.status.value);
        data.append("nic", e.target.nic.value);
        data.append(
          "phone2",
          e.target.phoneNumber2.value ? e.target.phoneNumber2.value : null
        );

        var config = {
          method: "post",
          url:  process.env.REACT_APP_BASE_URL + "/employee/newemp",
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
          data: data,
        };

        axios(config)
          .then((response) => {
            if (response.data.success) {
              this.setState({
                status: "success",
                show: { value: "Successfully Added Employee!", tf: true },
                loading: false,
              });
            } else {
              this.setState({
                status: "failed",
                show: { value: "Failed to Add Employee!", tf: true },
                loading: false,
              });
            }
          })
          .catch((error) => {
            this.setState({
              status: "failed",
              show: { value: "Failed to Add Employee!", tf: true },
              loading: false,
            });
          });
      } else {
        this.setState({
          status: "failed",
          show: { value: "Fill Required Fields!", tf: true },
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getCompanies = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/company/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.companies[0]) {
              this.setState({
                companies: response.data.companies,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Company Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
              status: "type-failed",
              show: { value: "Failed to load companies!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "type-failed",
            show: { value: "Failed Loading Employees!", tf: true },
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };


  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (this.state.status === "load-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "success") {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            document.getElementById("form").reset();
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentDidMount(){
    this.getCompanies();
  }

  componentWillMount = () => {
    this.setState({
      token: JSON.parse(sessionStorage.getItem("userToken")),
    });
    
  };

  render() {
    var companies = this.state.companies ? (
      this.state.companies.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.name}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Employee</h4>
              <p className="card-description">Employee Details</p>
              <form
                className="forms-sample"
                onSubmit={(e) => this.addEmployee(e)}
                id="form"
              >
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Employee ID</label>
                      <input
                        type="text"
                        className="form-control"
                        id="employeeId"
                        required
                        placeholder="Employee ID"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        required
                        pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
                        placeholder="Name"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    required
                    placeholder="Address"
                  />
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="phoneNumber1">Phone Number 1</label>
                      <input
                        type="number"
                        className="form-control"
                        id="phoneNumber1"
                        required
                        placeholder="Phone Number 1"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="phoneNumber2">Phone Number 2</label>
                      <input
                        type="number"
                        className="form-control"
                        id="phoneNumber2"
                        placeholder="Phone Number 2"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="nic">NIC</label>
                      <input
                        type="text"
                        className="form-control"
                        id="nic"
                        pattern="^([0-9]{9}[x|X|v|V]|[0-9]{12})$"
                        required
                        placeholder="NIC"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="companyName">Company Name</label>
                      <select className="form-control" id="companyid" name="companyid" required>
                    <option value="">Select</option>
                    {companies ? companies : <option value="">No Data</option>}
                  </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="position">Position</label>
                      <input
                        type="text"
                        className="form-control"
                        id="position"
                        placeholder="Position"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="jobDesc">Job Description</label>
                      <input
                        type="text"
                        className="form-control"
                        id="jobDesc"
                        placeholder="Job Description"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="salType">Salary Type</label>
                      <select className="form-control" id="salType" required>
                        <option value="">Select</option>
                        <option value="Daily">Daily</option>
                        <option value="Monthly">Monthly</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="basicSal">Basic Salary</label>
                      <input
                        type="number"
                        className="form-control"
                        step="0.01"
                        id="basicSal"
                        placeholder="Basic Salary"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="form-group">
                      <label htmlFor="oTRate">OT Rate</label>
                      <input
                        type="number"
                        className="form-control"
                        step="0.01"
                        id="oTRate"
                        placeholder="OT Rate"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="bank">Bank</label>
                      <select className="form-control" id="bank" required>
                        <option value="">Select</option>
                        <option value="Amana Bank">Amana Bank</option>
                        <option value="Bank of Ceylon">Bank of Ceylon</option>
                        <option value="Bank of China Limited">
                          Bank of China Limited
                        </option>
                        <option value="Cargills Bank Ltd">
                          Cargills Bank Ltd
                        </option>
                        <option value="Citibank N.A.">Citibank N.A.</option>
                        <option value="Commercial Bank of Ceylon PLC">
                          Commercial Bank of Ceylon PLC
                        </option>
                        <option value="Deutsche Bank AG">
                          Deutsche Bank AG
                        </option>
                        <option value="DFCC Bank PLC">DFCC Bank PLC</option>
                        <option value="Habib Bank Ltd">Habib Bank Ltd</option>
                        <option value="Hatton National Bank PLC">
                          Hatton National Bank PLC
                        </option>
                        <option value="ICICI Bank Ltd">ICICI Bank Ltd</option>
                        <option value="Indian Bank">Indian Bank</option>
                        <option value="Indian Overseas Bank">
                          Indian Overseas Bank
                        </option>
                        <option value="MCB Bank Ltd">MCB Bank Ltd</option>
                        <option value="National Development Bank PLC">
                          National Development Bank PLC
                        </option>
                        <option value="Nations Trust Bank PLC">
                          Nations Trust Bank PLC
                        </option>
                        <option value="Pan Asia Banking Corporation PLC">
                          Pan Asia Banking Corporation PLC
                        </option>
                        <option value="People's Bank">People's Bank</option>
                        <option value="Public Bank Berhad">
                          Public Bank Berhad
                        </option>
                        <option value="Sampath Bank PLC">
                          Sampath Bank PLC
                        </option>
                        <option value="Seylan Bank PLC">Seylan Bank PLC</option>
                        <option value="Standard Chartered Bank">
                          Standard Chartered Bank
                        </option>
                        <option value="State Bank of India">
                          State Bank of India
                        </option>
                        <option value="The Hong Kong and Shanghai Banking Corporation Ltd (HSBC)">
                          The Hong Kong and Shanghai Banking Corporation Ltd
                          (HSBC)
                        </option>
                        <option value="Union Bank of Colombo PLC">
                          Union Bank of Colombo PLC
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <label htmlFor="bankacc">Account Number</label>
                      <input
                        type="number"
                        className="form-control"
                        id="bankacc"
                        placeholder="Account Number"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Photo</label>
                  <input
                    type="file"
                    name="photo"
                    accept="image/x-png, image/jpeg"
                    id="photo"
                    className="form-control file-upload-info"
                    placeholder="Upload Image"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="status">Status</label>
                  <select className="form-control" id="status" required>
                    <option value="">Select</option>
                    <option value="1">Active</option>
                    <option value="2">Not Active</option>
                  </select>
                </div>
                <button type="submit" className="btn btn-success mr-2">
                  Submit
                </button>
                <button type="reset" className="btn btn-light">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default AddEmployee;
