import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import loader from "../../Resources/Images/loader/spinner-1.gif";

import "react-datepicker/dist/react-datepicker.css";

class CreateSalarySlip extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      status: null,
      show: { value: null, tf: false },
      loading: false,
      empData: [],
      salaryData: [],
      salaryIncentiveData: [],
    };
  }

  getEmpIds = async () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/employee/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((response) => {
          if (response.data.success) {
            this.getSlipData();
            if (response.data.employees[0]) {
              this.setState({
                empData: response.data.employees,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Employee Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
              status: "type-failed",
              show: { value: "Failed Loading Employees!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "type-failed",
            show: { value: "Failed Loading Employees!", tf: true },
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getSlipData = async () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + `/salaryslip/viewslip/${this.props.match.params.id}`,
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      await axios(config)
        .then((response) => {
          if (response.data.success) {
            this.setState({
              salaryData: response.data.salaryslip,
              salaryIncentiveData: response.data.salaryincentives,
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
              status: "type-failed",
              show: { value: "Failed Loading Salary Slip!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "type-failed",
            show: { value: "Failed Loading Salary Slip!", tf: true },
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  updateIncentive = (event, id, field) => {
    if (field === "incentive") {
      this.state.salaryIncentiveData[id].incentive = event;
    } else if (field === "qty") {
      this.state.salaryIncentiveData[id].qty = event;
    } else if (field === "amount") {
      this.state.salaryIncentiveData[id].amount = event;
    }
  };

  updateSalarySlip = async (e) => {
    e.preventDefault();
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      if (this.state.salaryIncentiveData.length > 0) {
        this.state.salaryIncentiveData.map(async (data, idx) => {
          var incentiveData = {
            incentiveid: data.id,
            slipid: this.props.match.params.id,
            incentive: data.incentive,
            qty: data.qty,
            amount: data.amount,
          };

          var config = {
            method: "patch",
            url:
            process.env.REACT_APP_BASE_URL + "/salaryslip/updatesalaryincentive",
            headers: {
              Authorization: `Bearer ${this.state.token}`,
            },
            data: incentiveData,
          };

          await axios(config)
            .then((response) => {
              if (response.data.success) {
                if (this.state.salaryIncentiveData.length - 1 === idx) {
                  this.setState({
                    status: "success",
                    show: {
                      value: "Successfully Updated Salary Slip!",
                      tf: true,
                    },
                    loading: false,
                  });
                }
              } else {
                this.setState({
                  status: "failed",
                  show: {
                    value: "Failed to Update Salary Slip!",
                    tf: true,
                  },
                  loading: false,
                });
              }
            })
            .catch((error) => {
              this.setState({
                status: "failed",
                show: { value: "Failed to Update Salary Slip!", tf: true },
                loading: false,
              });
            });
        });
      }
    } else {
      this.setState({
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
        loading: false,
      });
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (
        this.state.status === "load-failed" ||
        this.state.status === "type-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "failed" ||
        this.state.status === "del-failed" ||
        this.state.status === "app-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "success" ||
        this.state.status === "app-success" ||
        this.state.status === "del-success"
      ) {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            document.getElementById("form").reset();
            this.setState({
              show: { value: null, tf: false },
              salaryData: [],
            });
            window.location.href = "/emp/viewslaryslips";
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount = () => {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getEmpIds();
  };

  render() {
    let emp = null;
    if (this.state.empData !== "not-found") {
      for (let i = 0; i < this.state.empData.length; i++) {
        if (this.state.empData[i].id === this.state.salaryData.empid) {
          emp = this.state.empData[i].name;
        }
      }
    }

    const month = {
      "01": "January",
      "02": "February",
      "03": "March",
      "04": "April",
      "05": "May",
      "06": "June",
      "07": "July",
      "08": "August",
      "09": "September",
      10: "October",
      11: "November",
      12: "December",
    };

    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Create Salary Slip</h4>
              <p className="card-description">Salary Slip Details</p>
              <form
                className="forms-sample"
                onSubmit={(e) => this.updateSalarySlip(e)}
                id="form"
              >
                <div>
                  Employee <h4 htmlFor="empid">{emp}</h4>
                </div>
                <div>
                  Month{" "}
                  <h4 htmlFor="month">{month[this.state.salaryData.month]}</h4>
                </div>
                <div>
                  Year <h4 htmlFor="year">{this.state.salaryData.year}</h4>
                </div>
                <div>
                  Basic Salary{" "}
                  <h4 htmlFor="basicSal">{this.state.salaryData.basic}</h4>
                </div>
                <div>
                  OT Amount{" "}
                  <h4 htmlFor="otAmount">{this.state.salaryData.otamount}</h4>
                </div>
                <div>
                  OT Hours{" "}
                  <h4 htmlFor="otHrs">{this.state.salaryData.othours}</h4>
                </div>
                <div>
                  Leave Hours{" "}
                  <h4 htmlFor="leaveHrs">{this.state.salaryData.leavehours}</h4>
                </div>
                <div>
                  Lunch Count{" "}
                  <h4 htmlFor="lunchCount">
                    {this.state.salaryData.lunchcount}
                  </h4>
                </div>

                {this.state.salaryIncentiveData?.length > 0 && (
                  <Table className="table table-hover mb-3">
                    <Thead className="thead">
                      <Tr>
                        <Th scope="text-center gray-ish">Incentive</Th>
                        <Th scope="text-center gray-ish">Quantity</Th>
                        <Th scope="text-center gray-ish">Amount</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {this.state.salaryIncentiveData?.map((data, i) => {
                        return (
                          <Tr key={i}>
                            <Td>
                              <input
                                type="text"
                                id="incentive"
                                className="form-control"
                                required
                                defaultValue={data.incentive}
                                onChange={(e) =>
                                  this.updateIncentive(
                                    e.target.value,
                                    i,
                                    "incentive"
                                  )
                                }
                              />
                            </Td>
                            <Td>
                              <input
                                type="text"
                                id="qty"
                                className="form-control"
                                required
                                defaultValue={data.qty}
                                onChange={(e) =>
                                  this.updateIncentive(e.target.value, i, "qty")
                                }
                              />
                            </Td>
                            <Td>
                              <input
                                type="text"
                                id="amount"
                                className="form-control"
                                required
                                defaultValue={data.amount}
                                onChange={(e) =>
                                  this.updateIncentive(
                                    e.target.value,
                                    i,
                                    "amount"
                                  )
                                }
                              />
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                )}
                <button type="submit" className="btn btn-success mr-2">
                  Submit
                </button>
                <button
                  onClick={() => (window.location.href = "/emp/viewslaryslips")}
                  className="btn btn-light"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default CreateSalarySlip;
