import React, { Component } from "react";
import axios from "axios";
import qs from "querystring";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class SubmitLeave extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
      empData: null,
      type: null,
      status: null,
      show: { value: null, tf: false },
      loading: false,
    };
  }

  getTypes = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      const config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/leave/types",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.leavetypes[0]) {
              this.setState({
                type: response.data.leavetypes,
                status: "type-success",
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Leave Type Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "type-failed",
              show: { value: "Failed Loading Leave Types!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "type-failed",
            show: { value: "Failed Loading Leave Types!", tf: true },
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getEmpIds = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/employee/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((response) => {
          if (response.data.success) {
            if (response.data.employees[0]) {
              this.setState({
                empData: response.data.employees,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Employee Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              loading: false,
              status: "type-failed",
              show: { value: "Failed Loading Employees!", tf: true },
            });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            status: "type-failed",
            show: { value: "Failed Loading Employees!", tf: true },
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  submitLeave = (e) => {
    e.preventDefault();
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      if (
        e.target.empid.value &&
        e.target.status.value &&
        e.target.leaveDate.value &&
        e.target.reason.value &&
        e.target.leaveType.value
      ) {
        var data = {
          empid: e.target.empid.value,
          status: e.target.status.value,
          leavedate: e.target.leaveDate.value,
          reason: e.target.reason.value,
          leavetype: e.target.leaveType.value,
        };

        var config = {
          method: "post",
          url: process.env.REACT_APP_BASE_URL + "/leave",
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
          data: qs.stringify(data),
        };

        axios(config)
          .then((response) => {
            if (response.data.success) {
              this.setState({
                status: "success",
                show: { value: "Leave Submitted Successfully!", tf: true },
                loading: false,
              });
            } else {
              this.setState({
                status: "failed",
                show: { value: "Leave Submitting Failed!", tf: true },
                loading: false,
              });
            }
          })
          .catch((error) => {
            this.setState({
              status: "failed",
              show: { value: "Leave Submitting Failed!", tf: true },
              loading: false,
            });
          });
      } else {
        this.setState({
          status: "failed",
          show: { value: "Fill Required Fields!", tf: true },
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (
        this.state.status === "load-failed" ||
        this.state.status === "type-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "success") {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            document.getElementById("form").reset();
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount = () => {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getTypes();
    this.getEmpIds();
  };

  render() {
    var ids = this.state.empData ? (
      this.state.empData.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.name}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );

    var types = this.state.type ? (
      this.state.type.map((data, i) => {
        return (
          <option value={data.id} key={i}>
            {data.leavetype}
          </option>
        );
      })
    ) : (
      <option>No Data</option>
    );

    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Submit Leave</h4>
              <p className="card-description">Leave Details</p>
              <form
                id="form"
                className="forms-sample"
                onSubmit={(e) => this.submitLeave(e)}
              >
                <div className="form-group">
                  <label htmlFor="empid">Employee</label>
                  <select className="form-control" id="empid" required>
                    <option value="">Select</option>
                    {ids ? ids : <option value="">No Data</option>}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="leaveDate">Leave Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="leaveDate"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="status">Status</label>
                  <select className="form-control" id="status" required>
                    <option value="">Select</option>
                    <option value="1">Approve</option>
                    <option value="0">Pending</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="reason">Reason</label>
                  <input
                    type="text"
                    id="reason"
                    className="form-control"
                    placeholder="Reason"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="leaveType">Leave Type</label>
                  <select className="form-control" id="leaveType" required>
                    <option value="">Select</option>
                    {types ? types : <option value="">No Data</option>}
                  </select>
                </div>
                <button type="submit" className="btn btn-success mr-2">
                  Submit
                </button>
                <button type="reset" className="btn btn-light">
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

export default SubmitLeave;
