class Auth {
  constructor() {
    this.authenticated = JSON.parse(sessionStorage.getItem("loginStatus"));
  }

  login(cb) {
    sessionStorage.setItem("loginStatus", JSON.stringify(true));
    cb();
  }

  logout(cb) {
    sessionStorage.removeItem("loginStatus");
    cb();
  }

  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth();
