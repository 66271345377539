/* eslint-disable array-callback-return */
import React, { Component } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import swal from "sweetalert";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import loader from "../../Resources/Images/loader/spinner-1.gif";

class CreateInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      status: null,
      show: { value: null, tf: false },
      loading: false,
      custData: [],
      productData: [],
      displayProducts: [],
      displayServices: [],
    };

    this.total = 0;
    this.discount = 0;
    this.customer = "";
    this.duedate = "";
  }

  addProduct = (id) => {
    if (id >= 0) {
      const appendData = {
        index: id,
        id: this.state.productData[id].id,
        name: this.state.productData[id].name,
        price: this.state.productData[id].price,
        qty: 1,
        discount: 0,
        amount: this.state.productData[id].price,
      };

      this.setState({
        productData: this.state.productData.filter(
          (data) => data.id !== appendData.id
        ),
        displayProducts: [...this.state.displayProducts, appendData],
      });

      this.total = this.total + appendData.price;
    }
  };

  removeProduct = (id) => {
    if (id >= 0) {
      const appendData = {
        index: this.state.displayProducts[id].index,
        id: this.state.displayProducts[id].id,
        name: this.state.displayProducts[id].name,
        price: this.state.displayProducts[id].price,
      };

      this.setState({
        displayProducts: this.state.displayProducts.filter(
          (data) => data.id !== appendData.id
        ),
        productData: [...this.state.productData, appendData],
      });
    }
  };

  handleProductQtyChange = (index, value) => {
    let displayProducts = [...this.state.displayProducts];
    let item = { ...displayProducts[index] };
    item.qty = value < 1 ? 1 : value;
    item.amount =
      item.price * (value < 1 ? 1 : value) -
      item.price * (item.discount / 100) * (value < 1 ? 1 : value);
    displayProducts[index] = item;

    this.setState({ displayProducts });
  };

  handleProductDiscountChange = (index, value) => {
    let displayProducts = [...this.state.displayProducts];
    let item = { ...displayProducts[index] };
    item.discount = value < 0 ? 0 : value > 100 ? 100 : value;
    item.amount =
      item.price * item.qty -
      item.price *
        ((value < 0 ? 0 : value > 100 ? 100 : value) / 100) *
        item.qty;
    displayProducts[index] = item;

    this.setState({ displayProducts });
  };

  addService = () => {
    const appendData = {
      name: "",
      price: "",
      qty: 1,
      discount: 0,
      amount: 0,
    };

    this.setState({
      displayServices: [...this.state.displayServices, appendData],
    });
  };

  removeService = (id) => {
    var displayServices = [...this.state.displayServices];
    displayServices.splice(id, 1);
    if (id >= 0) {
      this.setState({ displayServices });
    }
  };

  handleServiceNameChange = (index, value) => {
    let displayServices = [...this.state.displayServices];
    let item = { ...displayServices[index] };
    item.name = value;
    displayServices[index] = item;

    this.setState({ displayServices });
  };

  handleServicePriceChange = (index, value) => {
    let displayServices = [...this.state.displayServices];
    let item = { ...displayServices[index] };
    item.price = value;
    item.amount = value * item.qty - value * (item.discount / 100) * value;
    displayServices[index] = item;

    this.setState({ displayServices });
  };

  handleServiceQtyChange = (index, value) => {
    let displayServices = [...this.state.displayServices];
    let item = { ...displayServices[index] };
    item.qty = value < 1 ? 1 : value;
    item.amount =
      item.price * (value < 1 ? 1 : value) -
      item.price * (item.discount / 100) * (value < 1 ? 1 : value);
    displayServices[index] = item;

    this.setState({ displayServices });
  };

  handleServiceDiscountChange = (index, value) => {
    let displayServices = [...this.state.displayServices];
    let item = { ...displayServices[index] };
    item.discount = value < 0 ? 0 : value > 100 ? 100 : value;
    item.amount =
      item.price * item.qty -
      item.price *
        ((value < 0 ? 0 : value > 100 ? 100 : value) / 100) *
        item.qty;
    displayServices[index] = item;

    this.setState({ displayServices });
  };

  renderInvoice = () => {
    if (this.state.displayProducts[0] || this.state.displayServices[0]) {
      let condition = false;
      this.state.displayProducts.map((data) => {
        if (!data.qty) {
          this.setState({
            status: "failed",
            show: { value: "Fill All the Fields to Continue!", tf: true },
          });
          return (condition = true);
        } else {
          return (condition = false);
        }
      });

      this.state.displayServices.map((data) => {
        if (!data.qty || !data.name || !data.price) {
          this.setState({
            status: "failed",
            show: { value: "Fill All the Fields to Continue!", tf: true },
          });
          return (condition = true);
        } else {
          return (condition = false);
        }
      });

      if (this.customer === "") {
        this.setState({
          status: "failed",
          show: { value: "Select a Customer!", tf: true },
        });
        return (condition = true);
      } else {
        if (!condition) {
          this.setState({
            show: { value: null, tf: false },
            loading: true,
          });

          if (this.state.token) {
            let data = new FormData();
            data.append("customerid", this.customer);
            data.append("duedate", this.duedate);

            var config = {
              method: "post",
              url: process.env.REACT_APP_BASE_URL + "/invoice",
              headers: {
                Authorization: `Bearer ${this.state.token}`,
              },
              data,
            };

            axios(config)
              .then((res) => {
                if (res.data.success) {
                  const items = [
                    ...this.state.displayProducts,
                    ...this.state.displayServices,
                  ];

                  items.map((invoiceItems) => {
                    let data = new FormData();
                    data.append("invoiceid", res.data.invoice);
                    data.append("item", invoiceItems.name);
                    data.append("unit_price", invoiceItems.price);
                    data.append("qty", invoiceItems.qty);
                    data.append("discount", invoiceItems.discount);

                    var config = {
                      method: "post",
                      url: process.env.REACT_APP_BASE_URL + "/invoiceitem",
                      headers: {
                        Authorization: `Bearer ${this.state.token}`,
                      },
                      data: data,
                    };

                    axios(config)
                      .then((response) => {
                        if (response.data.success) {
                          const sendData = {
                            items: [
                              ...this.state.displayProducts,
                              ...this.state.displayServices,
                            ],
                            discount: this.discount,
                            total: this.total,
                            customer: this.customer,
                          };
                          this.props.history.push({
                            pathname: "/invoice",
                            state: sendData,
                          });
                        } else {
                          this.setState({
                            status: "load-failed",
                            show: {
                              value: "Failed to Create Invoice!",
                              tf: true,
                            },
                            loading: false,
                          });
                        }
                      })
                      .catch((error) => {
                        this.setState({
                          status: "load-failed",
                          show: {
                            value: "Failed to Create Invoice!",
                            tf: true,
                          },
                          loading: false,
                        });
                      });
                  });
                } else {
                  this.setState({
                    status: "load-failed",
                    show: { value: "Failed to Add Invoice!", tf: true },
                    loading: false,
                  });
                }
              })
              .catch((error) => {
                this.setState({
                  status: "load-failed",
                  show: { value: "Failed to Add Invoice!", tf: true },
                  loading: false,
                });
              });
          } else {
            this.setState({
              loading: false,
              status: "auth-failed",
              show: { value: "Authentication Error!", tf: true },
            });
          }
        }
      }
    } else {
      this.setState({
        status: "failed",
        show: { value: "Add Products or Services to Continue!", tf: true },
      });
    }
  };

  getProducts = () => {
    this.setState({
      show: { value: null, tf: false },
      loading: true,
    });

    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/product",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.products[0]) {
              this.setState({
                productData: res.data.products,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Product Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load Products!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load Products!", tf: true },
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  getCustomers = () => {
    this.setState({
      show: { value: null, tf: false },
    });
    if (this.state.token) {
      var config = {
        method: "get",
        url: process.env.REACT_APP_BASE_URL + "/customer/all",
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      };

      axios(config)
        .then((res) => {
          if (res.data.success) {
            if (res.data.customer[0]) {
              this.setState({
                custData: res.data.customer,
                loading: false,
              });
            } else {
              this.setState({
                status: "empty",
                show: { value: "No Customer Data!", tf: true },
                loading: false,
              });
            }
          } else {
            this.setState({
              status: "load-failed",
              show: { value: "Failed to Load Customers!", tf: true },
              loading: false,
            });
          }
        })
        .catch((error) => {
          this.setState({
            status: "load-failed",
            show: { value: "Failed to Load Customers!", tf: true },
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
        status: "auth-failed",
        show: { value: "Authentication Error!", tf: true },
      });
    }
  };

  componentDidUpdate() {
    if (this.state.show.tf) {
      if (this.state.status === "auth-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.href = "/";
          }
        });
      } else if (
        this.state.status === "load-failed" ||
        this.state.status === "type-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            window.location.reload();
          }
        });
      } else if (this.state.status === "fill-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "upload-failed") {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "failed" ||
        this.state.status === "del-failed" ||
        this.state.status === "app-failed"
      ) {
        swal({
          title: "Failed!",
          text: this.state.show.value,
          icon: "warning",
          dangerMode: true,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (
        this.state.status === "success" ||
        this.state.status === "app-success" ||
        this.state.status === "del-success"
      ) {
        swal({
          title: "Success!",
          text: this.state.show.value,
          icon: "success",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      } else if (this.state.status === "empty") {
        swal({
          title: "Info!",
          text: this.state.show.value,
          icon: "info",
          dangerMode: false,
        }).then((yes) => {
          if (yes) {
            this.setState({
              show: { value: null, tf: false },
            });
          }
        });
      }
    }
  }

  componentWillMount() {
    this.state.token = JSON.parse(sessionStorage.getItem("userToken"));
    this.getProducts();
    this.getCustomers();
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#ffffffcc"
        logoSrc={loader}
      >
        <div className="create-invoice">
          <div className="col-md-10 offset-md-1 col-12">
            <div className="row mt-2">
              <div className="col-4">
                <div className="form-group">
                  <label for="product">Select to Add Product</label>
                  <select
                    className="form-control"
                    name="product"
                    id="product"
                    value=""
                    onChange={(e) => this.addProduct(e.target.value)}
                  >
                    <option value="">Select Product...</option>
                    {this.state.productData?.map((data, i) => {
                      return (
                        <option value={i} key={i}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label for="customer">Select Customer</label>
                  <select
                    className="form-control"
                    name="customer"
                    id="customer"
                    onChange={(e) => (this.customer = e.target.value)}
                  >
                    <option value="">Select Customer...</option>
                    {this.state.custData?.map((data, i) => {
                      return (
                        <option value={data.id} key={i}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label for="customer">Due Date</label>
                  <input
                    className="form-control"
                    name="duedate"
                    id="duedate"
                    required
                    type="date"
                    onChange={(e) => (this.duedate = e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-10 offset-md-1 col-12 invoice-table pt-1">
              <Table className="table table-hover">
                <Thead className="thead">
                  <Tr>
                    <Th scope="text-center gray-ish">Item</Th>
                    <Th scope="text-center gray-ish">Unit Price</Th>
                    <Th scope="text-center gray-ish">Quantity</Th>
                    <Th scope="text-center gray-ish">Discount</Th>
                    <Th scope="text-center gray-ish">Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {this.state.displayProducts?.map((data, i) => {
                    return (
                      <Tr>
                        <Td className="item">
                          <div>{data.name}</div>
                        </Td>
                        <Td>{data.price.toFixed(2)}</Td>
                        <Td>
                          <input
                            className="form-control form-contorl-sm col-12"
                            type="number"
                            id="qty"
                            placeholder="Quantity"
                            min="1"
                            value={data.qty}
                            defaultValue={data.qty}
                            onChange={(e) =>
                              this.handleProductQtyChange(i, e.target.value)
                            }
                          />
                        </Td>
                        <Td>
                          <input
                            className="form-control form-contorl-sm col-12"
                            type="number"
                            id="discount"
                            placeholder="Discount"
                            min="0"
                            max="100"
                            value={data.discount}
                            defaultValue={data.discount}
                            onChange={(e) =>
                              this.handleProductDiscountChange(
                                i,
                                e.target.value
                              )
                            }
                          />
                        </Td>
                        <Td>{data.amount?.toFixed(2)}</Td>
                        <Td>
                          <button
                            className="btn btn-outline-danger btn-sm"
                            type="button"
                            onClick={() => this.removeProduct(i)}
                          >
                            <i className="fa fa-trash-o"></i>
                          </button>
                        </Td>
                      </Tr>
                    );
                  })}
                  {this.state.displayServices[0] && (
                    <Tr>
                      <Td>
                        <strong>Services</strong>
                      </Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                    </Tr>
                  )}
                  {this.state.displayServices?.map((data, i) => {
                    return (
                      <Tr>
                        <Td className="item">
                          <div>
                            <input
                              className="form-control form-contorl-sm col-12"
                              type="text"
                              id="service-name"
                              placeholder="Description"
                              value={data.name}
                              defaultValue={data.name}
                              onChange={(e) =>
                                this.handleServiceNameChange(i, e.target.value)
                              }
                            />
                          </div>
                        </Td>
                        <Td>
                          <input
                            className="form-control form-contorl-sm col-12"
                            type="number"
                            id="price"
                            placeholder="Price"
                            min="1"
                            value={data.price}
                            defaultValue={data.price}
                            onChange={(e) =>
                              this.handleServicePriceChange(i, e.target.value)
                            }
                          />
                        </Td>
                        <Td>
                          <input
                            className="form-control form-contorl-sm col-12"
                            type="number"
                            id="service-qty"
                            placeholder="Quantity"
                            min="1"
                            value={data.qty}
                            defaultValue={data.qty}
                            onChange={(e) =>
                              this.handleServiceQtyChange(i, e.target.value)
                            }
                          />
                        </Td>
                        <Td>
                          <input
                            className="form-control form-contorl-sm col-12"
                            type="number"
                            id="service-discount"
                            placeholder="Discount"
                            min="0"
                            max="100"
                            value={data.discount}
                            defaultValue={data.discount}
                            onChange={(e) =>
                              this.handleServiceDiscountChange(
                                i,
                                e.target.value
                              )
                            }
                          />
                        </Td>
                        <Td>{data.amount?.toFixed(2)}</Td>
                        <Td>
                          <button
                            className="btn btn-outline-danger btn-sm"
                            type="button"
                            onClick={() => this.removeService(i)}
                          >
                            <i className="fa fa-trash-o"></i>
                          </button>
                        </Td>
                      </Tr>
                    );
                  })}
                  <Tr>
                    <Td>
                      <button
                        className="btn btn-warning"
                        onClick={() => this.addService()}
                      >
                        Add Empty Row
                      </button>
                    </Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                  </Tr>
                </Tbody>
              </Table>
            </div>

            <div className="offset-md-1 col-md-10 col-12 mb-3 pt-3 pr-md-4 sub-table">
              <Table className="table table-borderless">
                <Tbody>
                  <Tr className="gray pt-2">
                    <Td scope="text-left">
                      <span>Discount</span>
                    </Td>
                    <Td className="text-right">
                      Rs.{" "}
                      {
                        (this.discount = (
                          Number(
                            this.state.displayProducts
                              ?.map((data) => {
                                return (
                                  (data.price * data.discount * data.qty) / 100
                                );
                              })
                              .reduce((a, b) => {
                                return a + b;
                              }, 0)
                          ) +
                          Number(
                            this.state.displayServices
                              ?.map((data) => {
                                return (
                                  (data.price * data.discount * data.qty) / 100
                                );
                              })
                              .reduce((a, b) => {
                                return a + b;
                              }, 0)
                          )
                        ).toFixed(2))
                      }
                    </Td>
                  </Tr>
                  <Tr className="gray">
                    <Th scope="text-left">
                      <h4>
                        <strong>Total</strong>
                      </h4>
                    </Th>
                    <Td className="text-right">
                      <h4>
                        <strong>
                          Rs.{" "}
                          {
                            (this.total = (
                              Number(
                                this.state.displayProducts
                                  ?.map((data) => {
                                    return (
                                      data.price * data.qty -
                                      (data.price * data.discount * data.qty) /
                                        100
                                    );
                                  })
                                  .reduce((a, b) => {
                                    return a + b;
                                  }, 0)
                              ) +
                              Number(
                                this.state.displayServices
                                  ?.map((data) => {
                                    return (
                                      data.price * data.qty -
                                      (data.price * data.discount * data.qty) /
                                        100
                                    );
                                  })
                                  .reduce((a, b) => {
                                    return a + b;
                                  }, 0)
                              )
                            ).toFixed(2))
                          }
                        </strong>
                      </h4>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </div>
          </div>
          {this.state.displayProducts[0] || this.state.displayServices[0] ? (
            <div className="row mt-3">
              <div className="col-12 offset-md-1 col-md-10 text-right">
                <button
                  className="btn btn-success btn-lg"
                  onClick={() => this.renderInvoice()}
                >
                  Generate Invoice
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </LoadingScreen>
    );
  }
}

export default CreateInvoice;
